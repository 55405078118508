<template>
  <!-- Page Heading -->
  <!-- Page Heading -->
  <div class="d-sm-flex align-items-center justify-content-between mb-1">
    <h1 class="h4 mb-0 text-gray-800">Dashboard</h1>
  </div>

  <!-- Content Row -->
  <div class="row" style="line-height: 11px;">
    <div class="col-xl-2 col-md-6 mb-4">
      <router-link class="link" target='_blank' to="/customers">
        <div class="card counter-card border-left-success shadow h-100 py-2">
          <div class="card-body">
            <div class="row justify-content-center" v-if="loading1">
              <ProgressSpinner 
              style="width:40px;height:40px"
              strokeWidth="1" 
              fill="var(--surface-ground)"
              animationDuration=".5s"
              />
            </div>
            <div v-else class="row no-gutters align-items-right">
              <div class="col mr-2">
                <div class="
                  text-xs
                  font-weight-bold
                  text-success text-uppercase
                  mb-1
                ">
                  Customers
                </div>
                <div class="h5 mb-0 font-weight-bold text-gray-800">
                  {{totalcustomerCount}}
                </div>
              </div>
              <div class="col-auto">
                <i class="fa fa-user fa-2x text-gray-300"></i>
              </div>
            </div>
          </div>
        </div>
      </router-link>
    </div>

    <div class="col-xl-2 col-md-6 mb-4">
      <router-link class="link" to="/customers">
        <div class="card counter-card border-left-primary shadow h-100 py-2">
          <div class="card-body">
            <div class="row justify-content-center" v-if="loading2">
              <ProgressSpinner 
              style="width:40px;height:40px"
              strokeWidth="1" 
              fill="var(--surface-ground)"
              animationDuration=".5s"
              />
            </div>
            <div v-else class="row no-gutters align-items-right">
              <div class="col mr-2">
                <div class="
                  text-xs
                  font-weight-bold
                  text-primary text-uppercase
                  mb-1
                ">
                  Due Invoices
                </div>
                <div class="h5 mb-0 font-weight-bold text-gray-800">
                  {{totalinvoicesCount}}
                </div>
              </div>
              <div class="col-auto">
                <i class="fa fa-file fa-2x text-gray-300"></i>
              </div>
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import ProgressSpinner from 'primevue/progressspinner';
import { mapState } from "vuex";
import axios from "axios"

export default {
  name: "Dashboard",

  components: {ProgressSpinner},

  data() {
    return {
      app_base_url: process.env.VUE_APP_BASE_URL,
      loading1: true,
      loading2: true,
      totalcustomerCount: null,
      totalinvoicesCount: null,
    };
  },

  computed: mapState(["products", "productsInBag", "user"]),

  created() {
    this.totalCustomers();
    this.totalInvoices();
  },

  methods: {

    totalCustomers: function () {
      var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_billing_user") };
      axios.get(this.app_base_url + '/totalCustomers', { headers })
        .then((response) => {
          this.loading1 = false;
          this.totalcustomerCount = response.data.totalcustomerCount;
        }).catch((error) => {
          console.warn('Your Error is :', error);
        })
    },
    
    totalInvoices: function () {
      var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_billing_user") };
      axios.get(this.app_base_url + '/totalUnpaidInvoices', { headers })
      .then((response) => {
        this.loading2 = false;
        this.totalinvoicesCount = response.data.totalunpaidInvoices;
      }).catch((error) => {
        console.warn('Your Error is :', error);
      })
    },

  },
};
</script>
<style>
#task_list {
  list-style: none;
}

.counter-card:hover {
  transform: scale(1.1);
}

.link:hover {
  text-decoration: none;
}

.h5:hover {
  color: blue;
}
</style>