<template>
    <div>
        <div class="row justify-content-center" v-if="loading">
            <ProgressSpinner 
            style="width:50px;height:50px" 
            strokeWidth="1" 
            fill="var(--surface-ground)"
            animationDuration=".5s"
            />
        </div>
        <div v-else>
            <button class="btn btn-sm float-right btn-info btn pt-0 pb-0" @click="showFormPopup()">
                Edit <i class="ml-1 pi pi-user-edit"></i>
            </button>
            <table class="border=0">
                <tbody>
                    <tr><th scope="row" class="pt-0 pb-0">Company Name</th><td class="pt-0 pb-0"> {{companyName}}</td></tr>
                    <tr><th scope="row" class="pt-0 pb-0">Address</th><td class="pt-0 pb-0"> {{Address}}</td></tr>
                    <tr><th scope="row" class="pt-0 pb-0">City</th><td class="pt-0 pb-0"> {{City}}</td></tr>
                    <tr><th scope="row" class="pt-0 pb-0">State/Zip</th><td class="pt-0 pb-0"> {{State}}/{{Zip}}</td></tr>
                    <tr><th scope="row" class="pt-0 pb-0">Phone</th><td class="pt-0 pb-0"> {{Phone}}</td></tr>
                    <tr><th scope="row" class="pt-0 pb-0">Contact Name</th><td class="pt-0 pb-0"> {{contactName}}</td></tr>
                    <tr><th scope="row" class="pt-0 pb-0">Contact Phone</th><td class="pt-0 pb-0"> {{contactPhone}}</td></tr>
                    <tr><th scope="row" class="pt-0 pb-0">Email</th><td class="pt-0 pb-0"> {{Email}}</td></tr>
                    <tr><th scope="row" class="pt-0 pb-0">Additional Email</th><td class="pt-0 pb-0"> {{additionalEmail}}</td></tr>
                </tbody>
            </table>
            <Dialog :maximizable="true" :closeOnEscape="true" header="Customer Form" class="shadow-lg" v-model:visible="display" :style="{width: '50vw'}">
                <customerForm :userID="userID" @Display="dialog($event)" />
            </Dialog>
        </div>
        <Toast />
    </div>
</template>
<script>
    import axios from "axios";
    import moment from "moment";
    import 'primeicons/primeicons.css';
    import Toast from 'primevue/toast';
    import Dialog from 'primevue/dialog';
    import ProgressSpinner from 'primevue/progressspinner';
    import customerForm from '../frontend/partials/customerForm.vue';
    export default {
        components: {
            ProgressSpinner,
            Dialog,
            Toast,
            customerForm
        },
        data() {
            return {
                app_base_url: process.env.VUE_APP_BASE_URL,
                moment: moment,
                loading: true,
                display: false,
                title: null,
                //Fields
                userID: null,
                userRole: "Staff",
                companyName: null,
                Address: null,
                City: null,
                State: null,
                Zip: null,
                Phone: null,
                contactName: null,
                contactPhone: null,
                Email: null,
                Password: null,
                additionalEmail: null,
                createdDate: null,
            };
        },
        created() {
            this.userID = localStorage.getItem("logged_user_id");
            this.getCustomer();
        },
        methods: {
            getCustomer: function () {
            var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_billing_front_user") };
            axios.get(this.app_base_url + '/customers/' + this.userID, { headers })
            .then((response) => {
                this.loading = false,
                console.info("Frontend Show Customer: ", response.data.customer);
                this.companyName = response.data.customer.company_name,
                this.Address = response.data.customer.address,
                this.City = response.data.customer.city,
                this.State = response.data.customer.state,
                this.Zip = response.data.customer.zip,
                this.Phone = response.data.customer.phone,
                this.contactName = response.data.customer.contact_name,
                this.contactPhone = response.data.customer.contact_phone,
                this.Email = response.data.customer.email,
                this.additionalEmail = response.data.customer.additional_email
                this.createdDate = response.data.customer.created_at
                }).catch((error) => {
                console.warn('Your Error is :', error);
                })
            },

            showFormPopup: function () {
                this.display = true;
            },
            
            dialog(title)
            {
                this.display=title;
                this.getCustomer();
                this.$toast.add({ severity: 'success', summary: 'Profile Updated', detail: 'Customer details changed successfully!', life: 2000 });
            },

        },
    };
</script>
  