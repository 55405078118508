<template>
  <div v-if="isActive">
    <div class="row justify-content-center pt-0" style="margin-top: 60px;" v-if="loading">
      <ProgressSpinner style="width:50px;height:50px;" strokeWidth="1" fill="var(--surface-ground)"
        animationDuration=".5s" />
    </div>
    <div v-else>

      <div class="">
        <div class="row bg-light border rounded p-2" style="font-size: 14px;">
          <div class="col-md-12">
            <div>
              <div><b>Gateway Mode:</b> {{ mode[0].toUpperCase() + mode.slice(1)  }}</div>
              <div><b>Merchant ID:</b> {{ merchantID }}</div>
              <div><b>Private Key:</b> xxxxxxx</div>
              <div><b>Public Key:</b> xxxxxxx</div>
            </div>
            <div class="mt-2">
              <button v-on:click="viewGatewaySettingsPopup()" style="font-size: 12px !important;" class="btn btn-primary btn-sm mr-2"><i class="fa fa-eye"></i> View</button>
              <Dialog header="View Payment Gateway Details" v-model:visible="viewPaymentGatewayPopup" :style="{width: '30vw'}" >
                <viewPaymentGateway @Display="dialog($event)" />
              </Dialog>
              <button @click="editGatewaySettingsPopup()" style="font-size: 12px !important;" class="btn btn-primary btn-sm "><i class="fa fa-edit"></i> Edit</button>
              <Dialog header="Edit Payment Gateway Details" v-model:visible="editPaymentGatewayPopup" :style="{width: '30vw'}" >
                <editPaymentGateway @Display="dialog($event)" />
              </Dialog>
            </div>
          </div>
        </div>
      </div>
      <Toast />
    </div>
  </div>
  <div v-else>
    <br>
    <div class="d-flex justify-content-center mt-5">
      <img :src="'/img/Braintree_Payments_Logo.png'" class="float-left" width="100" />
    </div>
  </div>


</template>

<script>
import axios from "axios";
import Toast from 'primevue/toast';
import useValidate from "@vuelidate/core";
import ProgressSpinner from 'primevue/progressspinner';
import Dialog from 'primevue/dialog';
import viewPaymentGateway from '../partials/viewPaymentGateway.vue'
import editPaymentGateway from '../partials/editPaymentGateway.vue'

export default {
  components: {
    Toast,
    ProgressSpinner,
    Dialog,
    viewPaymentGateway,
    editPaymentGateway
  },

  data() {
    return {
      v$: useValidate(),
      isActive: false,
      loading: true,
      privateKEY: null,
      publicKEY: null,

      isDisabled: false,
      btnTitle: "Save",
      app_base_url: process.env.VUE_APP_BASE_URL,
      viewPaymentGatewayPopup: false,
      editPaymentGatewayPopup: false,

      mode: 'sandbox',
      merchantID: null,

    };
  },

  created() {
    this.getPaymentGatewaSettings();
  },

  methods: {

    dialog(val)
    {
      this.viewPaymentGatewayPopup = val;
      this.getPaymentGatewaSettings();
      
    },

    viewGatewaySettingsPopup()
    {
      this.viewPaymentGatewayPopup = true;
    },

    editGatewaySettingsPopup()
    {
      this.editPaymentGatewayPopup = true;
    },

    getPaymentGatewaSettings() {

      var id = 6;
      var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_billing_user") };
      axios
      .get(this.app_base_url + "/settings/" + id, { headers })
      .then((response) => {

        this.isActive = response.data.payment_gateway_active ? true : false;

        if (this.isActive) {
          this.loading = false;
        } else {
          this.loading = true;
        }

        this.merchantID = response.data.merchant_id
        // this.privateKEY = response.data.private_key
        // this.publicKEY = response.data.public_key

        if (!response.data.mode) {
          this.mode = 'Sandbox'
        } else {
          this.mode = response.data.mode
        }
        
      })
      .catch((error) => {
        console.error("Your Error is :", error);
      });
    },

    PaymentGatewayActive: function () {
      var data = {
        setting_id: 6,
        payment_gateway_active: !this.isActive,
      };

      var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_billing_user") };
      axios.post(this.app_base_url + "/settings", data, { headers }).then(
        (response) => {
          if (response.status === 200) {
            this.btnTitle = "Update Settings";
            this.isDisabled = false;

            if (response.status === 200) {
              this.getPaymentGatewaSettings();
              if (this.isActive) {
                this.$notify({
                  type: "info",
                  title: "Success",
                  text: "Braintree Acitvated!",
                });
                this.loading = false;
              } else {
                this.$toast.add({ severity: 'error', summary: 'Inactivated', detail: 'Braintree inactivated!', life: 2000 });
              }
            }

          }
          console.log(response);
        },
        (error) => {
          this.isDisabled = false;
          this.btnTitle = "Update Settings";
          console.log(error.response.data.error);
          this.$notify({
            type: "error",
            title: "Something went wrong!",
            text: error.response.data.error,
          });
        }
      );
    }
  },
}
</script>

<style scoped>
.customerCard {
  background: #e7edff;
  border: 1px;
  border-color: #d1d3e2;
  border-style: solid;
  border-radius: 5px;
  margin-top: 41px;
  margin-right: -3px;
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>