<template>
  <div class="container-fuild  full-height" style="height: 400px;">

<div class="text-center my-2 p-4 container">
  Note: Customers visiting this new billing system are requested to update their password!
</div>

<div class="row justify-content-center">
  <div>
    <div class="card o-hidden border-0 shadow-lg my-4">
      <div class="card-body p-0">
        <!-- Nested Row within Card Body -->
        <div class="row justify-content-center">
          <div style="width: 430px;">
            <div class="pl-5 pr-5 pt-3 pb-2">
              <div class="text-center">
                <h1 class="h4 text-gray-900 mb-4">Reset Password</h1>
              </div>
              <form @submit.prevent="formSubmit" class="user">
                <div class="form-group">
                  <input type="text" v-model="email" class="form-control form-control" id="exampleInputEmail"
                    aria-describedby="emailHelp" placeholder="Email" />
                  <small class="text-danger" v-if="v$.email.$error">{{
                  v$.email.$errors[0].$message
                  }}</small>
                </div>

                <button class="btn btn-dark" :disabled="isDisabled">
                  {{btnTitle}} <i v-if="isDisabled" class="pi pi-spin pi-spinner" style="font-size: 1rem"></i>
                </button>

                <div class="py-4" v-if="message">
                  <div class="alert alert-primary">{{ message }}</div>
                </div>

                <!-- <a href="#" class="ml-3" style="text-decoration: underline;">Forget password</a> -->
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
</template>

<script>
import useValidate from "@vuelidate/core";
import { required, minLength, email } from "@vuelidate/validators";
import axios from "axios";
import 'primeicons/primeicons.css';
import moment from "moment";
export default {
  data() {
    return {
      v$: useValidate(),
      app_base_url: process.env.VUE_APP_BASE_URL,
      email: null,
      moment: moment,
      password: null,
      loginErrMsg: null,
      btnTitle: "Reset",
      isDisabled: false,
      message: null,
    };
  },

  validations() {
    return {
      email: { required, minLength: minLength(3), email },
    };
  },

  methods: {
    formSubmit() {
      this.v$.$validate();
      if (this.v$.$error) {
        console.log("errors");
      } else {

        this.isDisabled = true;
        this.btnTitle = "Please wait... ";

        var data = {
          email: this.email,
        };
        var headers = {};
        axios
          .post(this.app_base_url + "/forgot/password", data, { headers: headers })
          .then((response) => {
            if (response.data.status) {
              this.message = response.data.message
              this.$notify({
                  type: "success",
                  title: response.data.message,
                  text: "Success!",
              });
              this.isDisabled = false;
              this.btnTitle = "Reset";

            } else {
              this.message = response.data.message
              this.$notify({
                  type: "error",
                  title: response.data.message,
                  text: "Error!",
              });

              this.isDisabled = false;
              this.btnTitle = "Reset";
            }

          },
            (error) => {
              this.message = response.data.message
              this.loginErrMsg = error.response.data.message;
              console.log(error.response.data.error);
              this.$notify({
                type: "error",
                title: "Something went wrong!",
                text: "Error!",
              });

              this.isDisabled = false;
              this.btnTitle = "Reset";
            });
      }
    },
  },
};
</script>