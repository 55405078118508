<template>
  <div class="row">
    <div class="col-md-8"><page-header :title="'Gateway Settings'" /></div>
    <!-- <div class="col-md-3 float-right"><page-header :title="'Mail Management'" /></div> -->
  </div>
  <div class="row">
    <!-- <div class="card col-md-6">
      <div class="card-body">
        <BrainTreeSettingForm/>
      </div>
    </div> -->
    <div class="card col-md-5 ml-1">
      <div class="card-body">
        <PaymentGateway/>
      </div>
    </div>  
  </div>
</template>

<script>
  import PageHeader from "../SharedComponents/PageHeader.vue";
  // import BrainTreeSettingForm from './partials/BrainTreeVue.vue';
  import PaymentGateway from './partials/PaymentGateway.vue';

  export default {
    components: { 
      PageHeader, 
      PaymentGateway,
      // BrainTreeSettingForm,
    },
  };
</script>