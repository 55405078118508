<template>
    <div>
        <div class="row justify-content-center" v-if="loading">
            <ProgressSpinner
                style="width:50px;height:50px"
                strokeWidth="1"
                fill="var(--surface-ground)"
                animationDuration=".5s"
            />
        </div>
        <div v-else>
            <div class="row text-secondary">
                <div class="col-md-8">
                    <button
                        class="btn btn-success pt-0 pb-0 my-1"
                        @click="showFormPopup()"
                    >
                        <i class="fa fa-address-card" aria-hidden="true"></i>
                        Add Card
                    </button>
                    <!-- <p v-if="customer.is_save_credit_card" class="text-success">Credit Card Save.</p>
                    <p v-else class="text-danger">No Credit Card</p> -->
                    <Dialog
                        :maximizable="true"
                        :closeOnEscape="true"
                        header="Card Information"
                        class="shadow-lg"
                        v-model:visible="display"
                        :style="{ width: '35vw' }"
                    >
                        <CreditCardForm @Display="dialog($event)" />
                    </Dialog>

                    <div class="my-3" v-if="user_cards.length > 0">
                        <p class="p-0 m-0">Default Card</p>
                        <select
                            @change="updateDefaultPaymentMethod($event)"
                            v-model="default_card"
                            class="form-control form-control-sm col-md-5"
                        >
                            <option
                                v-for="card in user_cards"
                                v-bind:key="card.id"
                                :selected="card.is_default === 1"
                                :value="card.id"
                                >{{ card.card_type }} | ends with
                                {{ card.card_number }}
                            </option>
                        </select>
                    </div>

                    <!-- <p class="p-0 m-0">
                        Please select your preferred date for monthly billing
                    </p>
                    <div class="form-check form-check-inline ">
                        <input
                            class="form-check-input"
                            @change="onChange($event)"
                            v-model="monthly_payment_date"
                            type="radio"
                            name="inlineRadioOptions"
                            id="5th"
                            value="5"
                        />
                        <label class="form-check-label" for="5th">5th</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input
                            class="form-check-input"
                            @change="onChange($event)"
                            v-model="monthly_payment_date"
                            type="radio"
                            name="inlineRadioOptions"
                            id="10th"
                            value="10"
                        />
                        <label class="form-check-label" for="10th">10th</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input
                            class="form-check-input"
                            @change="onChange($event)"
                            v-model="monthly_payment_date"
                            type="radio"
                            name="inlineRadioOptions"
                            id="15th"
                            value="15"
                        />
                        <label class="form-check-label" for="15th">15th</label>
                    </div> -->
                </div>
                <div class="col-md-4"></div>
            </div>
        </div>
    </div>
    <Toast />
</template>
<script>
import axios from "axios";
import moment from "moment";
import Toast from "primevue/toast";
import Dialog from "primevue/dialog";
import ProgressSpinner from "primevue/progressspinner";
import CreditCardForm from "../frontend/partials/CreditCardForm.vue";
export default {
    components: {
        Toast,
        Dialog,
        CreditCardForm,
        ProgressSpinner,
    },
    data() {
        return {
            app_base_url: process.env.VUE_APP_BASE_URL,
            moment: moment,
            loading: true,
            display: false,
            customer: [],
            //Fields
            userID: null,
            monthly_payment_date: null,
            default_card: null,
            user_cards: [],
        };
    },

    created() {
        this.userID = localStorage.getItem("logged_user_id");
        this.getPaymentInfo();
        this.getCustomer();
        this.getUserCards();
    },

    methods: {
        getUserCards() {
            this.loading = true;
            let data = [];
            var headers = {
                Authorization:
                    `Bearer ` + localStorage.getItem("rezo_billing_front_user"),
            };
            axios
                .post(this.app_base_url + "/get/user_payment_methods", data, {
                    headers,
                })
                .then(
                    (response) => {
                        this.user_cards = response.data.cards;
                        var result = response.data.cards.find((obj) => {
                            return obj.is_default === 1;
                        });
                        if (result) {
                            this.default_card = result.id;
                            this.monthly_payment_date = result.monthly_payment_date;    
                        }
                        
                        this.loading = false;
                        if (!result) {
                            this.default_card = response.data.cards[0].id
                        }
                    },
                    (error) => {
                        console.log(error.response.data.error);
                        this.loading = false;
                    }
                );
        },
        updateDefaultPaymentMethod: function(event) {
            console.log(event.target.value);

            this.loading = true;
            let data = {
                default_payment_method_id: this.default_card,
            };
            var headers = {
                Authorization:
                    `Bearer ` + localStorage.getItem("rezo_billing_front_user"),
            };
            axios
                .post(
                    this.app_base_url + "/update/user_default_payment_method",
                    data,
                    { headers }
                )
                .then(
                    (response) => {
                        this.user_cards = response.data.cards;
                        var result = response.data.cards.find((obj) => {
                            return obj.is_default === 1;
                        });
                        this.default_card = result.id;
                        this.monthly_payment_date = result.monthly_payment_date;
                        console.log(result.monthly_payment_date);
                        this.loading = false;
                    },
                    (error) => {
                        console.log(error.response.data.error);
                        this.loading = false;
                    }
                );
        },
        getPaymentInfo: function() {
            this.loading = false;
            // var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_billing_front_user") };
            // axios.get(this.app_base_url + '/user_payment_information', { headers })
            // .then((response) => {
            //     this.monthly_payment_date = response.data.monthly_payment_date
            // }).catch((error) => {
            // console.warn('Your Error is :', error);
            // })
        },

        onChange(event) {
            this.loading = true;
            console.log(event.target.value);
            var data = {
                monthly_payment_date: event.target.value,
            };

            var headers = {
                Authorization:
                    `Bearer ` + localStorage.getItem("rezo_billing_front_user"),
            };
            axios
                .post(
                    this.app_base_url + "/store/user_payment_information",
                    data,
                    { headers }
                )
                .then(
                    (response) => {
                        if (response.status === 200) {
                            this.getPaymentInfo();
                            this.$toast.add({
                                severity: "success",
                                summary: "Status Updated",
                                detail:
                                    "Monthly duration updated successfully!",
                                life: 2000,
                            });
                        }
                    },
                    (error) => {
                        this.isDisabled = false;
                        this.btnTitle = "Save Info";
                        console.log(error.response.data.error);
                        this.$notify({
                            type: "error",
                            title: "Something went wrong!",
                            text: error.response.data.error,
                        });
                    }
                );
        },

        showFormPopup: function() {
            this.display = true;
        },

        dialog(status) {
            this.display = status;
            this.$toast.add({
                severity: "success",
                summary: "Card Info Saved",
                detail: "Credit card save successfully!",
                life: 2000,
            });

            this.getUserCards()
        },

        getCustomer: function() {
            var headers = {
                Authorization:
                    `Bearer ` + localStorage.getItem("rezo_billing_front_user"),
            };
            axios
                .get(this.app_base_url + "/customers/" + this.userID, {
                    headers,
                })
                .then((response) => {
                    (this.loading = false),
                        console.info("Show Customer: ", response.data.customer);
                    this.customer = response.data.customer;
                })
                .catch((error) => {
                    console.warn("Your Error is :", error);
                });
        },
    },
};
</script>
