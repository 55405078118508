<template>
    <div class="row justify-content-center" v-if="loading">
        <ProgressSpinner style="width:50px;height:50px" strokeWidth="1" fill="var(--surface-ground)"
            animationDuration=".5s" />
    </div>
    <div v-else>

        <div v-if="!isPasswordConfirmed">
            <div class="form-group">
                <small for="password" class="required mr-1">Please enter your password to proceed further</small>
                <input type="password" required id="password" class="form-control form-control-sm " v-model="password" />
            </div>

            <button class="btn btn-sm btn-primary" @click="confirmPassword">Continue</button>
        </div>

        <div v-if="isPasswordConfirmed">
            <div class="row bg-light border rounded p-2 m-2" style="font-size: 14px;">
                <div class="col-md-12">
                    <div>
                        <div><b>Gateway Mode:</b> {{ mode }}</div>
                        <div><b>Merchant ID:</b> {{ merchant_id }}</div>
                        <div><b>Private Key:</b> {{ private_key }}</div>
                        <div><b>Public Key:</b> {{ public_key }}</div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>

// import axios from "axios";
import moment from "moment";
import 'primeicons/primeicons.css';
import ProgressSpinner from 'primevue/progressspinner';
import axios from "axios";

export default {
    props: [],
    components: { ProgressSpinner },
    data() {
        return {
            app_base_url: process.env.VUE_APP_BASE_URL,
            btnDisabled: false,
            loading: false,
            moment: moment,
            isPasswordConfirmed: false,
            password: null,
            mode: null,
            merchant_id: null,
            private_key: null,
            public_key: null,
        };
    },
    created() {
    },


    methods: {
        
        hideDialog()
        {
            this.$emit('Display', false)
        },

        confirmPassword() {
            this.loading = true;

            var data = {
                password: this.password,
            };

            var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_billing_user") };
            axios.post(this.app_base_url + "/auth/check/password", data, { headers }).then(
                (response) => {
                    if (response.status === 200) {

                        if (response.data.status === true) {
                            
                            this.$notify({
                                type: "success",
                                title: "Thanks for confirming password!",
                                text: response.data.message,
                            });

                            this.isPasswordConfirmed = true;
                            this.loading = false;

                            this.mode = response.data.setting.mode
                            this.merchant_id = response.data.setting.merchant_id
                            this.private_key = response.data.setting.private_key
                            this.public_key = response.data.setting.public_key

                        } else {

                            this.$notify({
                                type: "error",
                                title: "Something went wrong!",
                                text: response.data.message,
                            });

                            this.loading = false;

                        }
                        
                    }
                },
                (error) => {
                    console.log(error.response.data.error);
                    this.$notify({
                        type: "error",
                        title: "Something went wrong!",
                        text: error.response.data.error,
                    });

                    this.loading = false;

                }
            );  

        }
        
    },

};
</script>