import { createStore } from 'vuex'
import axios from 'axios'

export default createStore({
  state: {
    user: {
      'id': 0,
      'firstName': null,
      'lastName': null,
      'email': null,
      'role': null
    },
    headers: {},
    productsInBag: []
  },
  mutations: {
    authUser (state, user) {
      state.user = user;
    },
    authFrontUser (state, user) {
      state.user = user;
    },
    loadBag (state, products) {
      state.productsInBag = products;
    },
    addToBag(state, product) {
      state.productsInBag.push(product);
      localStorage.setItem("productsInBag", JSON.stringify(state.productsInBag))
    },
    removeFromBag(state, productId) {
      var updatedBag = state.productsInBag.filter(item => productId != item.id);
      state.productsInBag = updatedBag;
      localStorage.setItem("productsInBag", JSON.stringify(state.productsInBag))
    },
  },
  actions: {
    getAuthUser({ commit }) {
      var token = localStorage.getItem("rezo_billing_user");
      if(token){
        var headers = { Authorization: `Bearer `+token };
        axios.get(process.env.VUE_APP_BASE_URL + '/auth_user', {headers})
        .then(response => {
            let {id,first_name,last_name,email,role} = response.data;
            commit('authUser',{
              'id': id,
              'firstName': first_name,
              'lastName': last_name,
              'email': email,
              'role': role
            });
            // console.log("Auth User Got it!");
        });
      }else{
        console.log("Token not set yet!");
      }

    },
    getAuthFrontUser({ commit }) {
      var token = localStorage.getItem("rezo_billing_front_user");
      if(token){
        var headers = { Authorization: `Bearer `+token };
        axios.get(process.env.VUE_APP_BASE_URL + '/front/auth_user', {headers})
        .then(response => {
            let {id,first_name,last_name,email,role} = response.data;
            commit('authFrontUser',{
              'id': id,
              'firstName': first_name,
              'lastName': last_name,
              'email': email,
              'role': role
            });
            // console.log("Auth User Got it!");
        });
      }else{
        console.log("Front Customer Token not set yet!");
      }
    },
    
    loadBag({ commit }) {
      if (localStorage.getItem("productsInBag")) {
        commit('loadBag', JSON.parse(localStorage.getItem("productsInBag")));
      }
    },

    addToBag({ commit }, product) {
      commit('addToBag', product);
    },

    removeFromBag({ commit }, productId) {
      if (confirm('Are you sure you want to remove the item from bag?')) {
        commit('removeFromBag', productId);
      }
    },
    
  },
  modules: {
  }
})
