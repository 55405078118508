<template>
    <div class="row justify-content-center" v-if="loading">
        <ProgressSpinner style="width:50px;height:50px" strokeWidth="1" fill="var(--surface-ground)"
            animationDuration=".5s" />
    </div>
    <div v-else>

        <div v-if="!isPasswordConfirmed">
            <div class="form-group">
                <small for="password" class="required mr-1">Please enter your password to proceed further</small>
                <input type="password" required id="password" class="form-control form-control-sm" v-model="password" />
            </div>

            <button class="btn btn-sm btn-primary" @click="confirmPassword">Continue</button>
        </div>

        <div v-if="isPasswordConfirmed">
            <div class="row p-2 m-2" >
                <div class="col-md-12">
                    <div>
                        <form @submit.prevent="formSubmit">

                            <div class="form-group mr-2 mt-2">
                            <small for="mode" class="required mr-1">Mode</small>
                            <select v-model="mode" class="form-control form-control-sm" >
                                <option value="sandbox">Sandbox</option>
                                <option value="production">Production</option>
                            </select>
                            <span class="text-danger" v-if="v$.mode.$error">
                                <small class="help-block" v-for="validationError in v$.mode.$errors" :key="validationError">
                                {{ validationError.$message }}
                                </small>
                            </span>
                            </div>

                            <div class="form-group mr-2 mt-2">
                            <small for="merchantID" class="required mr-1">Merchant ID</small>
                            <input type="text" id="merchant_id" class="form-control form-control-sm" v-model="merchantID" />
                            <span class="text-danger" v-if="v$.merchantID.$error">
                                <small class="help-block" v-for="validationError in v$.merchantID.$errors" :key="validationError">
                                {{ validationError.$message }}
                                </small>
                            </span>
                            </div>

                            <div class="form-group mr-2 mt-2">
                            <small for="private_key" class="required mr-1">Private Key</small>
                            <input type="password" id="privateKEY" class="form-control form-control-sm" v-model="privateKEY" />
                            <span class="text-danger" v-if="v$.privateKEY.$error">
                                <small class="help-block" v-for="validationError in v$.privateKEY.$errors" :key="validationError">
                                {{ validationError.$message }}
                                </small>
                            </span>
                            </div>

                            <div class="form-group mr-2 mt-2">
                            <small for="publicKEY" class="required mr-1">Public Key</small>
                            <input type="password" id="public_key" class="form-control form-control-sm" v-model="publicKEY" />
                            <span class="text-danger" v-if="v$.publicKEY.$error">
                                <small class="help-block" v-for="validationError in v$.publicKEY.$errors" :key="validationError">
                                {{ validationError.$message }}
                                </small>
                            </span>
                            </div>

                            <button class="btn btn-success btn-sm btn-block">Update</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>

// import axios from "axios";
import moment from "moment";
import 'primeicons/primeicons.css';
import ProgressSpinner from 'primevue/progressspinner';
import axios from "axios";
import useValidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

export default {
    props: [],
    components: { ProgressSpinner },
    data() {
        return {
            v$: useValidate(),
            app_base_url: process.env.VUE_APP_BASE_URL,
            btnDisabled: false,
            loading: false,
            moment: moment,
            isPasswordConfirmed: false,
            password: null,
            mode: 'sandbox',
            merchantID: null,
            privateKEY: null,
            publicKEY: null,
        };
    },

    validations() {
        return {
            merchantID: {
                required: helpers.withMessage('Merchant id is required.', required),
            },
            privateKEY: {
                required: helpers.withMessage('Private key is required.', required),
            },
            publicKEY: {
                required: helpers.withMessage('Public key is required.', required),
            },
            mode: {
                required: helpers.withMessage('Mode is required.', required),
            },
        };
    },
  
    created() {
    },


    methods: {
        
        hideDialog()
        {
            this.$emit('Display', false)
        },

        formSubmit() {
            this.loading = true;
            this.v$.$validate();
            if (this.v$.$error) {
                console.log("errors");
            }
            else {
                this.isDisabled = true;
                this.btnTitle = "Please wait...";

                var data = {
                    setting_id: 6,
                    payment_gateway_active: this.isActive,
                    merchant_id: this.merchantID,
                    private_key: this.privateKEY,
                    public_key: this.publicKEY,
                    mode: this.mode,
                };

                var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_billing_user") };
                axios.post(this.app_base_url + "/settings", data, { headers }).then(
                (response) => {
                    if (response.status === 200) {
                        this.$notify({
                            type: "success",
                            title: "Success",
                            text: "Saved Successfully!",
                        });
                    }
                    this.loading = false;
                    this.hideDialog();
                    
                },
                (error) => {
                    this.isDisabled = false;
                    this.btnTitle = "Update Settings";
                    this.$notify({
                        type: "error",
                        title: "Something went wrong!",
                        text: error.response.data.error,
                    });
                    this.loading = false;
                });
            }
        },

        confirmPassword() {
            
            this.loading = true;

            var data = {
                password: this.password,
            };

            var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_billing_user") };

            axios.post(this.app_base_url + "/auth/check/password", data, { headers }).then(
                (response) => {
                    if (response.status === 200) {

                        if (response.data.status === true) {
                            
                            this.$notify({
                                type: "success",
                                title: "Thanks for confirming password!",
                                text: response.data.message,
                            });

                            this.merchantID = response.data.setting.merchant_id
                            this.privateKEY = response.data.setting.private_key
                            this.publicKEY = response.data.setting.public_key
                            this.mode = response.data.setting.mode

                            this.isPasswordConfirmed = true;
                            this.loading = false;

                        } else {

                            this.$notify({
                                type: "error",
                                title: "Something went wrong!",
                                text: response.data.message,
                            });

                            this.loading = false;

                        }
                        
                    }
                },
                (error) => {
                    console.log(error.response.data.error);
                    this.$notify({
                        type: "error",
                        title: "Something went wrong!",
                        text: error.response.data.error,
                    });

                    this.loading = false;

                }
            );  

        }
        
    },

};
</script>