<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card mb-3">
        <div class="card-body">
          <div class="card-body">
            <div class="row justify-content-center" v-if="loading">
              <ProgressSpinner style="width:50px;height:50px" strokeWidth="1" fill="var(--surface-ground)"
                animationDuration=".5s" />
            </div>
            <div v-else>
              <div class="row">
                <div class="col-md-12">
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Guest Emails</th>
                          <th>Guest</th>
                          <th>Subject</th>
                          <th>Status</th>
                          <th>Sent Date</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody style="font-size: 13px;">
                        <tr v-for="record in SentEmails.data" :key="record.id">
                          <td>{{ record.email }}</td>
                          <td>{{ companyName }}</td>
                          <td>{{ record.subject }}</td>
                          <td>
                            <span class="badge badge-pill badge-success">Sent</span>
                          </td>
                          <td>
                            {{ moment(record.created_at).format("MM/DD/YYYY") }}
                          </td>
                          <td>
                            <button class="btn btn-info mr-1 pt-0 pb-0 btn-sm" @click="viewSentEmail(record.id)">View</button>
                            <button class="btn btn-primary pt-0 pb-0 btn-sm" @click="ResendEmail(record.id)">Resent Email</button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                      <div v-if="SentEmails.data.length > 0" class="pagination d-flex justify-content-center mt-1">
                      <button class="btn btn-outline-secondary pt-0 pb-0 mr-1" @click="getCustomer(SentEmails.first_page_url)" :disabled="pagination.current_page == 1" style="font-size: 14px; height: 30px;"> First Page </button>
                      <button class="btn btn-outline-secondary pt-0 pb-0 mr-2" @click="getCustomer(pagination.prev_page_url)" :disabled="pagination.current_page == 1" style="font-size: 14px; height: 30px;"> Previous </button>
                      <label>Page {{ pagination.current_page }} of {{ pagination.last_page }}</label>
                      <button class="btn btn-outline-secondary pt-0 pb-0 ml-2" @click="getCustomer(pagination.next_page_url)" :disabled="pagination.current_page == pagination.last_page" style="font-size: 14px; height: 30px;">Next </button>
                      <button class="btn btn-outline-secondary pt-0 pb-0 ml-1" @click="getCustomer(SentEmails.last_page_url)" :disabled="pagination.current_page == pagination.last_page" style="font-size: 14px; height: 30px;"> Last Page </button>

                      <div class="ml-1">
                        <select v-model="searchFilters.per_page" class="form-control" style="border: 1px solid #858796; height: 30px; padding-top: 2px;">
                          <option selected :value="3">3</option>
                          <option selected :value="5">5</option>
                          <option selected :value="10">10</option>
                          <option selected :value="20">20</option>
                          <option selected :value="50">50</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Sidebar class="p-sidebar-md" :dismissable="true" position="right" v-model:visible="visibleRight">
    <p><strong>{{emailViewSubject}}</strong></p>
    <div v-html="emailViewBody"></div>
  </Sidebar>
  <Toast />
</template>
<script>

import axios from "axios";
import moment from "moment";
import Toast from 'primevue/toast';
// import { mapState } from "vuex";
import Sidebar from 'primevue/sidebar';
import ProgressSpinner from 'primevue/progressspinner';
export default {
  // computed: mapState(["user"]),
  components: {
    ProgressSpinner,
    Sidebar,
    Toast,
  },

  data() {
    return {
      app_base_url: process.env.VUE_APP_BASE_URL,
      moment: moment,
      loading: true,
      btnDisabled: false,
      visibleRight: false,
      SentEmails: [],
      pagination: {},
      searchFilters: {
        per_page:5
      },

      emailViewSubject: null,
      emailViewBody: null,

      //Fields
      userID: null,
      email: null,
      guest: null,
      subject: null,
      sentDate: null,
      status: false,
      companyName: null,
      customerEmail: false,
    };
  },

  watch: {
    searchFilters: {
      deep: true,
      handler() {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.getCustomer(this.app_base_url + '/customers/' + this.userID)
            this.loading = true;
            this.awaitingSearch = false;
          }, 700);
        }
        this.awaitingSearch = true;
      }
    },
  },

  created() {
    this.userID = this.$route.params.id;
    this.getCustomer();
  },

  methods: {
    
    getCustomer: function (page_url) {
      this.loading = true;
      let vm = this;
      page_url = page_url || this.app_base_url + '/customers/' + this.userID
      var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_billing_user") };
      axios.get(page_url, { headers, params: (this.searchFilters) })
      .then((response) => {
        this.loading = false;
        this.companyName = response.data.customer.company_name,
        this.SentEmails = response.data.CustomerSentEmails
        vm.makePagination(response.data)
        this.loading = false;
      });
    },

    makePagination: function (data) {

      let pagination = {
        current_page: data.CustomerSentEmails.current_page,
        last_page: data.CustomerSentEmails.last_page,
        next_page_url: this.app_base_url + '/customers/' + this.userID + '?page=' + (data.CustomerSentEmails.current_page + 1),
        prev_page_url: this.app_base_url + '/customers/' + this.userID + '?page=' + (data.CustomerSentEmails.current_page - 1),
      }
      
      this.pagination = pagination;
    },

    ResendEmail(rowID) {
      this.btnDisabled = true;

      var data = {
        user_id: this.$route.params.id,
        history_id: rowID
      };
      var headers = {
        Authorization:
          `Bearer ` + localStorage.getItem("rezo_billing_user"),
      };
      axios
        .post(this.app_base_url + "/history_mail/send_again", data, { headers })
        .then((response) => {
          if (response.status === 200) {
            this.btnDisabled = false;
            this.$toast.add({ severity: 'success', summary: 'Email Sent', detail: 'Email sent successfully.', life: 2000 });
          }
        }).catch((error) => {
          this.btnDisabled = false;
          this.$notify({
            type: "error",
            title: "Something Went Wrong",
            text: "Error!",
          });
          console.warn("Your Error is :", error);
        });
    },

    viewSentEmail(rowID) {
      this.loading = true;

      var data = {
        user_id: this.$route.params.id,
        sent_email_table_id: rowID
      };

      var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_billing_user") };
      axios
        .post(this.app_base_url + "/history_mail/view", data, { headers })
        .then((response) => {
          if (response.status === 200) {
            this.btnDisabled = false;
            this.loading = false;
            this.visibleRight = true;
            console.log("Email View:", response)
            this.emailViewSubject = response.data.subject;
            this.emailViewBody = response.data.body;
          }
        }).catch((error) => {
          this.btnDisabled = false;
          this.$notify({
            type: "error",
            title: "Something Went Wrong",
            text: "Error!",
          });
          console.warn("Your Error is :", error);
        });
    },
  },
};
</script>
