<template>
  <!-- DataTales Example -->
  <div class="card shadow mr-5">
    <div class="card-header" style="padding:0px;">
      <div class="row mt-3">
        <div class="col-md-6">
          <table-header :title="'Customers'" :hasLink="true" :linkTo="'/customer/create'" :linkText="'New Customer'" />
        </div>
        <div class="col-md-6">
          <form class="form-inline float-right mr-3">
            <div class="form-group">
              <input v-model="searchFilters.name" class="form-control form-control-sm" placeholder="Company Name"
                ref="search" />
            </div>

            <div class="form-group ml-1">
              <button type="button" class="btn btn-danger btn-sm" @click="resetSearch()">Reset</button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <ProgressSpinner v-if="loading" style="width:50px;height:50px" strokeWidth="1" fill="var(--surface-ground)"
      animationDuration=".5s" />
    <div class="card-body" v-else>
      <DataTable :value="customers.data" style="padding:4px;">
        <template  #empty>
            <div class="text-danger text-center">No customer found.</div>
        </template>
        <Column style="padding:4px;" field="company_name" header="Company Name" :sortable="true"></Column>

        <Column style="padding:4px;" field="phone" header="Phone #">
          <template #body="slotProps">
            <div v-if="slotProps.data.phone">
              <a v-bind:href="'tel:' + slotProps.data.phone">{{slotProps.data.phone}}</a>
            </div>
            <div v-else>
              <small>N/A</small>
            </div>
          </template>
        </Column>
        
        <Column style="padding:4px;" field="active" header="Status">
          <template #body="slotProps">
            <span v-if="slotProps.data.active">
              <small class="badge badge-success">Active</small>
            </span>
            <span v-else>
              <small class="badge badge-danger">Inactive</small>
            </span>
          </template>
        </Column>

        <Column style="padding:4px;" field="created_at" header="Created At" :sortable="true">
          <template #body="slotDate">
            {{ moment(slotDate.data.created_at).format("MM/DD/YYYY, h:mm:ss a") }}
          </template>
        </Column>

        <Column style="padding:4px;" header="Actions">
          <template #body="slotProps">
            <router-link v-tooltip.top="'Show Profile'" style="text-decoration: none;" :to="{name:'ShowCustomer', params: { id:slotProps.data.id}}"><i
                class="fas fa-eye btn btn-circle btn-secondary btn-sm mr-1" ></i></router-link>
            <router-link :to="{name:'EditCustomer', params: { id:slotProps.data.id}}"><i
                class="fas fa-edit btn btn-circle btn-primary btn-sm"></i></router-link>
                <button v-on:click="deleteCustomer(slotProps.data.id)" class="btn btn-circle btn-danger btn-sm ml-1"><i
                class="fas fa-trash"></i></button>
                
                <router-link :to="{name:'create/invoice',query: { activeTab: 'customerInvoices' }, params: { id:slotProps.data.id}}">
                  <button class="btn btn-info ml-2 btn-sm"><i class="fa fa-plus" aria-hidden="true"></i> Add Invoice </button>
                </router-link>
          </template>
        </Column>
      </DataTable>
      <div v-if="customers.data.length > 0" class="pagination d-flex justify-content-center">
        <button class="btn btn-outline-secondary pt-0 pb-0 mr-1" @click="getCustomers(customers.first_page_url)" :disabled="pagination.current_page == 1" style="font-size: 14px; height: 30px;"> First Page </button>
        <button class="btn btn-outline-secondary pt-0 pb-0 mr-2" @click="getCustomers(pagination.prev_page_url)" :disabled="pagination.current_page == 1" style="font-size: 14px; height: 30px;"> Previous </button>
        <label>Page {{ pagination.current_page }} of {{ pagination.last_page }}</label>
        <button class="btn btn-outline-secondary pt-0 pb-0 ml-2" @click="getCustomers(pagination.next_page_url)" :disabled="pagination.current_page == pagination.last_page" style="font-size: 14px; height: 30px;">Next </button>
        <button class="btn btn-outline-secondary pt-0 pb-0 ml-1" @click="getCustomers(customers.last_page_url)" :disabled="pagination.current_page == pagination.last_page" style="font-size: 14px; height: 30px;"> Last Page </button>

        <div class="ml-1">
          <select v-model="searchFilters.per_page" class="form-control" style="border: 1px solid #858796; height: 30px; padding-top: 2px;">
            <option selected :value="5">5</option>
            <option selected :value="10">10</option>
            <option selected :value="20">20</option>
            <option selected :value="50">50</option>
            <option selected :value="100">100</option>
          </select>
        </div>
      </div>
    </div>
    <Toast />
    <ConfirmDialog></ConfirmDialog>
  </div>
</template>
<script>
import Tooltip from 'primevue/tooltip';
import TableHeader from "../SharedComponents/TableHeader.vue";
import axios from "axios";
import moment from "moment";
import ConfirmDialog from 'primevue/confirmdialog';
import Toast from 'primevue/toast';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ProgressSpinner from 'primevue/progressspinner';
export default {
  directives: {
    'tooltip': Tooltip
  },

  components: {
    TableHeader,
    DataTable,
    Column,
    ProgressSpinner,
    ConfirmDialog,
    Toast
  },

  data() {
    return {
      searchFilters: {
        per_page:10
      },
      loading: false,
      customers: [],
      pagination: {},
      moment: moment,
      app_base_url: process.env.VUE_APP_BASE_URL,
    };
  },
  watch: {
    searchFilters: {
      deep: true,
      handler() {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.getCustomers()
            this.loading = true;
            this.awaitingSearch = false;
          }, 700);
        }
        this.awaitingSearch = true;
      }
    },
  },

  mounted() {
    this.$refs.search.focus();
  },

  created() {
    this.getCustomers();
  },

  methods: {
    getCustomers: function (page_url) {
      this.loading = true;
      let vm = this;
      page_url = page_url || this.app_base_url + '/customers'
      var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_billing_user") };
      axios.get(page_url, { headers, params: (this.searchFilters) })
      .then((response) => {
        this.loading = false;
        this.customers = response.data.customers;
        vm.makePagination(response.data)
        this.loading = false;
      });
    },

    makePagination: function (data) {

      let pagination = {
        current_page: data.pagination.current_page,
        last_page: data.pagination.last_page,
        next_page_url: this.app_base_url + '/customers?page=' + (data.pagination.current_page + 1),
        prev_page_url: this.app_base_url + '/customers?page=' + (data.pagination.current_page - 1),
      }
      
      this.pagination = pagination;
    },

    deleteCustomer: function (userID) {
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_billing_user"),
      };

      this.$confirm.require({
        message: 'Are you sure you want to proceed?',
        header: 'Delete',
        acceptClass: 'p-button-success',
        rejectClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          axios
          .delete(this.app_base_url + "/customers/" + userID, { headers: headers })
          .then((response) => {
            this.getCustomers();
            this.$toast.add({ severity: 'success', summary: 'Deleted', detail: 'Record deleted successfully!', life: 2000 });
            console.info("Customer deleted: ", response);
          })
          .catch((error) => {
            console.warn("Your Error is :", error);
          });
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: 'Record Safe', detail: 'Your record is safe!', life: 2000 });
        }
      });
    },

    resetSearch: function () {
      this.searchFilters = {};
    },
  }
};
</script>