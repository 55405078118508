<template>
    <div class="row justify-content-center" v-if="loading">
        <ProgressSpinner style="width:50px;height:50px" strokeWidth="1" fill="var(--surface-ground)"
            animationDuration=".5s" />
    </div>
    <div v-else>
        <small>{{Invoice.is_saas_payment? "This is a SAAS payment":"This is not a SAAS payment"}}</small>
        <div class="row">
            <div class="col-md-12"><strong class="mr-2">Invoice #:</strong> {{Invoice.invoice_no}}</div>
            <div class="col-md-12"><strong class="mr-2">Invoice Date:</strong> {{moment(Invoice.date).format("MM/DD/YYYY")}}</div>
            <div class="col-md-12"><strong class="mr-2">Invoice Amount:</strong> ${{ USDCurrancyformat(Invoice.invoice_amount) }}</div>
            <div class="col-md-12"><strong class="mr-2">Notes: </strong>{{Invoice.notes}}</div>
            <div class="col-md-12"><strong class="mr-2">PDF Invoice: </strong>
                <button v-if="Invoice.document_file != null" class="btn btn-sm pt-0 pb-0 btn-light" @click="downloadFile(Invoice.id)" :disabled="btnDisabled"><div v-if="!downladbtnDisabled">Invoice</div><i v-if="downladbtnDisabled" class="pi pi-spin pi-spinner" style="font-size: 1rem"></i></button>
                <small v-else class="text-danger">No invoice uploaded</small>
            </div>
            <div class="col-md-12">
                <div class="d-flex justify-content-center">
                    <button class="btn mt-3 btn-sm btn-success" @click="SendInvoiceMail()" :disabled="btnDisabled">{{btnTitle}} <i v-if="btnDisabled" class="pi pi-spin pi-spinner" style="font-size: 1rem"></i></button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import axios from "axios";
import moment from "moment";
import 'primeicons/primeicons.css';
import ProgressSpinner from 'primevue/progressspinner';
export default {
    props: ["ViewInvoiceID"],
    components: { ProgressSpinner },
    data() {
        return {
            app_base_url: process.env.VUE_APP_BASE_URL,
            btnTitle: "Send again",
            btnDisabled: false,
            downladbtnDisabled: false,
            Invoice: [],
            loading: true,
            moment: moment,
        };
    },
    created() {
        this.getInvoice(this.ViewInvoiceID);
    },


    methods: {
        getInvoice: function(id) {
            this.editBtnDisabled = true;
            var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_billing_user") };
            axios.get(this.app_base_url + '/customer_invoices/' + id, { headers })
            .then((response) => {
                this.loading = false;
                this.Invoice = response.data.CustomerInvoice;
            }).catch((error) => {
                console.warn('Your Error is :', error);
                this.$notify({
                    type: "error",
                    title: "Something Went Wrong",
                    text: "Error!",
                });
            })
        },
        
        USDCurrancyformat(value) {
            let val = (value/1).toFixed(2).replace(',', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        
        hideDialog()
        {
            this.$emit('Display',false)
        },

        SendInvoiceMail() {
            this.btnTitle = "Please wait...";
            this.btnDisabled = true;

            var data = {
                user_id: this.$route.params.id,
                id: this.ViewInvoiceID
            };
            var headers = {
                Authorization:
                `Bearer ` + localStorage.getItem("rezo_billing_user"),
            };
            axios
            .post(this.app_base_url + "/invoice_file/send_again", data, { headers })
            .then((response) => {
                if (response.status === 200) {
                    this.hideDialog();
                    this.btnDisabled = false;
                }
            }).catch((error) => {
                this.btnDisabled = false;
                this.$notify({
                type: "error",
                title: "Something Went Wrong",
                text: "Error!",
                });
                console.warn("Your Error is :", error);
            });
        },

        downloadFile(invoiceId){
            this.downladbtnDisabled = true;
            var data = {
                user_id: this.$route.params.id,
                id: invoiceId
            };

            var headers = {
                Authorization:
                `Bearer ` + localStorage.getItem("rezo_billing_user"),
            };
            axios
            .post(this.app_base_url + "/invoice_file/download", data, { headers,  responseType: 'blob' })
            .then((response) => {
                if (response.status === 200) {
                this.downladbtnDisabled = false;
                // console.log(response);
                const file = new Blob(
                [response.data], 
                {type: 'application/pdf'});
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(fileURL);
                }
            }).catch((error) => {
                this.downladbtnDisabled = false;
                this.$notify({
                type: "error",
                title: "Something Went Wrong",
                text: "Error!",
                });
                console.warn("Your Error is :", error);
            });
        },
    }
};
</script>