<template>
  <p class="h5 text-primary ml-3"><strong>
    {{ title }} |
    <router-link v-if="hasLink" class="btn btn-primary btn-sm ml-1" :to="linkTo"
      ><i class="fa fa-plus"></i> {{linkText}}</router-link
    >
  </strong>
  </p>
</template>

<script>
export default {
  props: ["title", "hasLink",'linkTo','linkText'],
};
</script>

<style>
</style>