<template>
  <div id="wrapper">
    <div id="content-wrapper" class="d-flex flex-column">
      <div id="content" style="#898989">

        <!-- Header -->
        <nav class="navbar navbar-expand-lg navbar-secondary" style="height: 140px; background: black;">
          <img :src="'/img/logo.png'" class="float-left" width="100" />
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="content_css collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto ml-5">
              <li class="nav-item active">
                <h3 class="mt-3 ml-5 text-light">Rezosystems Billing System</h3>
              </li>
            </ul>
          </div>
        </nav>
        <!-- /Header -->
        
        <!-- Content -->
        <div class="container-fuild  full-height" style="height: 400px;">
          <div class="row justify-content-center">
            <div>
              <div class="card o-hidden border-0 shadow-lg my-4">
                <div class="card-body p-0">
                  <!-- Nested Row within Card Body -->
                  <div class="row justify-content-center">
                    <div style="width: 430px;">
                      <div class="pl-5 pr-5 pt-3 pb-2">
                        <div class="text-center">
                          <h1 class="h4 text-gray-900 mb-4">Customer Login</h1>
                        </div>
                        <form @submit.prevent="formSubmit" class="user">
                          <div class="form-group">
                            <input type="text" v-model="email" class="form-control form-control" id="exampleInputEmail"
                              aria-describedby="emailHelp" placeholder="Email" />
                            <small class="text-danger" v-if="v$.email.$error">{{
                            v$.email.$errors[0].$message
                            }}</small>
                          </div>
                          <div class="form-group">
                            <input v-model="password" type="password" class="form-control" id="exampleInputPassword"
                              placeholder="Password" />
                            <small class="text-danger" v-if="v$.password.$error">
                              <p v-for="validationError in v$.password.$errors" :key="validationError">
                                {{ validationError.$message }}
                              </p>
                            </small>
                            <small class="text-danger" v-else>
                              <p class="text-danger">{{loginErrMsg}}</p>
                            </small>
                          </div>

                          <button class="btn btn-dark" :disabled="isDisabled">
                            {{btnTitle}} <i v-if="isDisabled" class="pi pi-spin pi-spinner" style="font-size: 1rem"></i>
                          </button>

                          <div class="mt-3">
                            <router-link class="text-dark " to="/forgot/password">
                              <span class="text-dark">Forgot Password?</span>
                            </router-link>
                          </div>
                          
                          <!-- <a href="#" class="ml-3" style="text-decoration: underline;">Forget password</a> -->
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="">
            <Dialog position="center" :style="{ width: '60vw' }" v-model:visible="display">
            Welcome to our new system! We take the security of our customers' information seriously, which is why we require all customers visiting our new system to change their password upon initial login. This is a standard security practice that ensures that your account is protected with a unique and strong password that only you have access to. Please click "Update Password" to create a new password that meets our password strength requirements. We appreciate your cooperation in helping us maintain a safe and secure online environment for our customers. If you have any questions or concerns, please do not hesitate to contact our support team for assistance.
            <template #footer>
                  <Button @click="closePopup" class="p-button-raised p-button-rounded p-button-secondary" label="Already Updated" />
                  <Button @click="redirectLoginPage" class="p-button-raised p-button-rounded p-button-success" label="Update Password" autofocus/>
              </template>
          </Dialog>
          </div>
        </div>
        <!-- /Content -->
      </div>

      <!-- Footer -->
      <footer class="sticky-footer" style="
        background: black; 
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        text-align: center;">
        <div class="container my-auto">
          <div class="copyright text-light text-center my-auto">
            <span>Copyright &copy; {{ moment(new Date()).format('YYYY') }} Rezo Billing. All rights reserved.</span>
          </div>
        </div>
      </footer>
      <!-- /Footer -->
    </div>
  </div>
</template>

<script>
import useValidate from "@vuelidate/core";
import { required, minLength, email } from "@vuelidate/validators";
import axios from "axios";
import 'primeicons/primeicons.css';
import moment from "moment";
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
export default {
  data() {
    return {
      v$: useValidate(),
      app_base_url: process.env.VUE_APP_BASE_URL,
      email: null,
      moment: moment,
      password: null,
      loginErrMsg: null,
      btnTitle: "Login",
      isDisabled: false,
      display: true
    };
  },

  components: {
    Dialog,Button
  },

  created() {
    if (this.$route.redirectedFrom.query.password_reset_token)
    {
      this.$router.push({ name: 'updatePassword', params: { password_reset_token: this.$route.redirectedFrom.query.password_reset_token }})
    }

  },

  validations() {
    return {
      email: { required, minLength: minLength(3), email },
      password: { required, minLength: minLength(3) },
    };
  },

  methods: {

    closePopup() {
      this.display = false;
    },
    redirectLoginPage() {
      this.$router.push({ name: 'forgotPassword'})
    },
    formSubmit() {
      this.v$.$validate();
      if (this.v$.$error) {
        console.log("errors");
      } else {

        this.isDisabled = true;
        this.btnTitle = "Please wait... ";

        var data = {
          email: this.email,
          password: this.password,
        };
        var headers = {};
        axios
          .post(this.app_base_url + "/front/login", data, { headers: headers })
          .then((response) => {
            localStorage.setItem("rezo_billing_front_user", response.data.token);
            localStorage.setItem("logged_user_id", response.data.userID);
            localStorage.setItem("user_role", response.data.userRole);
            this.$store.dispatch("getAuthFrontUser");
            this.$router.push({ name: "FrontDashboard" });
            this.isDisabled = false;

          },
            (error) => {
              this.loginErrMsg = error.response.data.message;
              console.log(error.response.data.error);
              // this.$notify({
              //   type: "error",
              //   title: "Something went wrong!",
              //   text: error.response.data.message,
              // });

              this.isDisabled = false;
              this.btnTitle = "Login";
            });
      }
    },
  },
};
</script>