<template>
  <div>
    <form @submit.prevent="formSubmit" class="user">
      <div class="col-md-12" style="padding: 0px;">
        <small class="required" for="ConfigDomain"> Config Domain</small>
        <input v-model="configDomain" type="text" class="form-control form-control-sm" id="ConfigDomain" />
        <span class="text-danger" v-if="v$.configDomain.$error">
          <small class="help-block" v-for="validationError in v$.configDomain.$errors" :key="validationError">
            {{ validationError.$message }}
          </small>
        </span>

        <small class="required" for="configKey"> Config Key</small>
        <input v-model="configKey" type="text" class="form-control form-control-sm" id="configKey" />
        <span class="text-danger" v-if="v$.configKey.$error">
          <small class="help-block" v-for="validationError in v$.configKey.$errors" :key="validationError">
            {{ validationError.$message }}
          </small>
        </span>
      </div>
      <button class="btn btn-info btn-sm mt-2" :disabled="btnDisabled">{{ btnTitle }}</button>
    </form>
  </div>
</template>

<script>
import useValidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import axios from "axios";
export default {
  data() {
    return {
      v$: useValidate(),
      configDomain: null,
      configKey: null,
      isDisabled: false,
      btnTitle: "Save Mailgun Details",
      app_base_url: process.env.VUE_APP_BASE_URL,
    };
  },

  validations() {
    return {
      configDomain: {
        required: helpers.withMessage('Domain field is required.', required),
      },
      configKey: {
        required: helpers.withMessage('Config key is required', required),
      },
    };
  },

  created() {
    this.getSetting();
  },

  methods: {
    getSetting() {
      var mailId = 3;
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_billing_user"),
      };

      axios
        .get(this.app_base_url + "/settings/" + mailId, { headers })
        .then((response) => {
          console.log("TESTED ", response.data);
          this.configDomain = response.data.config_domain,
            this.configKey = response.data.config_key
        })
        .catch((error) => {
          console.error("Your Error is :", error);
        });
    },

    hideDialog() {
      this.$emit('Display', false)
    },

    formSubmit() {
      this.v$.$validate();
      if (this.v$.$error) {
        console.log("errors");
        //event.preventDefault();
      } else {
        this.isDisabled = true;
        this.btnTitle = "Please wait...";

        var data = {
          setting_id: 3,
          config_domain: this.configDomain,
          config_key: this.configKey,
        };
        var headers = {
          Authorization:
            `Bearer ` + localStorage.getItem("rezo_billing_user"),
        };

        axios.post(this.app_base_url + "/settings", data, { headers }).then(
          (response) => {
            if (response.status === 200) {
              this.hideDialog();
              this.btnTitle = "Save Mailgun Details";
              this.isDisabled = false;
              this.$notify({
                type: "success",
                title: "Success",
                text: "Saved Successfully!",
              });
              console.log("Form submitted successfully");
              this.$router.push({ path: "/settings/mail" });
            }
            console.log(response);
          },
          (error) => {
            this.isDisabled = false;
            this.btnTitle = "Save Mailgun Details";
            console.log(error.response.data.error);
            this.$notify({
              type: "error",
              title: "Something went wrong!",
              text: error.response.data.error,
            });
          }
        );
      }
    },
  },
}
</script>