<template>
  <page-header :title="formTitle" />
  <div class="card col-md-6 mb-3">
    <div class="card-body" style="line-height: 4px;">
      <div class="row justify-content-center" v-if="loading">
        <ProgressSpinner 
          style="width:50px;height:50px" 
          strokeWidth="1" 
          fill="var(--surface-ground)"
          animationDuration=".5s"
        />
      </div>
      <div v-else>
        <form @submit.prevent="formSubmit" class="user">
          <div class="form-group row mt-3">
            <div class="col-sm-4">
              <label class="required" for="firstName">First Name</label>
              <input type="text" autocomplete="chrome-off" v-model="firstName"
                class="form-control form-control-sm mb-1" id="firstName"/>
              <span class="text-danger" v-if="v$.firstName.$error">
                <small class="help-block" v-for="validationError in v$.firstName.$errors" :key="validationError">
                  {{ validationError.$message }}
                </small>
              </span>
            </div>
            <div class="col-sm-4">
              <label class="required" for="lastName">Last Name</label>
              <input type="text" autocomplete="chrome-off" v-model="lastName"
                class="form-control form-control-sm mb-1" id="lastName"/>
              <span class="text-danger" v-if="v$.lastName.$error">
                <small class="help-block" v-for="validationError in v$.lastName.$errors" :key="validationError">
                  {{ validationError.$message }}
                </small>
              </span>
            </div>
            <div class="col-sm-4">
              <label class="required" for="role">Role</label>
              <select v-model="userRole" class="form-control form-control-sm" id="selectStates">
                <option :value="null">--Select--</option>
                <option value="Admin">Admin</option>
                <option value="Staff">Staff</option>
                <option value="Developer">Developer</option>
              </select>
              <span class="text-danger" v-if="v$.userRole.$error">
                <small class="help-block mt-1" v-for="validationError in v$.userRole.$errors" :key="validationError">
                  {{ validationError.$message }}
                </small>
              </span>
            </div>
          </div>
          
          <div class="form-group row mt-3">
            <div class="col-sm-6">
              <label for="contactPhone">Phone</label>
              <InputMask
                v-model="phone"
                mask="(999) 999-9999"
                type="text"
                class="form-control form-control-sm"
                id="contactPhone"
                autocomplete="chrome-off"
              />
            </div>
            <div class="col-sm-6">
              <label class="required" for="Email">Email</label>
              <input type="text" autocomplete="chrome-off" v-model="Email"
                class="form-control form-control-sm mb-1" id="Email"/>
              <span class="text-danger" v-if="v$.Email.$error">
                <small class="help-block" v-for="validationError in v$.Email.$errors" :key="validationError">
                  {{ validationError.$message }}
                </small>
              </span>
            </div>
          </div>
          
          <div class="form-group row mt-3">
            <div class="col-sm-6">
              <label :class="userID ? '' : 'required'" for="Password">{{userID ? 'Password' : 'Password'}}</label>
              <Password v-model="Password" :toggleMask="true" :inputStyle="{height: 31, width: 213}">
                <template #header>
                  <h6></h6>
                  <h6 v-if="userID" class="text-danger"> Change the current password</h6>
                  <h6 v-else class="text-success">Pick a password</h6>
                </template>
                <template #footer>
                  <Divider />
                  <p class="mt-2">Suggestions</p>
                  <ul class="pl-2 ml-2 mt-0" style="line-height: 1.2">
                    <li>At least one lowercase</li>
                    <li>At least one uppercase</li>
                    <li>At least one numeric</li>
                    <li>Minimum 8 characters</li>
                  </ul>
                </template>
              </Password>
              <span class="text-danger " v-if="v$.Password.$error">
                <small class="help-block mt-1" v-for="validationError in v$.Password.$errors" :key="validationError">
                  {{ validationError.$message }}
                </small>
              </span>
            </div>
            <div class="col-sm-6">
              <label for="ConfirmPassword">Confirm Password</label>
              <input type="password" autocomplete="chrome-off" v-model="ConfirmPassword" class="form-control form-control-sm mb-1" id="ConfirmPassword"/>
              <span class="text-danger" v-if="v$.ConfirmPassword.$error">
                <small class="help-block" v-for="validationError in v$.ConfirmPassword.$errors" :key="validationError">
                  {{ validationError.$message }}
                </small>
              </span>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <button class="btn btn-primary btn-sm" :disabled="btnDisabled">{{btnTitle}} <i v-if="btnDisabled" class="pi pi-spin pi-spinner" style="font-size: 1rem"></i></button>
            </div>
            <div class="col-md-8">
              <div class="form-inline">
                <div class="form-group mr-2">
                  <label class="switch">
                    <input type="checkbox" id="isEmailedLoginDetails" v-model="isEmailedLoginDetails" />
                    <span class="slider round"></span>
                  </label>
                  <label for="isEmailedLoginDetails" class="mr-1">Email Login Details</label>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import useValidate from "@vuelidate/core";
  import { 
    maxLength,
    minLength,
    required, 
    helpers,
    requiredUnless,
    sameAs,
    email
  } from "@vuelidate/validators";
  import PageHeader from "../SharedComponents/PageHeader.vue";
  import axios from "axios";
  import ProgressSpinner from 'primevue/progressspinner';
  import 'primeicons/primeicons.css';
  import InputMask from 'primevue/inputmask';
  import Password from 'primevue/password';

  export default {
    components: { 
      PageHeader,
      InputMask,
      ProgressSpinner,
      Password
    },
    data() {
      return {
        app_base_url: process.env.VUE_APP_BASE_URL,
        v$: useValidate(),
        formTitle: "New Admin",
        btnDisabled: false,
        passwordValidiation: true,
        btnTitle: "Create",
        loading: true,

        //Fields
        userID: null,
        firstName: null,
        lastName: null,
        userRole: null,
        phone: null,
        Email: null,
        Password: null,
        ConfirmPassword: null,
        isEmailedLoginDetails: true,
        is_create_page: true,
      };
    },

    validations() {
      return {
        firstName: {
          maxLength: maxLength(30),
          required: helpers.withMessage('First name is required.', required),
        },
        
        lastName: {
          maxLength: maxLength(30),
          required: helpers.withMessage('Last name is required.', required),
        },
        
        userRole: {
          required: helpers.withMessage('Role is required.', required),
        },

        Email: {
          required: helpers.withMessage('Email is required.', required),
          email,
        },

        Password: {
          requiredIf: requiredUnless(this.userID),
          // required: helpers.withMessage('Password is required.', required),
          minLength: minLength(6),
        },

        ConfirmPassword: {
          minLength: minLength(6),
          requiredIf: requiredUnless(this.userID),
          sameAs: helpers.withMessage('Password not match!', sameAs(this.Password)),
        },
        
      };
    },


    created() {
      this.loading = false;

      if (this.$route.params.id) {
        this.formTitle = "Edit Admin";
        this.userID = this.$route.params.id;
        this.getAdmin();
        this.btnTitle = "Update"
        this.loading = true;
        this.isEmailedLoginDetails = false;
        this.is_create_page = false;
      }
    },

    methods: {

      getAdmin: function () {
        var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_billing_user") };
        axios.get(this.app_base_url + '/admins/' + this.userID, { headers })
        .then((response) => {
            this.loading = false;
            console.info("Edit Admin: ", response.data.admin.email);
            this.firstName = response.data.admin.first_name,
            this.lastName = response.data.admin.last_name,
            this.role = response.data.admin.role,
            this.userRole = response.data.admin.role,
            this.phone = response.data.admin.phone,
            this.Email = response.data.admin.email
            // this.isEmailedLoginDetails = response.data.admin.is_emailed_login_details?true:false
          }).catch((error) => {
            console.warn('Your Error is :', error);
          })
      },

      formSubmit() {
        this.v$.$validate();
        if (this.v$.$error) {
          console.log("errors", this.v$.$error);
        } else {

          this.btnDisabled = true;
          this.btnTitle = "Please wait...";

          var data = {
            user_id: this.userID,
            first_name: this.firstName,
            last_name: this.lastName,
            role: this.userRole,
            phone: this.phone,
            email: this.Email,
            password: this.Password,
            is_emailed_login_details: this.isEmailedLoginDetails,
            is_create_page: this.is_create_page
          };

          var headers = {
            Authorization:
              `Bearer ` + localStorage.getItem("rezo_billing_user"),
          };
          axios
            .post(this.app_base_url + "/admins", data, { headers })
            .then((response) => {
              if (response.status === 200) {
                this.btnDisabled = false;
                // this.btnTitle = this.$route.name == "EditCustomer" ? "Update" : "Create";

                this.$notify({
                  type: "success",
                  title: "Success",
                  text: "Save Successfully!",
                });
                this.$router.push('/admins')
              }
            }).catch((error) => {

              let error_message = '';
              if (error.response.data.Error) {
                error_message = error.response.data.Error;
              }
              else {
                error_message = error;
              }

              
              this.btnDisabled = false
              
              if (this.$route.params.id) {
                this.btnTitle = 'Update';
              }
              else {
                this.btnTitle = 'Create';
              }
              
              this.$notify({
                type: "error",
                title: error_message,
                text: "Error!",
              });

              console.warn("Your Error is :", error);
            });
        }
      },
    }
  };
</script>