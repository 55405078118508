import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Auth/Login.vue'
import Dashboard from '../views/Dashboard.vue'
 
// Comment added for push

//Customer Frontend
import FrontLogin from '../views/Auth/FrontLogin.vue'
import FrontDashboard from '../views/FrontDashboard.vue'
import PayInvoice from '../views/frontend/partials/PayInvoice.vue'

import NotFound from '../views/NotFound.vue';
import ThankYou from '../views/frontend/partials/ThankYou.vue';


import Templates from "../views/EmailTemplates/Templates.vue"
import EditTemplate from "../views/EmailTemplates/Create.vue"

import Customers from "../views/Customers/Customers.vue"
import NewCustomer from "../views/Customers/Create.vue"
import EditCustomer from "../views/Customers/Create.vue"
import ShowCustomer from "../views/Customers/ShowCustomer.vue"

import Admins from "../views/Admins/Admins.vue"
import NewAdmin from "../views/Admins/Create.vue"
import EditAdmin from "../views/Admins/Create.vue"

import Basket from '../views/Basket.vue'

import GeneralSetting from '../views/Settings/GeneralSetting.vue'
// import SiteSetting from '../views/Settings/SiteSetting.vue'
import MailSetting from '../views/Settings/MailSetting.vue'
import BackupSetting from '../views/Settings/BackupSetting.vue'
import BrainTreeSetting from '../views/Settings/BrainTreeSetting.vue'

import forgotPassword from '../views/Settings/forgotPassword.vue'
import updatePassword from '../views/Settings/updatePassword.vue'

const routes = [
//--------------------------- Frontend Routes-----------------------------//
  {
    path: '/front/login',
    name: 'FrontLogin',
    meta: { 
      auth: false ,
      layout: 'Frontlogin'
    },
    component: FrontLogin,
    beforeEnter(to, from, next){
      if (localStorage.getItem("user_role")) {
        router.back();
      } else {
        next();
      }
    }
  },
  {
    path: '/forgot/password',
    name: 'forgotPassword',
    meta: { auth: false },
    component: forgotPassword,
    beforeEnter(to, from, next) {
      next();
    }
  },
  {
    path: '/update/password',
    name: 'updatePassword',
    meta: { auth: false },
    component: updatePassword,
    beforeEnter(to, from, next) {
      next();
    }
  },
  {
    path: '/front',
    name: 'FrontDashboard',
    meta: { auth: true },
    component: FrontDashboard,
    beforeEnter(to, from, next) {
      var role = localStorage.getItem("user_role");
      if (role == 'Staff') {
        next();
      } else {
        router.back();
      }
    }
  },
  {
    path:"/front/:id/pay",
    name: 'PayInvoice',
    meta: { auth: true },
    component:PayInvoice,
    beforeEnter(to, from, next) {
      var role = localStorage.getItem("user_role");
      if (role == 'Staff') {
        next();
      } else {
        router.back();
      }
    }
  },

//--------------------------- Admins Routes -----------------------------//

  {
    path: '/basket',
    name: 'Basket',
    meta: { auth: true },
    component: Basket,
    beforeEnter(to, from, next) {
      var role = localStorage.getItem("user_role");
      if (role == 'Admin') {
        next();
      } else {
        router.back();
      }
    }
  },

//--------------------------- Customers Routes-----------------------------//
  {
    path:"/customers",
    name:"Customers",
    meta: { auth: true },
    component:Customers,
    beforeEnter(to, from, next) {
      var role = localStorage.getItem("user_role");
      if (role == 'Admin') {
        next();
      } else {
        router.back();
      }
    }
  },
  {
    path:"/customer/create",
    name:"NewCustomer",
    meta: { auth: true },
    component:NewCustomer,
    beforeEnter(to, from, next) {
      var role = localStorage.getItem("user_role");
      if (role == 'Admin') {
        next();
      } else {
        router.back();
      }
    }
  },
  {
    path:"/customer/:id/edit",
    name:"EditCustomer",
    meta: { auth: true },
    component:EditCustomer,
    beforeEnter(to, from, next) {
      var role = localStorage.getItem("user_role");
      if (role == 'Admin') {
        next();
      } else {
        router.back();
      }
    }
  },
  {
    path:"/customer/:id/show",
    name:"ShowCustomer",
    meta: { auth: true },
    component:ShowCustomer,
    beforeEnter(to, from, next) {
      var role = localStorage.getItem("user_role");
      if (role == 'Admin') {
        next();
      } else {
        router.back();
      }
    }
  },
  {
    path:"/customer/:id/show",
    name:"create/invoice",
    meta: { auth: true },
    component:ShowCustomer,
    beforeEnter(to, from, next) {
      var role = localStorage.getItem("user_role");
      if (role == 'Admin') {
        next();
      } else {
        router.back();
      }
    }
  },
  {
    path: '/login',
    name: 'Login',
    meta: { 
      auth: false ,
      layout: 'login'
    },
    component: Login,
    beforeEnter(to, from, next){
      if (localStorage.getItem("user_role")) {
        router.back();
      } else {
        next();
      }
    }
  },
  {
    path: '/',
    name: 'Dashboard',
    meta: { auth: true },
    component: Dashboard,
    beforeEnter(to, from, next) {
      var role = localStorage.getItem("user_role");
      if (role == 'Admin') {
        next();
      } else {
        next('/front');
      }
    }
  },
  {
    path:"/templates",
    name:"Templates",
    meta: { auth: true },
    component:Templates,
    beforeEnter(to, from, next) {
      var role = localStorage.getItem("user_role");
      if (role == 'Admin') {
        next();
      } else {
        router.back();
      }
    }
  },
  {
    path:"/template/:id/edit",
    name:"EditTemplate",
    meta: { auth: true },
    component:EditTemplate,
    beforeEnter(to, from, next) {
      var role = localStorage.getItem("user_role");
      if (role == 'Admin') {
        next();
      } else {
        router.back();
      }
    }
  },

  {
    path:"/admins",
    name:"Admins",
    meta: { auth: true },
    component:Admins,
    beforeEnter(to, from, next) {
      var role = localStorage.getItem("user_role");
      if (role == 'Admin') {
        next();
      } else {
        router.back();
      }
    }
  },
  {
    path:"/admins/create",
    name:"NewAdmin",
    meta: { auth: true },
    component:NewAdmin,
    beforeEnter(to, from, next) {
      var role = localStorage.getItem("user_role");
      if (role == 'Admin') {
        next();
      } else {
        router.back();
      }
    }
  },
  {
    path:"/admins/:id/edit",
    name:"EditAdmin",
    meta: { auth: true },
    component:EditAdmin,
    beforeEnter(to, from, next) {
      var role = localStorage.getItem("user_role");
      if (role == 'Admin') {
        next();
      } else {
        router.back();
      }
    }
  },
  
//--------------------------- Settings Routes -----------------------------//

  {
    path:"/settings/general",
    name:"GeneralSetting",
    meta: { auth: true },
    component:GeneralSetting,
    beforeEnter(to, from, next) {
      var role = localStorage.getItem("user_role");
      if (role == 'Admin') {
        next();
      } else {
        router.back();
      }
    }
  },
  // {
  //   path:"/settings/site",
  //   name:"SiteSetting",
  //   meta: { auth: true },
  //   component:SiteSetting,
  //   beforeEnter(to, from, next) {
  //     var role = localStorage.getItem("user_role");
  //     if (role == 'Admin') {
  //       next();
  //     } else {
  //       router.back();
  //     }
  //   }
  // },
  {
    path:"/settings/mail",
    name:"MailSetting",
    meta: { auth: true },
    component:MailSetting,
    beforeEnter(to, from, next) {
      var role = localStorage.getItem("user_role");
      if (role == 'Admin') {
        next();
      } else {
        router.back();
      }
    }
  },
  {
    path:"/settings/backup",
    name:"BackupSetting",
    meta: { auth: true },
    component:BackupSetting,
    beforeEnter(to, from, next) {
      var role = localStorage.getItem("user_role");
      if (role == 'Admin') {
        next();
      } else {
        router.back();
      }
    }
  },
  {
    path:"/settings/braintree",
    name:"BrainTreeSetting",
    meta: { auth: true },
    component:BrainTreeSetting,
    beforeEnter(to, from, next) {
      var role = localStorage.getItem("user_role");
      if (role == 'Admin') {
        next();
      } else {
        router.back();
      }
    }
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'Not Found',
    component: NotFound
  },
  
  {
    path: '/front/thank-you',
    name: 'ThankYou',
    component: ThankYou
  }
  
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if(localStorage.getItem("user_role") == 'Admin')
  {
    //Admin Template
    var isloggedin= localStorage.getItem("rezo_billing_user");
    if (to.matched.some(record => record.meta.auth)) 
    {
      if (!isloggedin) {
        next({ name: 'Login' })
      } else {
        next()
      }
    } else {
      next()
    }
  }
  else
  {
    //Staff Template
    var isFrontloggedin= localStorage.getItem("rezo_billing_front_user");
    if (to.matched.some(record => record.meta.auth))
    {  
      if (!isFrontloggedin) {
        next({ name: 'FrontLogin' })
      } else {
        next()
      }
    } else {
      next()
    }
  }
})

export default router
