<template>
  <!-- DataTales Example -->
  <div class="card shadow mr-5">
    <div class="card-header" style="padding:0px;">
      <div class="row mt-3">
        <div class="col-md-6">
          <table-header :title="'Templates'" :hasLink="false" :linkTo="'/customer/create'" :linkText="'New Template'" />
        </div>
      </div>
    </div>

    <ProgressSpinner v-if="loading" style="width:50px;height:50px" strokeWidth="1" fill="var(--surface-ground)"
      animationDuration=".5s" />
    <div class="card-body" v-else>
      <DataTable :value="templates" style="padding:4px;">
        <template #empty>
          <div class="text-danger text-center">No email template found.</div>
        </template>
        <Column style="padding:4px;" field="title" header="Email Template" :sortable="true"></Column>

        <!-- <Column style="padding:4px;" field="active" header="Status">
          <template #body="slotProps">
            <span v-if="slotProps.data.active">
              <small class="badge badge-success">Active</small>
            </span>
            <span v-else>
              <small class="badge badge-danger">Inactive</small>
            </span>
          </template>
        </Column> -->

        <Column style="padding:4px;" field="created_at" header="Created At" :sortable="true">
          <template #body="slotDate">
            {{ moment(slotDate.data.created_at).format("MM/DD/YYYY") }}
          </template>
        </Column>

        <Column style="padding:4px;" header="Actions">
          <template #body="slotProps">
            <router-link :to="{name:'EditTemplate', params: { id:slotProps.data.id}}"><i
                class="fas fa-edit btn btn-circle btn-primary btn-sm"></i></router-link>
          </template>
        </Column>
        
      </DataTable>
    </div>
  </div>
</template>
<script>
import TableHeader from "../SharedComponents/TableHeader.vue";
import axios from "axios";
import moment from "moment";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ProgressSpinner from 'primevue/progressspinner';
export default {

  components: {
    TableHeader,
    DataTable,
    Column,
    ProgressSpinner,
  },

  data() {
    return {
      loading: true,
      templates: [],
      moment: moment,
      app_base_url: process.env.VUE_APP_BASE_URL,
    };
  },

  created() {
    this.gettemplates();
  },

  methods: {
    gettemplates: function () {
      var headers = {
        Authorization: `Bearer ` + localStorage.getItem("rezo_billing_user"),
      };
      axios
        .get(this.app_base_url + '/email_templates', {
          headers
        })
        .then((response) => {
          console.info('templates List', response.data.email_templates.data)
          this.templates = response.data.email_templates.data;
          this.loading = false;
        });
    },
  }
};
</script>