<template>
    <div class="row justify-content-center" v-if="loading">
        <ProgressSpinner
            style="width:50px;height:50px; padding-top: 220px;"
            strokeWidth="1"
            fill="var(--surface-ground)"
            animationDuration=".5s"
        />
    </div>
    <div v-else>
        <!-- <div class="alert alert-success" v-if="nonce">
            Successfully generated nonce.
        </div> -->
        <div class="alert alert-danger" v-if="error">
            {{ error }}
        </div>

        <ul class="nav nav-pills nav-fill mb-3">
            <li class="nav-item mx-1">
                <a
                    class="nav-link "
                    :class="{ active: add_new_card }"
                    @click="toggleCardSection('new_card')"
                    href="javascript:void(0)"
                    ><i class="fa fa-user" aria-hidden="true"></i> New Card</a
                >
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    :class="{ active: !add_new_card }"
                    @click="toggleCardSection('saved_card')"
                    href="javascript:void(0)"
                    ><i class="fa fa-user" aria-hidden="true"></i> Saved
                    Cards</a
                >
            </li>
        </ul>

        <div  :style="{'display':display_prop_new_card}">
            <form>
                <div class="form-group">
                    <div class="row">
                        <div class="col-4">
                            <label class="required">Credit Card Number</label>
                            <div
                                id="creditCardNumber"
                                class="form-control"
                            ></div>
                        </div>
                        <div class="col-6">
                            <div class="form-inline">
                                <div
                                    class="form-group"
                                    style="margin-top: 32px;"
                                >
                                    <label class="switch">
                                        <input
                                            type="checkbox"
                                            id="isSavedCard"
                                            v-model="isSavedCard"
                                        />
                                        <span class="slider round"></span>
                                    </label>
                                    <label
                                        for="isSavedCard"
                                        style="font-size: 14px; margin-top: 2px; margin-left: -6px;"
                                        >Save this card for future
                                        payments</label
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="row">
                        <div class="col-3">
                            <label class="required">Expire Month</label>
                            <div id="expireMonth" class="form-control"></div>
                        </div>
                        <div class="col-3">
                            <label class="required">Expire Year</label>
                            <div id="expireYear" class="form-control"></div>
                        </div>
                        <div class="col-2">
                            <label class="required">CVV</label>
                            <div id="cvv" class="form-control"></div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-5">
                        <button
                            class="btn btn-primary btn-sm"
                            @click.prevent="payWithCreditCard"
                            :disabled="isBtnDisabled"
                        >
                            {{ btnTitle }}
                        </button>
                        <router-link
                            v-show="!isBtnDisabled"
                            class="btn btn-sm btn-danger ml-1"
                            :to="{ name: 'FrontDashboard' }"
                            >Back</router-link
                        >
                    </div>
                    <div class="col-md-7">
                        <img
                            :src="'/img/credit-card-logo-clipart.png'"
                            class="float-right"
                            width="220"
                        />
                    </div>
                </div>
            </form>
        </div>

        <div :style="{'display': display_prop_saved_card}">
            <div v-if="user_cards.length < 1">
                You don't have any saved cards yet.

            </div>
            <div class="row mb-3" v-if="user_cards.length > 0">
                <div class="col-md-6">
                    <label class="required" for="user_cards"> Saved Cards</label>
                    <p v-if="!user_cards">There are no saved cards yet.</p>
                    <select
                        v-if="user_cards"
                        v-model="default_card"
                        class="form-control form-control-sm mb-1"
                        style=""
                        id="user_cards"
                    >
                        <option
                            v-for="card in user_cards"
                            v-bind:key="card.id"
                            :selected="card.is_default === 1"
                            :value="card.id"
                            >{{ card.card_type }} | ends with
                            {{ card.card_number }}
                        </option>
                    </select>

                    <button
                        v-if="user_cards"
                        class="btn btn-primary btn-sm mt-3"
                        @click.prevent="payWithExistingCard"
                        :disabled="isBtnDisabled"
                    >
                        {{ btnTitle }}
                    </button>

                </div>
            </div>
        </div>

        <div v-if="isBtnDisabled">
            <ProgressBar
                :value="value"
                mode="indeterminate"
                style="height:5px;"
            />
        </div>
    </div>
</template>
<script>
import axios from "axios";
import braintree from "braintree-web";
import ProgressBar from "primevue/progressbar";
import ProgressSpinner from "primevue/progressspinner";
export default {
    props: ["invoiceAmount"],
    components: { ProgressSpinner, ProgressBar },
    data() {
        return {
            hostedFieldInstance: false,
            mayPayment: true,
            nonce: "",
            error: "",
            clientToken: "",
            value: 100,
            loading: true,
            app_base_url: process.env.VUE_APP_BASE_URL,
            amount: null,
            userID: null,
            isSavedCard: false,
            isBtnDisabled: true,
            add_new_card: true,
            btnTitle: "Please wait...",
            display_prop_new_card: 'block',
            display_prop_saved_card: 'none',
            default_card: null,
            user_cards: [],
        };
    },

    created() {
        this.btnTitle = "Pay";
        this.userID = localStorage.getItem("logged_user_id");
        this.getClientToken();
        this.getUserCards();
    },

    methods: {
        toggleCardSection(section) {
            if (section == "new_card") {
                this.display_prop_new_card = 'block'
                this.display_prop_saved_card = 'none'
                this.add_new_card = true;
                this.btnTitle = "Pay";
                this.userID = localStorage.getItem("logged_user_id");
            } else if (section == "saved_card") {
                this.display_prop_new_card = 'none'
                this.display_prop_saved_card = 'block'
                this.add_new_card = false;
            }
        },

        getUserCards() {
            this.loading = true;
            let data = [];
            var headers = {
                Authorization:
                    `Bearer ` + localStorage.getItem("rezo_billing_front_user"),
            };
            axios
                .post(this.app_base_url + "/get/user_payment_methods", data, {
                    headers,
                })
                .then(
                    (response) => {
                        this.user_cards = response.data.cards;
                        var result = response.data.cards.find((obj) => {
                            return obj.is_default === 1;
                        });

                        if (result) {
                            this.default_card = result.id;
                            this.monthly_payment_date = result.monthly_payment_date;    
                        }
                        
                        this.loading = false;
                        if (!result) {
                            this.default_card = response.data.cards[0].id
                        }

                        this.loading = false;
                    },
                    (error) => {
                        console.log(error.response.data.error);
                        this.loading = false;
                    }
                );
        },

        getClientToken() {
            var headers = {
                Authorization:
                    `Bearer ` + localStorage.getItem("rezo_billing_front_user"),
            };
            axios.get(this.app_base_url + "/make_payment", { headers }).then(
                (response) => {
                    if (response.status === 200) {
                        this.clientToken = response.data.token;
                        this.brainTree(this.clientToken);
                        (this.loading = false),
                            this.$notify({
                                type: "info",
                                title: "Info",
                                text: "Please fill card details.",
                            });
                    }
                },
                (error) => {
                    console.log(error.response.data.error);
                    this.$notify({
                        type: "error",
                        title: "Something went wrong!",
                        text: error.response.data.error,
                    });
                }
            );
        },

        brainTree(token) {
            braintree.client
                .create({ authorization: token })
                .then((clientInstance) => {
                    let options = {
                        client: clientInstance,
                        styles: {
                            "input.invalid": {
                                color: "red",
                            },
                            "input.valid": {
                                color: "green",
                            },
                        },
                        fields: {
                            number: {
                                selector: "#creditCardNumber",
                                placeholder: "4111 1111 1111 1111",
                            },
                            cvv: {
                                selector: "#cvv",
                                placeholder: "•••",
                            },
                            expirationMonth: {
                                selector: "#expireMonth",
                                placeholder: "--Select--",
                                type: "month",
                                select: true,
                            },
                            expirationYear: {
                                selector: "#expireYear",
                                placeholder: "--Select--",
                                select: true,
                            },
                        },
                    };
                    return braintree.hostedFields.create(options);
                })
                .then((hostedFieldInstance) => {
                    // Use hostedFieldInstance to send data to Braintree
                    this.hostedFieldInstance = hostedFieldInstance;
                    this.isBtnDisabled = false;
                })
                .catch((err) => {
                    this.isBtnDisabled = false;
                    this.btnTitle = "Pay";
                    console.error(err);
                    this.error = err.message;
                    this.$notify({
                        type: "error",
                        title: "Something Went Wrong",
                        text: "Error!",
                    });
                });
        },

        payWithCreditCard() {
            this.isBtnDisabled = true;
            this.btnTitle = "Please wait...";

            if (this.hostedFieldInstance) {
                this.error = "";
                this.nonce = "";
                this.hostedFieldInstance
                    .tokenize()
                    .then((payload) => {
                        this.nonce = payload.nonce;
                        var headers = {
                            Authorization:
                                `Bearer ` +
                                localStorage.getItem("rezo_billing_front_user"),
                        };
                        var data = {
                            amount: this.invoiceAmount,
                            invoice_id: this.$route.params.id,
                            user_id: this.userID,
                            post_nonce: this.nonce,
                            is_save_credit_card: this.isSavedCard,
                        };

                        axios
                            .post(this.app_base_url + "/pay", data, { headers })
                            .then((response) => {
                                console.log(response);
                                this.isBtnDisabled = false;
                                this.btnTitle = "Pay";
                                this.mayPayment = true;
                                this.$router.push("/front/thank-you");
                            });
                    })
                    .catch((err) => {
                        this.isBtnDisabled = false;
                        this.btnTitle = "Pay";
                        console.error(err);
                        this.error = err.message;
                    });
            }
        },

        payWithExistingCard() {
            this.isBtnDisabled = true;
            this.btnTitle = "Please wait...";

            var headers = {
                Authorization:
                    `Bearer ` +
                    localStorage.getItem("rezo_billing_front_user"),
            };
            var data = {
                amount: this.invoiceAmount,
                invoice_id: this.$route.params.id,
                user_id: this.userID,
                post_nonce: null,
                is_save_credit_card: false,
                default_payment_method_id: this.default_card,
                existing_card: true,
            };
            console.log(data, headers);

            axios
                .post(this.app_base_url + "/pay", data, { headers })
                .then((response) => {
                    console.log(response);
                    this.isBtnDisabled = false;
                    this.btnTitle = "Pay";
                    this.mayPayment = true;
                    this.$router.push("/front/thank-you");
                });
    
        },
    },
};
</script>
<style lang="scss" scoped>
::v-deep(.p-progressbar) {
    .p-progressbar-value {
        border: 0 none;
        margin: 0;
        background: #cccccc;
    }
}
</style>
