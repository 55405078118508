<template>
  <div class="row justify-content-center" v-if="loading">
    <ProgressSpinner style="width:50px;height:50px" strokeWidth="1" fill="var(--surface-ground)" animationDuration=".5s" />
  </div>
  <div v-else class="p-2">
    <form @submit.prevent="formSubmit">
      <div class="form-group row pt-0">
        <div class="col-sm-12">
          <label for="card_number">Card Number</label>
          <InputMask v-model="card_number" mask="9999 9999 9999 9999" type="text" class="form-control form-control-sm"
            id="card_number" autocomplete="chrome-off" />
          <span class="text-danger" v-if="v$.card_number.$error">
            <small class="help-block" v-for="validationError in v$.card_number.$errors" :key="validationError">
              {{ validationError.$message }}
            </small>
          </span>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-sm-6">
          <label for="expiring_date">Expiration Date</label>
          <input type="month" v-model="expiring_date" id="expiring_date" class="form-control form-control-sm">
          <span class="text-danger" v-if="v$.expiring_date.$error">
            <small class="help-block" v-for="validationError in v$.expiring_date.$errors" :key="validationError">
              {{ validationError.$message }}
            </small>
          </span>
        </div>
        <div class="col-sm-4">
          <label for="cvv_number">CVV #</label>
          <input type="number" id="cvv_number" v-model="cvv_number" class="form-control form-control-sm">
          <span class="text-danger" v-if="v$.cvv_number.$error">
            <small class="help-block" v-for="validationError in v$.cvv_number.$errors" :key="validationError">
              {{ validationError.$message }}
            </small>
          </span>
        </div>
      </div>

      <button class="btn btn-success btn-sm" :disabled="btnDisabled">{{ btnTitle }} <i class="fa fa-money" aria-hidden="true"></i></button>
      <img :src="'/img/credit-card-logo-clipart.png'" class="float-right" width="240" />
    </form>
  </div>
</template>
<script>

import axios from "axios";
import useValidate from "@vuelidate/core";
import { required, minLength, maxLength, helpers } from "@vuelidate/validators";
import InputMask from 'primevue/inputmask';

export default {
  components: {
    InputMask
  },

  data() {
    return {
      v$: useValidate(),
      app_base_url: process.env.VUE_APP_BASE_URL,

      user_id: null,
      card_number: null,
      expiring_date: null,
      cvv_number: null,
      isDisabled: false,
      btnTitle: "Save Info"
    };
  },

  validations() {
    return {
      card_number: {
        required: helpers.withMessage('Credit card number is required.', required),
      },

      expiring_date: {
        required: helpers.withMessage('Expirate date is required.', required),
      },

      cvv_number: {
        required: helpers.withMessage('CVV # is required.', required),
        minLength: minLength(3),
        maxLength: maxLength(5)
      },
    };
  },

  created() {
    this.user_id = this.userID = localStorage.getItem("logged_user_id");
  },

  methods: {

    hideDialog() {
      this.$emit('Display', false)
    },

    formSubmit() {
      this.v$.$validate();
      if (this.v$.$error) {
        console.log("errors", this.v$.$error);
        if (this.v$.amount.$error) {
          this.v$.amount.$errors.forEach(validationError => {
            this.$toast.add({
              severity: 'error',
              summary: 'Amount',
              detail: validationError.$message,
              life: 2000
            });
          });
        }
        if (this.v$.card_number.$error) {
          this.v$.card_number.$errors.forEach(validationError => {
            this.$toast.add({
              severity: 'error',
              summary: 'Card Number',
              detail: validationError.$message,
              life: 2000
            });
          });
        }
        if (this.v$.expiring_date.$error) {
          this.v$.expiring_date.$errors.forEach(validationError => {
            this.$toast.add({
              severity: 'error',
              summary: 'Expiration date',
              detail: validationError.$message,
              life: 2000
            });
          });
        }
        if (this.v$.cvv_number.$error) {
          this.v$.cvv_number.$errors.forEach(validationError => {
            this.$toast.add({
              severity: 'error',
              summary: 'CVV Number',
              detail: validationError.$message,
              life: 2000
            });
          });
        }
      } else {

        this.isDisabled = true;
        this.btnTitle = "Please wait...";

        var data = {
          user_id: this.user_id,
          card_number: this.card_number,
          expiring_date: this.expiring_date,
          cvv_number: this.cvv_number,
        };

        var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_billing_front_user") };
        axios.post(this.app_base_url + "/store/user_payment_information", data, { headers }).then(
          (response) => {
            if (response.status === 200) {
              this.btnTitle = "Save Info";
              this.isDisabled = false;
              this.hideDialog();
            }
          },
          (error) => {
            this.isDisabled = false;
            this.btnTitle = "Save Info"
            console.log(error.response.data.error);
            this.$notify({
              type: "error",
              title: "Something went wrong!",
              text: error.response.data.error,
            });
          }
        );
      }
    }
  }
}
</script>