<template>
  <!-- Page Wrapper -->
  <div id="wrapper">
    <!-- Content Wrapper -->
    <div id="content-wrapper" class="d-flex flex-column">
      <!-- Main Content -->
      <div id="content" style="#898989">
        <!-- Topbar -->
        <nav class="navbar navbar-expand-lg navbar-secondary" style="height: 140px; background: black;">
          <img :src="'/img/logo.png'" class="float-left" width="100"/>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>

          <div class="content_css collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto ml-5">
              <li class="nav-item active">
                <h3 class="mt-3 ml-5 text-light text-center">Rezosystems Billing System</h3>
              </li>
              <!-- <li class="nav-item active">
                <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                  Dropdown
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <a class="dropdown-item" href="#">Action</a>
                  <a class="dropdown-item" href="#">Another action</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item" href="#">Something else here</a>
                </div>
              </li> -->
            </ul>
            <div v-if="this.$route.fullPath != '/update/password'">
              <Button v-if="this.$route.fullPath != '/forgot/password' " @click="logout()" type="button" class="btn btn-outline-danger pull-right my-2 my-sm-0" label="Confirm">
                Logout <i class="ml-2 pi pi-sign-out"></i>
              </Button>
            </div>
          </div>
        </nav>

        <div class="container-fuild  full-height" style="height: 400px;">
          <router-view :key="$route.fullPath" />
        </div>
      </div>

      <footer class="sticky-footer" 
        style="
        background: black; 
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        text-align: center;"
      >
        <div class="container my-auto">
          <div class="copyright text-white text-center my-auto">
            <span>Copyright &copy; {{ moment(new Date()).format('YYYY') }} Rezo Billing. All rights reserved.</span>
          </div>
        </div>
      </footer>
    </div>
  </div>
  <ConfirmDialog></ConfirmDialog>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import ConfirmDialog from 'primevue/confirmdialog';
import 'primeicons/primeicons.css';

export default {
  computed: mapState(["user"]),
  
  components: {ConfirmDialog},

  data() {
    return {
      moment: moment
    };
  },

  methods: {

    logout: function ()
    {
      this.$confirm.require({
        message: 'Are you sure you want to logout?',
        header: 'Logout',
        acceptClass: 'p-button-danger',
        rejectClass: 'p-button-secondary',
        acceptIcon: 'pi pi-sign-out',
        acceptLabel: 'Logout',
        rejectIcon: 'pi pi-home',
        rejectLabel: 'Cancel',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          // localStorage.removeItem("rezo_billing_front_user");
          // localStorage.removeItem("user_role");
          localStorage.clear();
          this.$router.push({ name: 'Dashboard' })
        },
        reject: () => {
          //
        }
      });
    }
  }
};
</script>
<style>
  .content_css {
    min-height: calc(100vh - 70px);
  }
  .footer_css {
    height: 50px;
  }
</style>