<template>
  <h1 class="h4 text-primary">
    {{ title }}
    {{module_title}}
  </h1>
</template>

<script>
export default {
  props: ["module_title","title"],
};
</script>

<style>
</style>