<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card mb-3">
        <div class="card-body">
          <div class="card-body">
            <div class="row">
              <div class="col-md-8">
                <div class="row justify-content-center" v-if="loading">
                  <ProgressSpinner 
                    style="width:50px;height:50px" 
                    strokeWidth="1" 
                    fill="var(--surface-ground)"
                    animationDuration=".5s"
                  />
                </div>
                <div v-else>
                  <DataTable :value="CustomerProducts.data" style="padding:4px;">
                    <template  #empty>
                        <div class="text-danger text-center">No product found.</div>
                    </template>
                    <Column style="padding:3px; font-size: 15px;" field="name" header="Name" :sortable="true"></Column>
                    <!-- <Column style="padding:3px; font-size: 15px;" field="description" header="Description" :sortable="true"></Column> -->
                    <Column style="padding:3px; font-size: 15px;" field="startup_fee" header="Startup Fee" :sortable="false">
                      <template #body="slotdata">
                        <div v-if="slotdata.data.startup_fee != null">
                          ${{ USDCurrancyformat(slotdata.data.startup_fee) }}
                        </div>
                        <div v-else>
                          <small>N/A</small>
                        </div>
                      </template>
                    </Column>
                    <Column style="padding:3px; font-size: 15px;" field="monthly_fee" header="Monthly Fee" :sortable="false">
                      <template #body="slotdata">
                        <div v-if="slotdata.data.monthly_fee != null">
                          ${{ USDCurrancyformat(slotdata.data.monthly_fee) }}
                        </div>
                        <div v-else>
                          <small>N/A</small>
                        </div>
                      </template>
                    </Column>

                    <Column style="padding:4px;" field="created_at" header="Created At" :sortable="true">
                      <template #body="slotDate">
                        {{ moment(slotDate.data.created_at).format("MM/DD/YYYY, h:mm:ss a") }}
                      </template>
                    </Column>
                    
                    <Column style="padding:3px; font-size: 15px;" header="Actions">
                      <template #body="slotProps">
                        <button :disabled="editBtnDisabled" v-on:click="editProduct(slotProps.data.id)" class="btn btn-circle btn-primary btn-sm ml-1">
                          <i v-if="!editBtnDisabled" class="fas fa-edit"></i>
                          <i v-if="editBtnDisabled" class="pi pi-spin pi-spinner" style="font-size: 1rem"></i>
                        </button>
                        <button v-on:click="deleteProduct(slotProps.data.id)" class="btn btn-circle btn-danger btn-sm ml-1"><i
                            class="fas fa-trash"></i></button>
                      </template>
                    </Column>
                  </DataTable>
                  <div v-if="CustomerProducts.data.length > 0" class="pagination d-flex justify-content-center mt-1">
                    <button class="btn btn-outline-secondary pt-0 pb-0 mr-1" @click="getCustomerProducts(CustomerProducts.first_page_url)" :disabled="pagination.current_page == 1" style="font-size: 14px; height: 30px;"> First Page </button>
                    <button class="btn btn-outline-secondary pt-0 pb-0 mr-2" @click="getCustomerProducts(pagination.prev_page_url)" :disabled="pagination.current_page == 1" style="font-size: 14px; height: 30px;"> Previous </button>
                    <label>Page {{ pagination.current_page }} of {{ pagination.last_page }}</label>
                    <button class="btn btn-outline-secondary pt-0 pb-0 ml-2" @click="getCustomerProducts(pagination.next_page_url)" :disabled="pagination.current_page == pagination.last_page" style="font-size: 14px; height: 30px;">Next </button>
                    <button class="btn btn-outline-secondary pt-0 pb-0 ml-1" @click="getCustomerProducts(CustomerProducts.last_page_url)" :disabled="pagination.current_page == pagination.last_page" style="font-size: 14px; height: 30px;"> Last Page </button>

                    <div class="ml-1">
                      <select v-model="searchFilters.per_page" class="form-control" style="border: 1px solid #858796; height: 30px; padding-top: 2px;">
                        <option selected :value="3">3</option>
                        <option selected :value="5">5</option>
                        <option selected :value="10">10</option>
                        <option selected :value="20">20</option>
                        <option selected :value="50">50</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <form @submit.prevent="formSubmit">
                  <div class="">
                    <small class="required" for="product">Name</small>
                    <select v-model="Name" class="form-control form-control-sm" id="product">
                      <option :value="null">--Select--</option>
                      <option value="GMS">GMS</option>
                      <option value="Rental">Rental</option>
                      <option value="Ski & Snowboards">Ski & Snowboards</option>
                      <option value="Rafting">Rafting</option>
                      <option value="Others ">Others</option>
                      <option value="Ski tune">Ski tune</option>
                    </select>
                  </div>
                  <div class="">
                    <small>Description</small>
                    <textarea autocomplete="chrome-off" v-model="Description"
                      class="form-control form-control-sm" name="txtname" rows="4" cols="50" maxlength="200">
                    </textarea>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="">
                        <small>Startup Fee</small><br>
                        <InputNumber 
                          inputClass="form-control form-control-sm" 
                          inputStyle="height: 31px; font-size: 13px;"
                          v-model="startupFee" 
                          mode="currency" 
                          currency="USD" 
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="">
                        <small class="required">Monthly Fee</small><br>
                        <InputNumber 
                          :inputClass="this.v$.monthlyFee.$error ? 'form-control form-control-sm is-invalid':'form-control form-control-sm'"
                          inputStyle="height: 31px; font-size: 13px;" 
                          v-model="monthlyFee" 
                          mode="currency" 
                          currency="USD" 
                        />
                      </div>
                    </div>
                  </div>
                  <button class="btn btn-sm btn-block mt-2 btn-success" :disabled="btnDisabled">{{btnTitle}} <i v-if="btnDisabled" class="pi pi-spin pi-spinner" style="font-size: 1rem"></i></button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Toast />
  <ConfirmDialog></ConfirmDialog>
</template>
<script>

import axios from "axios";
import useValidate from "@vuelidate/core";
import { maxLength,required, helpers } from "@vuelidate/validators";
import Toast from 'primevue/toast';
import InputNumber from 'primevue/inputnumber';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import moment from "moment";
import ConfirmDialog from 'primevue/confirmdialog';
import ProgressSpinner from 'primevue/progressspinner';
import 'primeicons/primeicons.css';

// import { mapState } from "vuex";
export default {
  // computed: mapState(["user"]),
  components: {
    Toast, 
    InputNumber,
    DataTable,
    Column,
    ConfirmDialog,
    ProgressSpinner
  },
  data() {
    return {
      app_base_url: process.env.VUE_APP_BASE_URL,
      v$: useValidate(),
      btnTitle: "Create",
      btnDisabled: false,
      editBtnDisabled: false,
      CustomerProducts: [],
      pagination: {},
      loading: true,
      moment: moment,
      searchFilters: {
        per_page:5
      },

      //Fields
      productID: null,
      userID: null,
      Name: null,
      Description: null,
      startupFee: null,
      monthlyFee: null
    };
  },

  watch: {
    searchFilters: {
      deep: true,
      handler() {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.getCustomerProducts(this.app_base_url + '/customers/' + this.userID)
            this.loading = true;
            this.awaitingSearch = false;
          }, 700);
        }
        this.awaitingSearch = true;
      }
    },
  },

  validations() {
    return {
      Name: {
        maxLength: maxLength(20),
        required: helpers.withMessage('Product name is required.', required),
      },
      monthlyFee: {
        required: helpers.withMessage('Product monthly fee is required.', required),
      }
    };
  },
  
  created() {
    this.userID = this.$route.params.id;
    this.getCustomerProducts();
  },
  methods: {
    
    USDCurrancyformat(value) {
      let val = (value/1).toFixed(2).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },

    formSubmit() {
      this.v$.$validate();
      if (this.v$.$error) {
        console.log("errors", this.v$.$error);
        if (this.v$.Name.$error) { 
          this.v$.Name.$errors.forEach(validationError => {
            this.$toast.add({ 
              severity: 'error', 
              summary: 'Required',
              detail: validationError.$message, 
              life: 2000 
            });
          });
        }
        if (this.v$.monthlyFee.$error) { 
          this.v$.monthlyFee.$errors.forEach(validationError => {
            this.$toast.add({ 
              severity: 'error', 
              summary: 'Required',
              detail: validationError.$message, 
              life: 2000 
            });
          });
        }
      } else {

        this.btnDisabled = true;
        this.btnTitle = "Please wait...";

        var data = {
          id: this.productID,
          user_id: this.userID,
          name: this.Name,
          description: this.Description,
          startup_fee: this.startupFee,
          monthly_fee: this.monthlyFee,
        };

        var headers = {
          Authorization:
            `Bearer ` + localStorage.getItem("rezo_billing_user"),
        };
        axios
        .post(this.app_base_url + "/customer_products", data, { headers })
        .then((response) => {
          if (response.status === 200) {
            this.getCustomerProducts();
            this.btnDisabled = false;
            this.btnTitle = "Create";
            this.Name =  null,
            this.Description = null,
            this.startupFee = null,
            this.monthlyFee = null,
            this.productID = null,
            this.v$. $reset();

            this.$notify({
              type: "success",
              title: "Success",
              text: "Save Successfully!",
            });
          }
        }).catch((error) => {
          this.btnDisabled = false
          this.btnTitle = 'Create';
          this.$notify({
            type: "error",
            title: "Something Went Wrong",
            text: "Error!",
          });
          console.warn("Your Error is :", error);
        });
      }
    },

    getCustomerProducts: function (page_url) {
      this.loading = true;
      let vm = this;
      page_url = page_url || this.app_base_url + '/customers/' + this.userID
      var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_billing_user") };
      axios.get(page_url, { headers, params: (this.searchFilters) })
      .then((response) => {
        this.loading = false;
        this.CustomerProducts = response.data.CustomerProducts;
        vm.makePagination(response.data)
        this.loading = false;
      });
    },

    makePagination: function (data) {

      let pagination = {
        current_page: data.CustomerProducts.current_page,
        last_page: data.CustomerProducts.last_page,
        next_page_url: this.app_base_url + '/customers/' + this.userID + '?page=' + (data.CustomerProducts.current_page + 1),
        prev_page_url: this.app_base_url + '/customers/' + this.userID + '?page=' + (data.CustomerProducts.current_page - 1),
      }
      
      this.pagination = pagination;
    },

    editProduct: function (id) {
      this.editBtnDisabled = true;
      var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_billing_user") };
      axios.get(this.app_base_url + '/customer_products/' + id, { headers })
      .then((response) => {
        this.editBtnDisabled = false;
        this.btnTitle = "Update";
        console.info("Show Customer Product: ", response.data.CustomerProduct);
        this.productID = response.data.CustomerProduct.id;
        this.Name = response.data.CustomerProduct.name;
        this.Description = response.data.CustomerProduct.description;
        this.startupFee = response.data.CustomerProduct.startup_fee;
        this.monthlyFee = response.data.CustomerProduct.monthly_fee;
      }).catch((error) => {
        console.warn('Your Error is :', error);
        this.$notify({
          type: "error",
          title: "Something Went Wrong",
          text: "Error!",
        });
      })
    },

    deleteProduct: function (id) {
      var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_billing_user") };
      this.$confirm.require({
        message: 'Are you sure you want to proceed?',
        header: 'Delete',
        acceptClass: 'p-button-success',
        rejectClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          axios
          .delete(this.app_base_url + "/customer_products/" + id, {
            headers: headers,
          })
          .then((response) => {
            this.getCustomerProducts();
            this.$toast.add({ severity: 'success', summary: 'Deleted', detail: 'Record deleted successfully!', life: 2000 });
            console.info("Customer Product deleted: ", response);
          })
          .catch((error) => {
            console.warn("Your Error is :", error);
            this.$notify({
              type: "error",
              title: "Something Went Wrong",
              text: "Error!",
            });
          });
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: 'Record Safe', detail: 'Your record is safe!', life: 2000 });
        }
      });
    }
  }
};
</script>
