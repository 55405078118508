<template>
  <div class="row justify-content-center" v-if="loading">
    <ProgressSpinner 
      style="width:50px;height:50px; padding-top: 320px;" 
      strokeWidth="1" 
      fill="var(--surface-ground)"
      animationDuration=".5s"
    />
  </div>
  <div v-else>
    <form @submit.prevent="formSubmit" class="user">
      <!-- Dropdown -->
      <div class="form-group row">
        <div class="col-md-8">
          <label class="required" for="exampleSiteName"> Driver</label>
          <div class="">
            <select v-model="mailDriver" class="form-control form-control-sm" >
              <option selected :value="undefined">--Select Mail Driver--</option>
              <option value="smtp">SMTP</option>
              <option value="mailgun">Mailgun (API)</option>
              <option value="log_developer">Log Developer SendMailing</option>
            </select>
          </div>
        </div>
        <!-- Mailgun Button -->
        <div class="col-md-4" v-if="mailDriver === 'mailgun'">
          <br>
          <button type="button" v-on:click="mailgun_Dialog()" class="btn mt-2 btn-success btn-sm">Edit Mailgun Credentials</button>
          <Dialog header="View Mailgun Details" v-model:visible="display" :style="{width: '30vw'}" >
            <conformPopUp @hideDialog="hideConfig(event)" />
          </Dialog>
        </div>
      </div>

      <!-- Mailgun Well -->
      <div class="form-group pt-0" v-if="mailDriver === 'mailgun' || mailDriver === 'log_developer'">
        <div>
          <div class="customerCard col-md-8" v-show="WellDisplay">
            <button class="btn btn-light btn-sm disabled" type="button">{{configDomain_value}}</button>
            <p style="-webkit-text-security': disc" > {{configKey_value}}</p>
          </div>
        </div>
      </div>

      <!-- SMTP Fields -->
      <div class="form-group row" v-if="mailDriver === 'smtp'">
        <div class="col-md-6 mb-sm-0">
          <label for="exampleHost"> Host</label>
          <input
            v-model="smtpHost"
            type="text"
            class="form-control form-control-sm"
            id="exampleHost"
          />
        </div>
        <div class="col-md-6  mb-sm-0">
          <label for="examplePort"> Port</label>
          <input
            v-model="smtpPort"
            type="text"
            class="form-control form-control-sm"
            id="examplePort"
          />
        </div>
        <div class="col-md-6 mt-3 mb-sm-0">
          <label for="exampleUserName"> Username</label>
          <input
            v-model="smtpUserName"
            type="text"
            class="form-control form-control-sm"
            id="exampleUserName"
          />
        </div>
          <div class="col-sm-6 mt-3">
        <label for=""> Password</label>
        <input v-model="smtpPassword" type="password" id="examplepassword" class="form-control form-control-sm"/>
      </div>
    </div>
    <!-- Mail Fields -->
    <div class="form-group row">
      <div class="col-md-6 mb-sm-0">
        <label class="required" for="examplefromEmail"> From (email)</label>
        <input
          v-model="fromEmail"
          type="email"
          class="form-control form-control-sm"
          :class="{ 'is-invalid': v$.fromEmail.$error }"
          id="examplefromEmail"
        />
        <span class="text-danger" v-if="v$.fromEmail.$error">
          <span
            class="help-block"
            v-for="validationError in v$.fromEmail.$errors"
            :key="validationError"
          >
            {{ validationError.$message }}
          </span>
        </span>
      </div>
        <div class="col-md-6 mb-sm-0">
          <label class="required" for="examplefromName"> From (name)</label>
          <input
            v-model="fromName"
            type="text"
            class="form-control form-control-sm"
            :class="{ 'is-invalid': v$.fromName.$error }"
            id="examplefromName"
          />
          <span class="text-danger" v-if="v$.fromName.$error">
            <span
              class="help-block"
              v-for="validationError in v$.fromName.$errors"
              :key="validationError"
            >
              {{ validationError.$message }}
            </span>
          </span>
        </div>
      </div>
      
      <button class="btn btn-primary" :disabled="btnDisabled">{{btnTitle}}</button>
    </form>
  </div>
</template>

<script>
  import useValidate from "@vuelidate/core";
  import { required, minLength, maxLength, email } from "@vuelidate/validators";
  import axios from "axios";
  import Dialog from 'primevue/dialog';
  import ProgressSpinner from 'primevue/progressspinner';
  import conformPopUp from "../partials/conformPopUp.vue";
  export default {

    components: {
      conformPopUp,
      Dialog,
      ProgressSpinner
    },

    data() {
      return {
        v$: useValidate(),
        display: false,
        mailDriver: null,
        configDomain: null,
        configKey: null,
        configDomain_value: 'N/A',
        configKey_value: 'N/A',
        show_configKey: null,
        WellDisplay: false,
        smtpHost: null,
        smtpPort: null,
        smtpUserName: null,
        smtpPassword: null,
        fromName: null,
        fromEmail: null,
        loading: true,
        isDisabled: false,
        btnTitle: "Update Settings",
        base_url: process.env.VUE_APP_BASE_URL,

      };
    },

    validations() {
      return {
        fromName: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(50),
        },
        fromEmail: {
          required,
          email,
          minLength: minLength(3),
          maxLength: maxLength(50),
        },
      };
    },

    created() {
      this.getSettings();
    },

    methods: {
      mailgun_Dialog() {
        this.display = true;
      },

      hideConfig(val) {
        this.getSettings();
        console.log(val);
        this.display = false;
      },

      getSettings() {
        var mailId = 3;
        var headers = {
          Authorization: `Bearer ` + localStorage.getItem("rezo_billing_user"),
        };
        
        axios
        .get(this.base_url + "/settings/" + mailId, { headers })
        .then((response) => {

          this.loading = false;

          this.mailDriver = response.data.mail_driver,
          this.configDomain_value = response.data.config_domain == null ? 'N/A': response.data.config_domain,
          this.show_configKey = response.data.config_key, 
          response.data.config_key == null ? this.WellDisplay = false: this.WellDisplay = true, //Condition also for CSS
          this.smtpHost= response.data.smtp_host,
          this.smtpPort= response.data.smtp_port,
          this.smtpUserName= response.data.smtp_user_name,
          this.smtpPassword= response.data.smtp_password,
          this.fromName = response.data.from_name,
          this.fromEmail = response.data.from_email

          let total_x = ''
          let end = response.data.config_key.length - 4;
          let last_4 = response.data.config_key.slice(-4);
          for (let index = 1; index <= end; index++) {
            total_x = total_x + 'x';
          }

          this.configKey_value = total_x + last_4;
        })
        .catch((error) => {
          console.error("Your Error is :", error);
        });
      },

      formSubmit() {
        this.v$.$validate();
        if (this.v$.$error) {
          console.log("errors");
          //event.preventDefault();
        } else {
          this.isDisabled = true;
          this.btnTitle = "Please wait...";

          var data = {
            setting_id: 3,
            mail_driver: this.mailDriver,
            smtp_host: this.smtpHost,
            smtp_port: this.smtpPort,
            smtp_user_name: this.smtpUserName,
            smtp_password: this.smtpPassword,
            from_name: this.fromName,
            from_email: this.fromEmail
          };
          var headers = {
            Authorization:
              `Bearer ` + localStorage.getItem("rezo_billing_user"),
          };

          axios.post(this.base_url + "/settings", data, { headers }).then(
            (response) => {
              if (response.status === 200) {
                
                this.getSettings();

                this.btnTitle = "Update Settings";
                this.isDisabled = false;
                this.$notify({
                  type: "success",
                  title: "Success",
                  text: "Saved Successfully!",
                });
                console.log("Form submitted successfully");
                this.$router.push({ path: "/settings/mail" });
              }
              console.log(response);
            },
            (error) => {
              this.isDisabled = false;
              this.btnTitle = "Update Settings";
              console.log(error.response.data.error);
              this.$notify({
                type: "error",
                title: "Something went wrong!",
                text: error.response.data.error,
              });
            }
          );
        }
      },
    },
  }
</script>

<style scoped>
  .customerCard {
    background: #e7edff;
    border: 1px;
    border-color: #d1d3e2;
    border-style: solid;
    border-radius: 5px;
    margin-right: -3px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
</style>