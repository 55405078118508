<template>
  <div class="row justify-content-center" v-if="loading">
    <ProgressSpinner style="width:50px;height:127px" strokeWidth="1" fill="var(--surface-ground)"
      animationDuration=".5s" />
  </div>
  <div v-else>
    <div v-if="!isValid">
      <form @submit.prevent="formSubmit" class="user">
        <div class="form-group row">
          <div class="col-md-12">
            <label class="required">Please Enter Password</label>
            <div class="">
              <input type="text" autocomplete="chrome-off" v-model="matchPassword"
                :class="isMatch ? 'form-control form-control-sm is-invalid' : 'form-control form-control-sm is-valid'" />

              <span class="text-danger " v-if="v$.matchPassword.$error">
                <small class="help-block mt-1" v-for="validationError in v$.matchPassword.$errors"
                  :key="validationError">
                  {{ validationError.$message }}
                </small>
              </span>
            </div>
          </div>
        </div>
        <button :class="isMatch ? 'btn btn-sm btn-danger' : 'btn btn-sm btn-success'">Show Credentials <i
            class="fa fa-eye" aria-hidden="true"></i></button>
      </form>
    </div>
    <div v-else>
      <mailgunFields @Display="hideConfigDialog($event)" />
    </div>
  </div>
  <Toast />
</template>
<script>

import useValidate from "@vuelidate/core";
import ProgressSpinner from 'primevue/progressspinner';
import Toast from 'primevue/toast';
import {
  required,
  helpers,
} from "@vuelidate/validators";
import axios from "axios";
import mailgunFields from "../partials/mailgunFields.vue";
export default {

  components: {
    ProgressSpinner,
    mailgunFields,
    Toast
  },

  data() {
    return {
      matchPassword: null,
      v$: useValidate(),
      loading: false,
      isMatch: false,
      isDisabled: false,
      app_base_url: process.env.VUE_APP_BASE_URL,
    };
  },

  validations() {
    return {
      matchPassword: {
        required: helpers.withMessage('Please enter password.', required),
      },
    };
  },

  methods: {
    hideConfigDialog(val) {
      this.$emit('hideDialog', val)
    },

    formSubmit() {
      this.v$.$validate();
      if (this.v$.$error) {
        console.log("errors");
      } else {

        this.loading = true;

        var data = {
          match_password: this.matchPassword
        };

        var headers = {
          Authorization:
            `Bearer ` + localStorage.getItem("rezo_billing_user"),
        };

        axios.post(this.app_base_url + "/mailgun/confirm_password", data, { headers }).then(
          (response) => {
            if (response.status === 200) {
              this.loading = false;

              if (response.data.isvalidUser == false) {
                this.isMatch = true;
                this.$toast.add({ severity: 'error', summary: 'Wrong Password', detail: 'The given password is incorrect.', life: 2000 });
              }
              else {
                this.isValid = response.data.isvalidUser
              }
            }
            console.log(response);
          },
          (error) => {
            console.log(error.response.data.error);
            this.$notify({
              type: "error",
              title: "Something went wrong!",
              text: error.response.data.error,
            });
          }
        );
      }
    },
  },
}
</script>