<template>
    <div>
        <div class="row justify-content-center" v-if="loading">
            <ProgressSpinner style="width:50px;height:50px" strokeWidth="1" fill="var(--surface-ground)"
                animationDuration=".5s" />
        </div>
        <div v-else>
            <div class="row">
                <div class="col-md-12">
                    <table class="table">
                        <thead>
                            <tr style="font-size: 15px;">
                                <th class="p-1" scope="col">Date</th>
                                <th class="p-1" scope="col">Description</th>
                                <th class="p-1" scope="col">Amount</th>
                                <th class="p-1" scope="col">Pay/View</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="invoice in invoices.data" :key="invoice" class="spaceUnder"
                                style="font-size: 15px;">
                                <td class="p-1">{{ moment(invoice.date).format("MM/DD/YYYY") }}</td>
                                <td class="p-1">{{ invoice.notes }}</td>
                                <td class="p-1 text-danger">${{ USDCurrancyformat(invoice.invoice_amount) }}</td>
                                <td class="p-1">
                                    <router-link class="btn btn-sm btn-primary mr-1"
                                        :to="{ name: 'PayInvoice', params: { id: invoice.id } }"><small>Pay</small>
                                    </router-link>
                                    <Button v-if="invoice.document_file != null" class="btn btn-sm btn-secondary"
                                        @click="downloadFile(invoice.id)"><small>View</small></Button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="text-center pt-0">
                        <small class="text-danger" v-if="invoices.data.length < 1">No invoice found.</small>
                    </div>
                    <div v-if="invoices.data.length > 0" class="pagination d-flex justify-content-center mt-1">
                        <button class="btn btn-outline-secondary pt-0 pb-0 mr-1"
                            @click="getCustomer(invoices.first_page_url)"
                            :disabled="pagination.current_page == 1" style="font-size: 14px; height: 30px;"> First Page
                        </button>
                        <button class="btn btn-outline-secondary pt-0 pb-0 mr-2"
                            @click="getCustomer(pagination.prev_page_url)"
                            :disabled="pagination.current_page == 1" style="font-size: 14px; height: 30px;"> Previous
                        </button>
                        <label>Page {{ pagination.current_page }} of {{ pagination.last_page }}</label>
                        <button class="btn btn-outline-secondary pt-0 pb-0 ml-2"
                            @click="getCustomer(pagination.next_page_url)"
                            :disabled="pagination.current_page == pagination.last_page"
                            style="font-size: 14px; height: 30px;">Next </button>
                        <button class="btn btn-outline-secondary pt-0 pb-0 ml-1"
                            @click="getCustomer(invoices.last_page_url)"
                            :disabled="pagination.current_page == pagination.last_page"
                            style="font-size: 14px; height: 30px;"> Last Page </button>

                        <div class="ml-1">
                            <select v-model="searchFilters.per_page" class="form-control"
                                style="border: 1px solid #858796; height: 30px; padding-top: 2px;">
                                <option selected :value="3">3</option>
                                <option selected :value="5">5</option>
                                <option selected :value="10">10</option>
                                <option selected :value="20">20</option>
                                <option selected :value="50">50</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import ProgressSpinner from 'primevue/progressspinner';
export default {
    components: {
        ProgressSpinner,
    },
    data() {
        return {
            app_base_url: process.env.VUE_APP_BASE_URL,
            moment: moment,
            loading: true,

            //Fields
            userID: null,
            invoices: [],
            pagination: {},
            searchFilters: {
                per_page: 5
            },
        };
    },
    watch: {
        searchFilters: {
            deep: true,
            handler() {
                if (!this.awaitingSearch) {
                    setTimeout(() => {
                        this.getCustomer(this.app_base_url + '/customers/' + this.userID)
                        this.loading = true;
                        this.awaitingSearch = false;
                    }, 700);
                }
                this.awaitingSearch = true;
            }
        },
    },
    created() {
        this.userID = localStorage.getItem("logged_user_id");
        this.getCustomer();
    },
    methods: {
        USDCurrancyformat(value) {
            let val = (value / 1).toFixed(2).replace(',', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },

        getCustomer: function (page_url) {
            this.loading = true;
            let vm = this;
            page_url = page_url || this.app_base_url + '/customers/' + this.userID
            var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_billing_front_user") };
            axios.get(page_url, { headers, params: (this.searchFilters) })
            .then((response) => {
                this.loading = false;
                this.invoices = response.data.CustomerUnpaidInvoices;
                vm.makePagination(response.data)
                this.loading = false;
            });
        },

        makePagination: function (data) {

            let pagination = {
                current_page: data.CustomerUnpaidInvoices.current_page,
                last_page: data.CustomerUnpaidInvoices.last_page,
                next_page_url: this.app_base_url + '/customers/' + this.userID + '?page=' + (data.CustomerUnpaidInvoices.current_page + 1),
                prev_page_url: this.app_base_url + '/customers/' + this.userID + '?page=' + (data.CustomerUnpaidInvoices.current_page - 1),
            }

            this.pagination = pagination;
        },


        downloadFile(invoiceId) {
            var data = {
                user_id: this.userID,
                id: invoiceId
            };

            var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_billing_front_user") };
            axios
                .post(this.app_base_url + "/invoice_file/download", data, { headers, responseType: 'blob' })
                .then((response) => {
                    if (response.status === 200) {
                        // console.log(response);
                        const file = new Blob(
                            [response.data],
                            { type: 'application/pdf' });
                        //Build a URL from the file
                        const fileURL = URL.createObjectURL(file);
                        //Open the URL on new Window
                        window.open(fileURL);
                    }
                }).catch((error) => {
                    this.$notify({
                        type: "error",
                        title: "Something Went Wrong",
                        text: "Error!",
                    });
                    console.warn("Your Error is :", error);
                });
        },
    },
};
</script>