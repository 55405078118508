<template>
  <!-- DataTales Example -->
  <div class="card shadow mr-5">
    <div class="card-header" style="padding:0px;">
      <div class="row mt-3">
        <div class="col-md-6">
          <table-header :title="'Admins'" :hasLink="true" :linkTo="'/admins/create'" :linkText="'New Admin'" />
        </div>
        <!-- <div class="col-md-6">
          <form class="form-inline float-right mr-3">
            <div class="form-group">
              <input v-model="searchFilters.name" class="form-control form-control-sm" placeholder="Name"
                ref="search" />
            </div>

            <div class="form-group ml-1">
              <button type="button" class="btn btn-danger btn-sm" @click="resetSearch()">Reset</button>
            </div>
          </form>
        </div> -->
      </div>
    </div>
    
    <ProgressSpinner v-if="loading" style="width:50px;height:50px" strokeWidth="1" fill="var(--surface-ground)"
      animationDuration=".5s" />
    <div class="card-body" v-else>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Role</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="admin in admins" :key="admin">
              <td>{{admin.first_name}} {{admin.last_name}}</td>
              <td>{{admin.email}}</td>
              <td>{{admin.role}}</td>
              <td>
                <router-link :to="{ path: '/admins/'+ admin.id + '/edit'}"><i class="fas fa-edit btn btn-circle btn-primary btn-sm"></i></router-link>
                <button v-on:click="deleteAdmin(admin.id)" class="btn btn-circle btn-danger btn-sm ml-1"><i class="fas fa-trash"></i></button>                  
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <Toast />
    <ConfirmDialog></ConfirmDialog>
  </div>
</template>
<script>
  import TableHeader from "../SharedComponents/TableHeader.vue";
  import axios from "axios";
  import moment from "moment";
  import ConfirmDialog from 'primevue/confirmdialog';
  import Toast from 'primevue/toast';
  import ProgressSpinner from 'primevue/progressspinner';
  export default {
    components: {
      TableHeader,
      ProgressSpinner,
      ConfirmDialog,
      Toast
    },

    data() {
      return {
        searchFilters: {},
        loading: true,
        loadRows: 20,
        admins: [],
        moment: moment,
        app_base_url: process.env.VUE_APP_BASE_URL,
      };
    },
    watch: {
      searchFilters: {
        deep: true,
        handler() {
          if (!this.awaitingSearch) {
            setTimeout(() => {
              this.getAdmins()
              this.loading = true;
              this.awaitingSearch = false;
            }, 700);
          }
          this.awaitingSearch = true;
        }
      },
    },
    
    // mounted() {
    //   this.$refs.search.focus();
    // },

    created() {
      this.getAdmins();
    },

    methods: {
      getAdmins: function () {
        var headers = {
          Authorization: `Bearer ` + localStorage.getItem("rezo_billing_user"),
        };
        axios
        .get(this.app_base_url + '/admins', { 
          headers,  
          params: this.searchFilters 
        })
        .then((response) => {
          console.info('admins List', response.data.admins)
          this.admins = response.data.admins;
          this.loading = false;
        });
      },

      deleteAdmin: function (adminID) {
        var headers = {
          Authorization: `Bearer ` + localStorage.getItem("rezo_billing_user"),
        };

        this.$confirm.require({
          message: 'Are you sure you want to proceed?',
          header: 'Delete',
          acceptClass: 'p-button-success',
          rejectClass: 'p-button-danger',
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            axios
            .delete(this.app_base_url + "/admins/" + adminID, {
              headers: headers,
            })
            .then((response) => {
              this.getAdmins();
              this.$toast.add({ severity: 'success', summary: 'Deleted', detail: 'Record deleted successfully!', life: 2000 });
              console.info("Admin deleted: ", response);
            })
            .catch((error) => {
              console.warn("Your Error is :", error);
            });
          },
          reject: () => {
            this.$toast.add({ severity: 'error', summary: 'Record Safe', detail: 'Your record is safe!', life: 2000 });
          }
        });
      },

      resetSearch: function () {
        this.searchFilters = {};
      },
    }
  };
</script>