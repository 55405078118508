<template>
    <page-header :title="'Customer | '" :module_title="companyName" />

    <ul class="nav nav-pills nav-fill mt-3 mb-3">

        <li class="nav-item">
            <a class="nav-link " :class="{'active': activeTab == 'profile'}" @click="goToTab('profile')"
                href="javascript:void(0)"><i class="fa fa-user" aria-hidden="true"></i> Profile</a>
        </li>

        <li class="nav-item">
            <a class="nav-link" :class="{'active': activeTab == 'customerProducts'}" href="javascript:void(0)"
                @click="goToTab('customerProducts')"><i class="fa fa-th-large" aria-hidden="true"></i> Products</a>
        </li>
        
        <li class="nav-item">
            <a class="nav-link" :class="{'active': activeTab == 'customerInvoices'}" href="javascript:void(0)"
                @click="goToTab('customerInvoices')"><i class="fa fa-copy"></i> Invoices</a>
        </li>

        <li class="nav-item">
            <a class="nav-link" :class="{'active': activeTab == 'customerBillingHistory'}" href="javascript:void(0)"
                @click="goToTab('customerBillingHistory')"><i class="fa fa-history" aria-hidden="true"></i> Billing History</a>
        </li>

        <li class="nav-item">
            <a class="nav-link" :class="{'active': activeTab == 'customerSentEmails'}" href="javascript:void(0)"
                @click="goToTab('customerSentEmails')"><i class="fa fa-envelope" aria-hidden="true"></i> Sent Emails</a>
        </li>
    </ul>

    <section v-if="activeTab == 'profile'">
        <CustomerProfile/>
    </section>

    <section v-if="activeTab == 'customerProducts'">
        <CustomerProduct />
    </section>

    <section v-if="activeTab == 'customerInvoices'">
        <customerInvoice />
    </section>

    <section v-if="activeTab == 'customerBillingHistory'">
        <customerBillingHistory />
    </section>
    
    <section v-if="activeTab == 'customerSentEmails'">
        <CustomerSentEmails />
    </section>

</template>
  
<script>
import PageHeader from "../SharedComponents/PageHeader.vue";
// import surveyForm from "./Create.vue";
import CustomerProfile from './partials/CustomerProfile.vue';
import CustomerProduct from './partials/CustomerProduct.vue';
import CustomerInvoice from './partials/CustomerInvoices.vue';
import customerBillingHistory from './partials/CustomerBillingHistorys.vue';
import CustomerSentEmails from './partials/CustomerSentEmails.vue';

import axios from "axios";
import { useRoute } from 'vue-router'

export default {
    setup() {

    },

    components: {
        PageHeader,
        CustomerProfile,
        CustomerProduct,
        CustomerInvoice,
        customerBillingHistory,
        CustomerSentEmails
    },

    data() {
        const route = useRoute()
        // console.log(route.query)
        return {
            companyName: null,
            userID: null,
            app_base_url: process.env.VUE_APP_BASE_URL,
            activeTab: route.query.activeTab == undefined ? 'profile' : route.query.activeTab,
        };
    },
    created() {
        this.userID = this.$route.params.id;
        this.getCustomer();
    },
    methods: {
        goToTab(param) {
            //router.push({ path: '', query: { plan: 'private' }})
            this.$router.replace({ query: { activeTab: param } });
            this.activeTab = param;
        },

        getCustomer: function () {
            var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_billing_user") };
            axios.get(this.app_base_url + '/customers/' + this.userID, { headers })
            .then((response) => {
                console.info("Show Customer: ", response.data.customer);
                this.companyName = response.data.customer.company_name;
            }).catch((error) => {
                console.warn('Your Error is :', error);
            })
        },
    }

};
</script>
  