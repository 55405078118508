<template>
  <page-header :title="formTitle" />
  <div class="card col-md-12 mb-3">
    <div class="card-body" style="line-height: 4px;">
      <div class="row justify-content-center" v-if="loading">
        <ProgressSpinner 
          style="width:50px;height:50px" 
          strokeWidth="1" 
          fill="var(--surface-ground)"
          animationDuration=".5s"
        />
      </div>
      <div v-else>
        <form @submit.prevent="formSubmit" class="user">
          <div class="form-group row mt-1">
            <div class="col-sm-6">
              <label class="required" for="title">Title</label>
              <input type="text" autocomplete="chrome-off" v-model="title"
                class="form-control mb-2 form-control-sm mb-1" id="title"/>
              <span class="text-danger" v-if="v$.title.$error">
                <small class="help-block" v-for="validationError in v$.title.$errors" :key="validationError">
                  {{ validationError.$message }}
                </small>
              </span>
            </div>
            <div class="col-sm-6">
              <label class="required" for="subject">Subject</label>
              <input type="text" readonly autocomplete="chrome-off" v-model="subject"
                class="form-control mb-2 form-control-sm mb-1" id="subject"/>
              <span class="text-danger" v-if="v$.subject.$error">
                <small class="help-block" v-for="validationError in v$.subject.$errors" :key="validationError">
                  {{ validationError.$message }}
                </small>
              </span>
            </div>
          </div>
          
          <div class="form-group row mt-1">
            <div class="col-sm-6">
              <label for="staticBody">Fix Body <small class="text-danger">(Read only)</small></label>
              <Editor v-model="staticBody" readonly editorStyle="height: 300px">
                <template #toolbar>
                  <span class="ql-formats">
                    <button class="ql-bold"></button>
                    <button class="ql-italic"></button>
                    <button class="ql-underline"></button>
                  </span>
                </template>
              </Editor>
            </div>
          <!-- </div>
          
          <div class="form-group row mt-3"> -->
            <div class="col-sm-6">
              <label for="dynamicBody">Dynamic Body</label>
              <Editor v-model="dynamicBody" editorStyle="height: 300px">
                <template #toolbar>
                  <span class="ql-formats">
                    <button class="ql-bold"></button>
                    <button class="ql-italic"></button>
                    <button class="ql-underline"></button>
                  </span>
                </template>
              </Editor>
            </div>
          </div>
          <button class="btn btn-primary btn-sm" :disabled="btnDisabled">{{btnTitle}} <i v-if="btnDisabled" class="pi pi-spin pi-spinner" style="font-size: 1rem"></i></button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import useValidate from "@vuelidate/core";
  import { 
    required, 
    helpers,
  } from "@vuelidate/validators";
  import PageHeader from "../SharedComponents/PageHeader.vue";
  import axios from "axios";
  import ProgressSpinner from 'primevue/progressspinner';
  import 'primeicons/primeicons.css';
  import Editor from 'primevue/editor';

  export default {
    components: { 
      PageHeader,
      ProgressSpinner,
      Editor
    },
    data() {
      return {
        app_base_url: process.env.VUE_APP_BASE_URL,
        v$: useValidate(),
        formTitle: "Edit Template",
        loading: true,
        btnDisabled: false,
        btnTitle: "Update",

        //Fields
        templateID: null,
        title: null,
        subject: null,
        staticBody: null,
        dynamicBody: null
      };
    },

    validations() {
      return {
        title: {
          required: helpers.withMessage('Template title is required.', required),
        },
        subject: {
          required: helpers.withMessage('Email subject is required.', required),
        }
      };
    },


    created() {
      this.templateID = this.$route.params.id;
      this.getTemplate();
    },

    methods: {

      getTemplate: function () {
        var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_billing_user") };
        axios.get(this.app_base_url + '/email_templates/' + this.templateID, { headers })
        .then((response) => {
          this.loading = false;
          console.info("Edit Template: ", response.data.template);
          this.title = response.data.template.title,
          this.subject = response.data.template.subject,
          this.staticBody = response.data.template.static_body,
          this.dynamicBody = response.data.template.dynamic_body
        }).catch((error) => {
          console.warn('Your Error is :', error);
          this.$notify({
            type: "error",
            title: "Something Went Wrong",
            text: "Error!",
          });
        })
      },

      formSubmit() {
        this.v$.$validate();
        if (this.v$.$error) {
          console.log("errors", this.v$.$error);
        } else {

          this.btnDisabled = true;
          this.btnTitle = "Please wait...";

          var data = {
            title: this.title,
            subject: this.subject,
            static_body: this.staticBody,
            dynamic_body: this.dynamicBody
          };
          
          var headers = {
            Authorization:
              `Bearer ` + localStorage.getItem("rezo_billing_user"),
          };
          axios
          .put(this.app_base_url + "/email_templates/" + this.templateID, data, { headers })
          .then((response) => {
            if (response.status === 200) {
              this.btnDisabled = false;
              this.btnTitle = "Create";

              this.$notify({
                type: "success",
                title: "Success",
                text: "Save Successfully!",
              });
              this.$router.push('/templates')
            }
          }).catch((error) => {
            this.btnDisabled = false
            this.btnTitle = 'Update';
            
            this.$notify({
              type: "error",
              title: "Something Went Wrong",
              text: "Error!",
            });
            console.warn("Your Error is :", error);
          });
        }
      },
    }
  };
</script>