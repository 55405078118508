<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card mb-3">
        <div class="card-body">
          <div class="card-body">
            <div class="row justify-content-center" v-if="loading">
              <ProgressSpinner 
                style="width:50px;height:50px" 
                strokeWidth="1" 
                fill="var(--surface-ground)"
                animationDuration=".5s"
              />
            </div>
            <div v-else>
              <div class="row">
                <div class="col-md-4"><small class="text-primary" style="text-decoration: underline;">Basic details</small> </div>
                <div class="col-md-4"></div>
                <div class="col-md-4"><small class="text-primary" style="text-decoration: underline;">Payment Information</small></div>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <p><strong>Company Name:</strong> {{companyName}}</p>
                  <p><strong>Address:</strong> {{Address}}</p>
                  <p><strong>City:</strong> {{City}}</p>
                  <p><strong>State:</strong> {{State}}</p>
                  <p><strong>Zip Code:</strong> {{Zip}}</p>
                  <p><strong>Phone:</strong> {{Phone}}</p>
                  <router-link :to="{name:'EditCustomer', params: { userID}}" class="btn btn-primary btn-sm">Edit</router-link> 
                </div>
                <div class="col-md-4">
                  <p><strong>Contact Name:</strong> {{contactName}}</p>
                  <p><strong>Contact Phone:</strong> {{contactPhone}}</p>
                  <p><strong>Email:</strong> {{Email}}</p>
                  <p><strong>Additional Email:</strong> {{additionalEmail}}</p>
                  <p><strong>Created Date:</strong> {{ moment(createdDate).format("MM/DD/YYYY, h:mm:ss a") }}</p>
                  <!-- <p><strong>Created Date:</strong> {{ createdDate }}</p> -->

                  <div class="form-inline">
                    <div class="form-group mr-2">
                      <label for="checkboxActive" class="mr-1">Active</label>
                      <label class="switch">
                        <input type="checkbox" id="checkboxActive"  v-on:click="customerActive()" v-model="isActive" />
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <p v-if="default_payment_method"><strong>Default Card:</strong> ************{{ default_payment_method }}</p>
                  <p v-else>User does not have a default payment method.</p>
                  <p v-if="braintree_customer_id"><strong>Customer ID:</strong> {{ braintree_customer_id }}</p>
                  <!-- <p><strong>Selected Pay Date:</strong> No Date Selected</p> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Toast />
</template>
<script>

import axios from "axios";
import moment from "moment";
// import { mapState } from "vuex";
import Toast from 'primevue/toast';
import ProgressSpinner from 'primevue/progressspinner';
export default {
  // computed: mapState(["user"]),
  components: {
    Toast, 
    ProgressSpinner
  },

  data() {
    return {
      app_base_url: process.env.VUE_APP_BASE_URL, 
      isActive: false,
      moment: moment,
      loading: true,
      //Fields
      userID: null,
      userRole: "Staff",
      companyName: null,
      Address: null,
      City: null,
      State: null,
      Zip: null,
      Phone: null,
      contactName: null,
      contactPhone: null,
      Email: null,
      Password: null,
      additionalEmail: null,
      createdDate: null,
      default_payment_method: null,
      braintree_customer_id: null,
    };
  },
  
  created() {
    this.userID = this.$route.params.id;
    this.getCustomer();
  },
  methods: {

    getCustomer: function () {
      var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_billing_user") };
      axios.get(this.app_base_url + '/customers/' + this.userID, { headers })
      .then((response) => {
        this.loading = false,
        console.info("Show Customer: ", response.data.customer);
        this.companyName = response.data.customer.company_name,
        this.Address = response.data.customer.address,
        this.City = response.data.customer.city,
        this.State = response.data.customer.state,
        this.Zip = response.data.customer.zip,
        this.Phone = response.data.customer.phone,
        this.contactName = response.data.customer.contact_name,
        this.contactPhone = response.data.customer.contact_phone,
        this.Email = response.data.customer.email,
        this.additionalEmail = response.data.customer.additional_email
        this.isActive = response.data.customer.active?true:false,
        this.createdDate = response.data.customer.created_at
        this.default_payment_method = response.data.default_payment_method
        this.braintree_customer_id = response.data.braintree_customer_id
      }).catch((error) => {
        console.warn('Your Error is :', error);
      })
    },

    customerActive: function () {
      var data = {
        user_id: this.userID,
        active: !this.isActive,
      };

      var headers = {
        Authorization:
          `Bearer ` + localStorage.getItem("rezo_billing_user"),
      };
      axios
      .post(this.app_base_url + "/customers", data, { headers })
      .then((response) => {
        if (response.status === 200) {
          if (this.isActive) {
            this.$toast.add({ severity: 'success', summary: 'Activated', detail: 'Customer status changed successfully!', life: 2000 });
          }else{
            this.$toast.add({ severity: 'error', summary: 'Inactivated', detail: 'Customer status changed successfully!', life: 2000 });
          }
        }
      }).catch((error) => {
        this.$notify({
          type: "error",
          title: "Something Went Wrong",
          text: "Error!",
        });

        console.warn("Your Error is :", error);
      });
    }
  },
};
</script>
