<template>
    <div>
        <div class="row justify-content-center" v-if="loading">
            <ProgressSpinner style="width:50px;height:50px" strokeWidth="1" fill="var(--surface-ground)"
                animationDuration=".5s" />
        </div>
        <div v-else>
            <div class="row">
                <div class="col-md-12">
                    <table class="table">
                        <thead>
                            <tr style="font-size: 15px;">
                            <th class="p-1" scope="col">#</th>
                            <th class="p-1" scope="col">Name</th>
                            <th class="p-1" scope="col">Description</th>
                            <th class="p-1" scope="col">Startup Fee</th>
                            <th class="p-1" scope="col">Monthly Fee</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(product, key) in products.data" :key="product" class="spaceUnder" style="font-size: 15px;">
                                <th class="p-1" scope="row">{{key + 1}}- </th>
                                <td class="p-1">{{product.name}}</td>
                                <td class="p-1">{{product.description}}</td>
                                <td class="p-1 text-danger">${{ USDCurrancyformat(product.startup_fee) }}</td>
                                <td class="p-1 text-secondary">${{ USDCurrancyformat(product.monthly_fee) }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="text-center pt-0">
                        <small class="text-danger" v-if="products.data.length < 1">No product found.</small>
                    </div>
                    <div v-if="products.data.length > 0" class="pagination d-flex justify-content-center mt-1">
                        <button class="btn btn-outline-secondary pt-0 pb-0 mr-1" @click="getCustomer(products.first_page_url)" :disabled="pagination.current_page == 1" style="font-size: 14px; height: 30px;"> First Page </button>
                        <button class="btn btn-outline-secondary pt-0 pb-0 mr-2" @click="getCustomer(pagination.prev_page_url)" :disabled="pagination.current_page == 1" style="font-size: 14px; height: 30px;"> Previous </button>
                        <label>Page {{ pagination.current_page }} of {{ pagination.last_page }}</label>
                        <button class="btn btn-outline-secondary pt-0 pb-0 ml-2" @click="getCustomer(pagination.next_page_url)" :disabled="pagination.current_page == pagination.last_page" style="font-size: 14px; height: 30px;">Next </button>
                        <button class="btn btn-outline-secondary pt-0 pb-0 ml-1" @click="getCustomer(products.last_page_url)" :disabled="pagination.current_page == pagination.last_page" style="font-size: 14px; height: 30px;"> Last Page </button>

                        <div class="ml-1">
                        <select v-model="searchFilters.per_page" class="form-control" style="border: 1px solid #858796; height: 30px; padding-top: 2px;">
                            <option selected :value="2">2</option>
                            <option selected :value="3">3</option>
                            <option selected :value="5">5</option>
                            <option selected :value="10">10</option>
                            <option selected :value="20">20</option>
                            <option selected :value="50">50</option>
                        </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import axios from "axios";
import moment from "moment";
import ProgressSpinner from 'primevue/progressspinner';
export default {
    components: {
        ProgressSpinner,
    },
    data() {
        return {
            app_base_url: process.env.VUE_APP_BASE_URL,
            moment: moment,
            loading: true,
            
            //Fields
            userID: null,
            products: [],
            pagination: {},
            searchFilters: {
                per_page:5
            },
        };
    },
    watch: {
        searchFilters: {
            deep: true,
            handler() {
                if (!this.awaitingSearch) {
                setTimeout(() => {
                    this.getCustomer(this.app_base_url + '/customers/' + this.userID)
                    this.loading = true;
                    this.awaitingSearch = false;
                }, 700);
                }
                this.awaitingSearch = true;
            }
        },
    },
    created() {
        this.userID = localStorage.getItem("logged_user_id");
        this.getCustomer();
    },
    methods: {
        USDCurrancyformat(value) {
            let val = (value/1).toFixed(2).replace(',', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },

        getCustomer: function (page_url) {
            this.loading = true;
            let vm = this;
            page_url = page_url || this.app_base_url + '/customers/' + this.userID
            var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_billing_front_user") };
            axios.get(page_url, { headers, params: (this.searchFilters) })
            .then((response) => {
                this.loading = false;
                this.products = response.data.CustomerProducts;
                vm.makePagination(response.data)
                this.loading = false;
            });
        },

        makePagination: function (data) {

            let pagination = {
                current_page: data.CustomerProducts.current_page,
                last_page: data.CustomerProducts.last_page,
                next_page_url: this.app_base_url + '/customers/' + this.userID + '?page=' + (data.CustomerProducts.current_page + 1),
                prev_page_url: this.app_base_url + '/customers/' + this.userID + '?page=' + (data.CustomerProducts.current_page - 1),
            }
            
            this.pagination = pagination;
        },
    },
};
</script>
<style scoped>
tr.spaceUnder>td {
  padding-bottom: 1em;
}
</style>
  