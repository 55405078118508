<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card mb-3">
        <div class="card-body">
          <div class="card-body">
            <div class="row">
              <div class="col-md-8">
                <div class="row justify-content-center" v-if="loading">
                  <ProgressSpinner 
                    style="width:50px;height:50px" 
                    strokeWidth="1" 
                    fill="var(--surface-ground)"
                    animationDuration=".5s"
                  />
                </div>
                <div v-else>
                  <DataTable :value="CustomerInvoices.data" style="padding:4px;" :lazy="true" :loading="loading" @sort="onSort" :sortField="this.sortField" :sortOrder="this.sortOrder">
                    <template  #empty>
                        <div class="text-danger text-center">No invoice found.</div>
                    </template>

                    <Column style="padding:4px; font-size: 13px;" field="date" header="Date" :sortable="true">
                      <template #body="slotDate">
                        {{ moment(slotDate.data.date).format("MM/DD/YYYY") }}
                        <div class="my-1">
                          <span v-if="slotDate.data.is_paid" class="badge badge-pill badge-success">Paid</span>
                          <span v-else class="badge badge-pill badge-danger">Unpaid</span>
                        </div>
                      </template>
                    </Column>
                    
                    <Column style="padding:3px; font-size: 13px;" field="notes" header="Note" :sortable="false"></Column>
                    <!-- <Column style="padding:3px; font-size: 15px;" field="date" header="Invoice Date" :sortable="true"></Column> -->
                    
                    <Column style="padding:3px; font-size: 13px;" field="invoice_amount" header="Amount" :sortable="false">
                      <template #body="slotdata">
                        <div v-if="slotdata.data.invoice_amount != null">
                          ${{ USDCurrancyformat(slotdata.data.invoice_amount) }}
                        </div>
                        <div v-else>
                          <small>N/A</small>
                        </div>
                      </template>
                    </Column>

                    <!-- <Column style="padding:4px;" field="date" header="Status" :sortable="true">
                      <template #body="slotDate">
                        <span v-if="slotDate.data.is_paid" class="badge badge-pill badge-success">Paid</span>
                        <span v-else class="badge badge-pill badge-danger">Unpaid</span>
                      </template>
                    </Column> -->

                    <Column style="padding:4px;font-size: 13px;" field="document_file" header="Attachment" :sortable="false">
                      <template #body="slotDate">
                        <div v-if="slotDate.data.document_file != null">
                          <button class="btn-info pt-0 pb-0 btn btn-sm" @click="downloadFile(slotDate.data.id)"><div v-if="FileBtnDisabled"><i class="pl-3 pr-3 pt-1  pi pi-spin pi-spinner" style="font-size: 1rem"></i></div><div v-else>View file</div></button>
                        </div>
                        <div v-else>
                            <small>No file</small>
                        </div>
                      </template>
                    </Column>
                    
                    <Column style="padding:3px; font-size: 15px;font-size: 13px;" header="Actions">
                      <template #body="slotProps">
                        <button v-tooltip.top="'View invoice'" v-on:click="showInvoice(slotProps.data.id)" class="btn btn-circle btn-secondary btn-sm ml-1"><i
                            class="fas fa-eye"></i></button>

                        <Dialog header="View Invoice" v-model:visible="displayInvoicePopUp" :style="{width: '30vw'}" >
                          <viewInvoice :ViewInvoiceID="ViewInvoiceID" @Display="dialog($event)" />
                        </Dialog>

                        <button v-tooltip.top="'Edit invoice'" :disabled="editBtnDisabled" v-on:click="editInvoice(slotProps.data.id)" class="btn btn-circle btn-primary btn-sm ml-1">
                          <i v-if="!editBtnDisabled" class="fas fa-edit"></i>
                          <i v-if="editBtnDisabled" class="pi pi-spin pi-spinner" style="font-size: 1rem"></i>
                        </button>
                        <button v-tooltip.top="'Pay invoice'" v-if="!slotProps.data.is_paid" v-on:click="payInvoice(slotProps.data.id)" class="btn btn-circle btn-primary btn-sm ml-1"><i
                            class="fas fa-credit-card"></i></button>

                        <Dialog header="Pay Invoice" v-model:visible="displayPayInvoicePopUp" :style="{width: '30vw'}" >
                          <payInvoice :PayInvoiceID="PayInvoiceID" @Display="dialogPayInvoice($event)" />
                        </Dialog>

                        <button v-tooltip.top="'Delete invoice'" v-on:click="deleteInvoice(slotProps.data.id)" class="btn btn-circle btn-danger btn-sm ml-1"><i
                            class="fas fa-trash"></i></button>
                      </template>
                    </Column>
                  </DataTable>
                  <div v-if="CustomerInvoices.data.length > 0" class="pagination d-flex justify-content-center mt-1">
                    <button class="btn btn-outline-secondary pt-0 pb-0 mr-1" @click="getCustomerInvoices(CustomerInvoices.first_page_url)" :disabled="pagination.current_page == 1" style="font-size: 14px; height: 30px;"> First Page </button>
                    <button class="btn btn-outline-secondary pt-0 pb-0 mr-2" @click="getCustomerInvoices(pagination.prev_page_url)" :disabled="pagination.current_page == 1" style="font-size: 14px; height: 30px;"> Previous </button>
                    <label>Page {{ pagination.current_page }} of {{ pagination.last_page }}</label>
                    <button class="btn btn-outline-secondary pt-0 pb-0 ml-2" @click="getCustomerInvoices(pagination.next_page_url)" :disabled="pagination.current_page == pagination.last_page" style="font-size: 14px; height: 30px;">Next </button>
                    <button class="btn btn-outline-secondary pt-0 pb-0 ml-1" @click="getCustomerInvoices(CustomerInvoices.last_page_url)" :disabled="pagination.current_page == pagination.last_page" style="font-size: 14px; height: 30px;"> Last Page </button>

                    <div class="ml-1">
                      <select v-model="searchFilters.per_page" class="form-control" style="border: 1px solid #858796; height: 30px; padding-top: 2px;">
                        <option selected :value="3">3</option>
                        <option selected :value="5">5</option>
                        <option selected :value="10">10</option>
                        <option selected :value="20">20</option>
                        <option selected :value="50">50</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <form @submit.prevent="formSubmit">

                  <div class="row">
                    <div class="col-md-4">
                      <div class="">
                        <small class="required">Invoice #</small> <br>
                        <InputNumber 
                          :inputClass="this.v$.invoiceNumber.$error ? 'form-control form-control-sm is-invalid':'form-control form-control-sm'"
                          inputStyle="height: 31px; font-size: 13px;"
                          :useGrouping=false
                          v-model="invoiceNumber"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="">
                        <small class="required">Invoice Amount</small><br>
                        <InputNumber 
                          :inputClass="this.v$.invoiceAmount.$error ? 'form-control form-control-sm is-invalid':'form-control form-control-sm'"
                          inputStyle="height: 31px; font-size: 13px;"
                          mode="currency" 
                          currency="USD" 
                          v-model="invoiceAmount"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row mt-1">
                    <div class="col-md-6">
                      <div class="">
                        <small class="required" for="Name">Date</small><br>
                        <!-- <input type="date" :min="{todayDate}" autocomplete="chrome-off" v-model="invoiceDate" class="form-control form-control-sm" :class="{ 'is-invalid': v$.invoiceDate.$error }"/> -->
                        <Calendar v-model="invoiceDate" inputClass="form-control-sm" :minDate="new Date(todayDate)" dateFormat="mm/dd/yy" />
                      </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-inline">
                          <div class="form-group mt-4">
                            <input type="checkbox" id="saasPaymentActive" class="mr-1" v-model="isSaasPayment" />
                            <label for="saasPaymentActive" style="font-size: 14px;">SAAS Payment</label>
                          </div>
                        </div>
                    </div>
                  </div>
                  
                  <div class="mt-1">
                    <small class="required" for="Notes">Notes</small>
                    <textarea id="Notes" autocomplete="chrome-off"
                      class="form-control form-control-sm" :class="{ 'is-invalid': v$.invoiceNotes.$error }"
                      name="txtname" 
                      rows="2" 
                      cols="20" 
                      maxlength="200"
                      v-model="invoiceNotes">
                    </textarea>
                  </div>

                  <div class="row">
                    <div class="col-md-6 mt-2">
                        <div class="form-inline">
                          <div class="form-group">
                            <input type="checkbox" id="SentStatus" class="mr-1" v-model="SentStatus" />
                            <label for="SentStatus" style="font-size: 14px;">Send Invoice</label>
                          </div>
                        </div>
                    </div>
                  </div>

                  <div class="row py-2" v-show="inputSelectedFile">
                    <div class="col-md-11 pr-0">
                      <div class="text-secondary" style="font-size: 12px;">
                        <strong>File: </strong>{{inputSelectedFile}}
                      </div>
                    </div>
                    <div class="col-md-1 pl-0">
                      <i @click="removeFile()" class="fa fa-times-circle text-danger" aria-hidden="true"></i>
                    </div>
                  </div>

                  <div class="row mt-2">
                    <div class="col-md-5 pr-1">
                      <div style="position:relative;">
                        <a v-tooltip.top="'PDF Document only'" class='btn btn-primary btn-block' href='javascript:;' style="padding-top: 6px; font-size: 12px;">
                          Upload file
                          <input 
                            type="file" 
                            ref="file" 
                            v-on:change="onFileSelected()"
                            accept=".pdf" 
                            style="position:absolute;z-index:2;top:0;left:0;filter: alpha(opacity=0);-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity=0);opacity:0;background-color:transparent;color:transparent;width: 111px;"
                            name="file"
                          >
                        </a>
                      </div>
                    </div>
                    <div class="col-md-7 pl-1">
                      <button class="btn btn-sm btn-block btn-success" :disabled="btnDisabled">{{btnTitle}} <i v-if="btnDisabled" class="pi pi-spin pi-spinner" style="font-size: 1rem"></i></button>
                    </div>

                  </div>

                  <div class="row mt-2" v-if="invoiceID">
                    <div class="col-md-5 pr-1">
                      <div>
                        <a @click="cancelUpdate()" v-tooltip.top="'Create New Invoice'" class='btn btn-danger btn-block' href='javascript:;' style="font-size: 12px;">
                          Cancel Update
                        </a>
                        <br>
                      </div>
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Toast />
  <ConfirmDialog></ConfirmDialog>
</template>
<script>

import axios from "axios";
import useValidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import Toast from 'primevue/toast';
import InputNumber from 'primevue/inputnumber';
import Tooltip from 'primevue/tooltip';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import moment from "moment";
import Dialog from 'primevue/dialog';
import ConfirmDialog from 'primevue/confirmdialog';
import ProgressSpinner from 'primevue/progressspinner';
import 'primeicons/primeicons.css';
// import { mapState } from "vuex";
import viewInvoice from '../partials/viewInvoice.vue'
import payInvoice from '../partials/payInvoice.vue'
import Calendar from 'primevue/calendar';

export default {
  // computed: mapState(["user"]),
  directives: {
    'tooltip': Tooltip
  },
  components: {
    Toast, 
    InputNumber,
    DataTable,
    Column,
    Dialog,
    ConfirmDialog,
    ProgressSpinner,
    viewInvoice,
    payInvoice,
    Calendar
  },
  data() {
    return {
      app_base_url: process.env.VUE_APP_BASE_URL,
      v$: useValidate(),
      btnTitle: "Create",
      btnDisabled: false,
      editBtnDisabled: false,
      CustomerInvoices: [],
      pagination: {},
      searchFilters: {
        'per_page':5
      },
      loading: true,
      displayInvoicePopUp: false,
      FileBtnDisabled: false,
      inputSelectedFile: null,
      loadRows: 5,
      moment: moment,
      ViewInvoiceID: null,
      
      //Fields
      invoiceID: null,
      userID: null,
      invoiceNumber: null,
      invoiceAmount: null,
      isSaasPayment: 0,
      SentStatus: true,
      invoiceDate: null,
      invoiceNotes: null,
      selectedFile: null,

      // extra
      todayDate: moment().format('YYYY-MM-DD').toString(),

      displayPayInvoicePopUp : false,
      PayInvoiceID: null,

      sortOrder: null,
      sortField: null
    };
  },
  
  watch: {
    searchFilters: {
      deep: true,
      handler() {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.getCustomerInvoices(this.app_base_url + '/customers/' + this.userID)
            this.loading = true;
            this.awaitingSearch = false;
          }, 700);
        }
        this.awaitingSearch = true;
      }
    },
  },

  validations() {
    return {
      invoiceNumber: {
        required: helpers.withMessage('Invoice # is required.', required),
      },
      invoiceAmount: {
        required: helpers.withMessage('Invoice amount is required.', required),
      },
      invoiceDate: {
        required: helpers.withMessage('Please select invoice date.', required),
      },
      invoiceNotes: {
        required: helpers.withMessage('Notes is required.', required),
      }
    };
  },
  
  created() {
    this.userID = this.$route.params.id;
    this.getCustomerInvoices();
  },
  methods: {

    onSort(e) {
      this.sortField = e.sortField;
      this.sortOrder = e.sortOrder;

      this.searchFilters = {
        'per_page': 5,
        'sort_by': e.sortField,
        'order': e.sortOrder,
      }
    },
    
    USDCurrancyformat(value) {
      let val = (value/1).toFixed(2).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },

    dialog(val)
    {
      this.displayInvoicePopUp=val;
      this.$toast.add({ severity: 'success', summary: 'Invoice Sent', detail: 'Mail sent successfully to customer.', life: 2000 });
    },

    dialogPayInvoice(val)
    {
      this.displayPayInvoicePopUp = val;
      this.getCustomerInvoices();
      // this.$toast.add({ severity: 'success', summary: 'Invoice Sent', detail: 'Mail sent successfully to customer.', life: 2000 });
    },

    showInvoice(invoiceID) {
      this.displayInvoicePopUp = true;
      this.ViewInvoiceID = invoiceID;
    },

    payInvoice(invoiceID) {
      this.displayPayInvoicePopUp = true;
      this.PayInvoiceID = invoiceID;
    },

    onFileSelected(){
      this.selectedFile = this.$refs.file.files[0];
      this.inputSelectedFile = this.$refs.file.files[0].name;
    },

    removeFile(){
      this.selectedFile = null;
      this.inputSelectedFile = null;
    },
    
    downloadFile(invoiceId){
      this.FileBtnDisabled = true;
      var data = {
        user_id: this.userID,
        id: invoiceId
      };

      var headers = {
        Authorization:
          `Bearer ` + localStorage.getItem("rezo_billing_user"),
      };
      axios
      .post(this.app_base_url + "/invoice_file/download", data, { headers,  responseType: 'blob' })
      .then((response) => {
        if (response.status === 200) {
          this.FileBtnDisabled = false;
          // console.log(response);
          const file = new Blob(
          [response.data], 
          {type: 'application/pdf'});
          //Build a URL from the file
          const fileURL = URL.createObjectURL(file);
          //Open the URL on new Window
          window.open(fileURL);
        }
      }).catch((error) => {
        this.$notify({
          type: "error",
          title: "Something Went Wrong",
          text: "Error!",
        });
        console.warn("Your Error is :", error);
      });
    },

    formSubmit() {
      this.v$.$validate();
      if (this.v$.$error) {
        console.log("errors", this.v$.$error);
        if (this.v$.invoiceNumber.$error) { 
          this.v$.invoiceNumber.$errors.forEach(validationError => {
            this.$toast.add({ 
              severity: 'error', 
              summary: 'Required',
              detail: validationError.$message, 
              life: 2000 
            });
          });
        }
        if (this.v$.invoiceAmount.$error) { 
          this.v$.invoiceAmount.$errors.forEach(validationError => {
            this.$toast.add({ 
              severity: 'error', 
              summary: 'Required',
              detail: validationError.$message, 
              life: 2000 
            });
          });
        }
        if (this.v$.invoiceDate.$error) { 
          this.v$.invoiceDate.$errors.forEach(validationError => {
            this.$toast.add({ 
              severity: 'error', 
              summary: 'Required',
              detail: validationError.$message, 
              life: 2000 
            });
          });
        }
        if (this.v$.invoiceNotes.$error) { 
          this.v$.invoiceNotes.$errors.forEach(validationError => {
            this.$toast.add({ 
              severity: 'error', 
              summary: 'Required',
              detail: validationError.$message, 
              life: 2000 
            });
          });
        }
      } else {
        this.btnDisabled = true;
        this.btnTitle = "Please wait...";

        let formData = new FormData();        
        if (this.invoiceID != null) { 
          formData.append("id", this.invoiceID); 
        }
        formData.append("user_id", this.userID);
        formData.append("invoice_no", this.invoiceNumber);
        formData.append("invoice_amount", this.invoiceAmount);
        formData.append("is_saas_payment", this.isSaasPayment ? 1 : 0);
        formData.append("status", this.SentStatus ? 1 : 0);
        // formData.append("date", this.invoiceDate);


        console.log(this.invoiceDate);

        let day = this.invoiceDate.getDate();
        let month = this.invoiceDate.getMonth();
        let year = this.invoiceDate.getFullYear();
        
        formData.append("date", year + '-' + month + '-' + day);
        formData.append("notes", this.invoiceNotes);
        formData.append('file', this.selectedFile)

        var headers = {
          Authorization:`Bearer ` + localStorage.getItem("rezo_billing_user"),
          'Content-Type': 'multipart/form-data'
        };
        axios
        .post(this.app_base_url + "/customer_invoices", formData,  { headers })
        .then((response) => {
          if (response.status === 200) {
            this.getCustomerInvoices();
            this.btnDisabled = false;
            this.btnTitle = "Create";
            this.invoiceNumber =  null,
            this.invoiceAmount =  null,
            this.isSaasPayment = 0;
            this.invoiceDate = null,
            this.invoiceNotes = null,
            this.invoiceID = null,
            this.selectedFile = null,
            this.inputSelectedFile = null,
            this.v$. $reset();
            this.$notify({
              type: "success",
              title: "Success",
              text: "Save Successfully!",
            });
          }
        }).catch((error) => {
          this.btnDisabled = false
          this.btnTitle = 'Create';
          this.$notify({
            type: "error",
            title: "Something Went Wrong",
            text: "Error!",
          });
          console.warn("Your Error is :", error);
        });
      }
    },

    getCustomerInvoices: function (page_url) {
      this.loading = true;
      let vm = this;
      page_url = page_url || this.app_base_url + '/customers/' + this.userID
      var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_billing_user") };
      axios.get(page_url, { headers, params: (this.searchFilters) })
      .then((response) => {
        this.loading = false;
        this.CustomerInvoices = response.data.CustomerInvoices;
        vm.makePagination(response.data)
        this.loading = false;
      });
    },

    makePagination: function (data) {
      
      let pagination = {
        current_page: data.CustomerInvoices.current_page,
        last_page: data.CustomerInvoices.last_page,
        next_page_url: this.app_base_url + '/customers/' + this.userID + '?page=' + (data.CustomerInvoices.current_page + 1),
        prev_page_url: this.app_base_url + '/customers/' + this.userID + '?page=' + (data.CustomerInvoices.current_page - 1),
      }
      
      this.pagination = pagination;
    },

    editInvoice: function (id) {
      this.editBtnDisabled = true;
      var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_billing_user") };
      axios.get(this.app_base_url + '/customer_invoices/' + id, { headers })
      .then((response) => {
        this.editBtnDisabled = false;
        this.btnTitle = "Update";
        console.info("Show Customer Invoice: ", response.data.CustomerInvoice);
        this.invoiceID = response.data.CustomerInvoice.id;
        this.invoiceNumber =  response.data.CustomerInvoice.invoice_no;
        this.invoiceAmount =  response.data.CustomerInvoice.invoice_amount;
        this.isSaasPayment = response.data.CustomerInvoice.is_saas_payment?true:false,
        this.SentStatus = response.data.CustomerInvoice.status?true:false,
        this.invoiceDate = response.data.CustomerInvoice.date;
        this.invoiceNotes = response.data.CustomerInvoice.notes;
      }).catch((error) => {
        console.warn('Your Error is :', error);
        this.$notify({
          type: "error",
          title: "Something Went Wrong",
          text: "Error!",
        });
      })
    },

    cancelUpdate: function () {
      this.editBtnDisabled = false;
      this.btnTitle = "Create";
      this.invoiceID = null;
      this.invoiceNumber =  null;
      this.invoiceAmount =  null;
      this.isSaasPayment = null;
      this.SentStatus = null;
      this.invoiceDate = null;
      this.invoiceNotes = null;
    },

    deleteInvoice: function (id) {
      var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_billing_user") };
      this.$confirm.require({
        message: 'Are you sure you want to proceed?',
        header: 'Delete',
        acceptClass: 'p-button-success',
        rejectClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          axios
          .delete(this.app_base_url + "/customer_invoices/" + id, {
            headers: headers,
          })
          .then((response) => {
            this.getCustomerInvoices();
            this.$toast.add({ severity: 'success', summary: 'Deleted', detail: 'Record deleted successfully!', life: 2000 });
            console.info("Customer Invoice deleted: ", response);
          })
          .catch((error) => {
            console.warn("Your Error is :", error);
            this.$notify({
              type: "error",
              title: "Something Went Wrong",
              text: "Error!",
            });
          });
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: 'Record Safe', detail: 'Your record is safe!', life: 2000 });
        }
      });
    }
  }
};
</script>