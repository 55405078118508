<template>
    <div class="d-flex align-items-center justify-content-center mb-5" style="margin-top: 70px">
      <div class="text-center">
        <i class="fa fa-check-circle fa-5x text-success" aria-hidden="true"></i>
        <p class="lead mt-3">
            Transaction completed successfully, Thank you for making your payment.
        </p>
        <router-link :to="redirect" class="btn btn-success">Go To Dashboard</router-link>
      </div>
    </div>
  </template>
  
  <script>
    export default {
      name: 'ThankYou',
      computed: {
        redirect() {
            return '/front';
        },
      },
    }
  </script>