<template>
    <div class="row justify-content-center" v-if="loading">
            <ProgressSpinner 
            style="width:50px;height:50px" 
            strokeWidth="1" 
            fill="var(--surface-ground)"
            animationDuration=".5s"
            />
        </div>
    <div v-else>
        <form @submit.prevent="formSubmit" class="user">
            <div class="form-group row mt-0 mb-1">
                <div class="col-sm-6 mb-sm-0">
                    <small class="required" for="companyName">Company Name</small>
                    <input autocomplete="chrome-off" v-model="companyName" type="text"
                        class="form-control form-control-sm" id="companyName" :disabled="inputDisabled"/>
                    <small class="text-danger" v-if="v$.companyName.$error">
                        <span class="help-block" v-for="validationError in v$.companyName.$errors" :key="validationError">
                            {{ validationError.$message }}
                        </span>
                    </small>
                </div>
            </div>
            
            <div class="form-group row mt-0 mb-1">
                <div class="col-sm-12 mb-sm-0">
                    <small class="required" for="Address">Address</small>
                    <input autocomplete="chrome-off" v-model="Address" type="text"
                        class="form-control form-control-sm" id="Address" :disabled="inputDisabled" />
                    <small class="text-danger" v-if="v$.Address.$error">
                        <span class="help-block" v-for="validationError in v$.Address.$errors" :key="validationError">
                            {{ validationError.$message }}
                        </span>
                    </small>
                </div>
            </div>

            <div class="form-group row mt-0 mb-1">
                <div class="col-sm-4 mb-sm-0">
                    <small class="required" for="City">City</small>
                    <input autocomplete="chrome-off" v-model="City" type="text"
                        class="form-control form-control-sm" id="City" :disabled="inputDisabled" />
                    <small class="text-danger" v-if="v$.City.$error">
                        <span class="help-block" v-for="validationError in v$.City.$errors" :key="validationError">
                            {{ validationError.$message }}
                        </span>
                    </small>
                </div>
                <div class="col-sm-4 mb-sm-0">
                    <small class="required" for="selectStates"> State</small>
                    <select v-model="State" class="form-control form-control-sm" style="" id="selectStates" >
                    <option selected :value="null">--Select--</option>
                    <option v-for="state in statesList" :key="state">{{state}}</option>
                    </select>
                    <span class="text-danger" v-if="v$.State.$error">
                        <small class="help-block" v-for="validationError in v$.State.$errors" :key="validationError">
                        {{ validationError.$message }}
                        </small>
                    </span>
                </div>
                <div class="col-sm-4 mb-sm-0">
                    <small class="required" for="Zip">Zip</small>
                    <input autocomplete="chrome-off" v-model="Zip" type="text"
                        class="form-control form-control-sm" id="Address" :disabled="inputDisabled" />
                    <small class="text-danger" v-if="v$.Zip.$error">
                        <span class="help-block" v-for="validationError in v$.Zip.$errors" :key="validationError">
                            {{ validationError.$message }}
                        </span>
                    </small>
                </div>
            </div>

            <div class="form-group row mt-0 mb-1">
                <div class="col-sm-6">
                    <small for="Phone">Phone</small>
                    <InputMask
                        v-model="Phone"
                        mask="(999) 999-9999"
                        type="text"
                        class="form-control form-control-sm"
                        id="Phone"
                        autocomplete="chrome-off"
                        :disabled="inputDisabled"
                    />
                </div>

                <div class="col-sm-6">
                    <small class="required" for="contactName">Contact Name</small>
                    <input type="text" autocomplete="chrome-off" v-model="contactName"
                        class="form-control form-control-sm" id="contactName" :disabled="inputDisabled"/>
                    <span class="text-danger" v-if="v$.contactName.$error">
                        <small class="help-block" v-for="validationError in v$.contactName.$errors" :key="validationError">
                        {{ validationError.$message }}
                        </small>
                    </span>
                </div>
            </div>

            <div class="form-group row mt-0 mb-1">
                <div class="col-sm-6">
                    <small for="contactPhone">Contact Phone</small>
                    <InputMask
                        v-model="contactPhone"
                        mask="(999) 999-9999"
                        type="text"
                        class="form-control form-control-sm"
                        id="contactPhone"
                        autocomplete="chrome-off"
                        :disabled="inputDisabled"
                    />
                </div>
                <div class="col-sm-6">
                    <small class="required" for="Email">Email</small>
                    <input type="text" autocomplete="chrome-off" v-model="Email"
                        class="form-control form-control-sm" id="Email" :disabled="inputDisabled"/>
                    <span class="text-danger" v-if="v$.Email.$error">
                        <small class="help-block" v-for="validationError in v$.Email.$errors" :key="validationError">
                        {{ validationError.$message }}
                        </small>
                    </span>
                </div>
            </div>

            <div class="form-group row mt-0 mb-1">
                <div class="col-sm-6">
                    <small for="Password">Password</small>
                    <Password v-model="Password" :toggleMask="true" :disabled="inputDisabled" :inputStyle="{height: 31, width: 292}">
                        <template #header>
                            <h6 class="text-danger"> Change the current password</h6>
                        </template>
                        <template #footer>
                            <Divider />
                            <p class="mt-2">Suggestions</p>
                            <ul class="pl-2 ml-2 mt-0" style="line-height: 1.2">
                                <li>At least one lowercase</li>
                                <li>At least one uppercase</li>
                                <li>At least one numeric</li>
                                <li>Minimum 8 characters</li>
                            </ul>
                        </template>
                    </Password>
                </div>
                <div class="col-sm-6">
                    <small for="additionalEmail">Additional Email</small>
                    <input type="text" autocomplete="chrome-off" v-model="additionalEmail" class="form-control form-control-sm mb-1" id="additionalEmail" :disabled="inputDisabled"/>
                </div>
            </div>

            <button class="btn btn-primary btn-sm mt-1" :disabled="btnDisabled">{{btnTitle}} <i v-if="btnDisabled" class="pi pi-spin pi-spinner" style="font-size: 1rem"></i></button>
        </form>
    </div>
</template>
<script>
import useValidate from "@vuelidate/core";
import InputMask from 'primevue/inputmask';
import Password from 'primevue/password';
import ProgressSpinner from 'primevue/progressspinner';
import { 
    maxLength,
    minLength,
    required, 
    helpers,
    email
  } from "@vuelidate/validators";
import axios from "axios";
export default {
    
    props:[
        "userID"
    ],

    components: {
        InputMask,
        Password,
        ProgressSpinner
    },

    data() {
        return {
            app_base_url: process.env.VUE_APP_BASE_URL,
            v$: useValidate(),
            formTitle: "New Customer",
            btnDisabled: false,
            inputDisabled: false,
            passwordValidiation: true,
            btnTitle: "Update Profile",
            statesList: [],
            loading: true,

            //Fields
            userRole: "Staff",
            companyName: null,
            Address: null,
            City: null,
            State: null,
            Zip: null,
            Phone: null,
            contactName: null,
            contactPhone: null,
            Email: null,
            Password: null,
            additionalEmail: null,
            isEmailedLoginDetails: false,
        };
    },

    validations() {
        return {
            companyName: {
                maxLength: maxLength(30),
                required: helpers.withMessage('Company name is required.', required),
            },
            Address: {
                required: helpers.withMessage('Address is required.', required),
            },
            City: {
                required: helpers.withMessage('City is required.', required),
            },
            State: {
                required: helpers.withMessage('Please select any state.', required),
            },
            Zip: {
                required: helpers.withMessage('Zip is required.', required),
                maxLength: helpers.withMessage('Max 5 required.', maxLength(5)),
            },
            contactName: {
                required: helpers.withMessage('Contact name is required.', required),
            },
            Email: {
                required: helpers.withMessage('Email is required.', required),
            },
            additionalEmail: {
                email
            },
            Password: {
                minLength: minLength(4)
            }
        };
    },

    created() {
        this.getCustomer();
        this.getAmericanStates();
        this.btnTitle = "Update Profile"
    },

    methods: {

        getAmericanStates: function () {
            var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_billing_front_user") };
            axios.get(this.app_base_url + '/customers/create', { headers })
            .then((response) => {
                console.log("all states here", response.data.states)
                this.statesList = response.data.states;
            }).catch((error) => {
                console.warn('Your Error is :', error);
            })
        },
        
        getCustomer: function () {
        var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_billing_front_user") };
        axios.get(this.app_base_url + '/customers/' + this.userID, { headers })
        .then((response) => {
            this.loading = false,
            console.info("Frontend Show Customer: ", response.data.customer);
            this.companyName = response.data.customer.company_name,
            this.Address = response.data.customer.address,
            this.City = response.data.customer.city,
            this.State = response.data.customer.state,
            this.Zip = response.data.customer.zip,
            this.Phone = response.data.customer.phone,
            this.contactName = response.data.customer.contact_name,
            this.contactPhone = response.data.customer.contact_phone,
            this.Email = response.data.customer.email,
            this.additionalEmail = response.data.customer.additional_email
            }).catch((error) => {
            console.warn('Your Error is :', error);
            })
        },

        hideDialog()
        {
            this.$emit('Display',false)
        },

        formSubmit() {
            this.v$.$validate();
            if (this.v$.$error) {
                console.log("errors", this.v$.$error);
            } else {
                
                this.inputDisabled = true;
                this.btnDisabled = true;
                this.btnTitle = "Please wait...";

                var data = {
                    role: this.userRole,
                    user_id: this.userID,
                    company_name: this.companyName,
                    address: this.Address,
                    city: this.City,
                    state: this.State,
                    zip: this.Zip,
                    phone: this.Phone,
                    contact_name: this.contactName,
                    contact_phone: this.contactPhone,
                    email: this.Email,
                    password: this.Password,
                    additional_email: this.additionalEmail,
                    is_emailed_login_details: this.isEmailedLoginDetails
                };

                var headers = {
                    Authorization:
                    `Bearer ` + localStorage.getItem("rezo_billing_front_user"),
                };
                axios
                .post(this.app_base_url + "/customers", data, { headers })
                .then((response) => {
                if (response.status === 200) {

                    this.inputDisabled = false;
                    this.btnDisabled = false;
                    this.btnTitle = "Update Profile";

                    this.hideDialog();

                    // this.$notify({
                    // type: "success",
                    // title: "Success",
                    // text: "Save Successfully!",
                    // });
                }
                }).catch((error) => {
                    this.btnTitle = 'Update Profile';
                    this.$notify({
                        type: "error",
                        title: "Something Went Wrong",
                        text: "Error!",
                    });
                    console.warn("Your Error is :", error);
                });
            }
        },
    },
};
</script>