<template>
  <div class="row">
    <div class="col-md-8">
      <page-header :title="'Backup Settings'" />
    </div>
  </div>
  <div class="row">
    <div class="card col-md-3">
      <div class="card-body">
        <div class="row justify-content-center" v-if="loading">
          <ProgressSpinner style="width:50px;height:50px; padding-top: 135px;" strokeWidth="1"
            fill="var(--surface-ground)" animationDuration=".5s" />
        </div>
        <div v-else>
          <form @submit.prevent="formSubmit">
            <div class="form-group mr-2 mt-2">
              <label for="merchantID" class="mr-1">Backup Period</label>
              <select v-model="backupPeriod" class="form-control form-control-sm mb-1" style="" id="selectStates">
                <option value="daily">Daily</option>
                <option value="Once a week">Once a week</option>
                <option value="Twice a week">Twice a week</option>
                <option value="Once a month">Once a month</option>
                <option value="Twice a month">Twice a month</option>
              </select>
            </div>
            <button class="btn btn-success btn-sm btn-block">{{ btnTitle }}</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ProgressSpinner from 'primevue/progressspinner';
import PageHeader from "../SharedComponents/PageHeader.vue";
export default {
  components: {
    PageHeader,
    ProgressSpinner
  },
  data() {
    return {
      backupPeriod: 'Once a month',
      isDisabled: false,
      loading: true,
      btnTitle: "Update Settings",
      app_base_url: process.env.VUE_APP_BASE_URL,
    }
  },

  created() {
    this.getSetting();
  },

  methods: {
    getSetting() {
      var id = 7;
      var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_billing_user") };
      axios
        .get(this.app_base_url + "/settings/" + id, { headers })
        .then((response) => {
          this.loading = false;
          this.backupPeriod = response.data.backup_period
        })
        .catch((error) => {
          console.error("Your Error is :", error);
        });
    },
    formSubmit() {
      this.isDisabled = true;
      this.btnTitle = "Please wait...";

      var data = {
        setting_id: 7,
        backup_period: this.backupPeriod,
      };

      var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_billing_user") };
      axios.post(this.app_base_url + "/settings", data, { headers }).then(
        (response) => {
          if (response.status === 200) {
            this.btnTitle = "Update Settings";
            this.isDisabled = false;
            this.$notify({
              type: "success",
              title: "Success",
              text: "Saved Successfully!",
            });
            console.log("Form submitted successfully");
          }
        },
        (error) => {
          this.isDisabled = false;
          this.btnTitle = "Update Settings";
          console.log(error.response.data.error);
          this.$notify({
            type: "error",
            title: "Something went wrong!",
            text: error.response.data.error,
          });
        }
      );
    },
  },
};
</script>