<template>
  <page-header :title="'General Settings'" />
  <div class="card col-md-6 mb-3">
    <div class="card-body">
      <form @submit.prevent="formSubmit" class="user">
        <div class="form-group row">
          <div class="col-md-6 mb-3 mb-sm-0">
            <label class="required" for="exampleSiteName"> Site Name</label>
            <input
              v-model="siteName"
              type="text"
              class="form-control form-control-sm"
              :class="{ 'is-invalid': v$.siteName.$error }"
              id="exampleSiteName"
            />
            <span class="text-danger" v-if="v$.siteName.$error">
              <span
                class="help-block"
                v-for="validationError in v$.siteName.$errors"
                :key="validationError"
              >
                {{ validationError.$message }}
              </span>
            </span>
          </div>
            <div class="col-md-6 mb-3 mb-sm-0">
            <label class="required" for="exampleSiteUrl"> Site URL</label>
            <input
              v-model="siteUrl"
              type="text"
              class="form-control form-control-sm"
              :class="{ 'is-invalid': v$.siteUrl.$error }"
              id="exampleSiteUrl"
            />
            <span class="text-danger" v-if="v$.siteUrl.$error">
              <span
                class="help-block"
                v-for="validationError in v$.siteUrl.$errors"
                :key="validationError"
              >
                {{ validationError.$message }}
              </span>
            </span>
          </div>
            <div class="col-md-6 mt-3 mb-3 mb-sm-0">
              <label class="required" for="exampleEmail"> Default E-mail</label>
              <input
                v-model="email"
                type="text"
                class="form-control form-control-sm"
                :class="{ 'is-invalid': v$.email.$error }"
                id="exampleEmail"
              />
              <span class="text-danger" v-if="v$.email.$error">
                <span
                  class="help-block"
                  v-for="validationError in v$.email.$errors"
                  :key="validationError"
                >
                  {{ validationError.$message }}
                </span>
              </span>
            </div>
          <div class="col-sm-6 mt-3 mb-2">
          <label for=""> Password</label>
          <input v-model="password" type="password" id="examplePassword" class="form-control form-control-sm"/>
        </div>
        </div>
        
      <hr class="mb-4">
        <p class="mt-3">Hourly calendar hours</p>
        <div class="form-group row">
          <div class="col-md-6 mb-3 mb-sm-0">
            <label class="" for="exampleSiteName"> From</label>
            <select v-model="calendarHours_from" class="form-control form-control-sm" >
              <option selected :value="undefined">--Select Hours--</option>
              <option value=00:00>12:00 AM</option>
              <option value=01:00>01:00 AM</option>
              <option value=02:00>02:00 AM</option>
              <option value=03:00>03:00 AM</option>
              <option value=04:00>04:00 AM</option>
              <option value=05:00>05:00 AM</option>
              <option value=06:00>06:00 AM</option>
              <option value=07:00>07:00 AM</option>
              <option value=08:00>08:00 AM</option>
              <option value=09:00>09:00 AM</option>
              <option value=10:00>10:00 AM</option>
              <option value=11:00>11:00 AM</option>
              <option value=12:00>12:00 PM</option>
              <option value=13:00>01:00 PM</option>
              <option value=14:00>02:00 PM</option>
              <option value=15:00>03:00 PM</option>
              <option value=16:00>04:00 PM</option>
              <option value=17:00>05:00 PM</option>
              <option value=18:00>06:00 PM</option>
              <option value=19:00>07:00 PM</option>
              <option value=20:00>08:00 PM</option>
              <option value=21:00>09:00 PM</option>
              <option value=22:00>10:00 AM</option>
              <option value=23:00>11:00 AM</option>
            </select>
          </div>
          <div class="col-md-6 mb-3 mb-sm-0">
            <label class="" for="exampleSiteName"> To</label>
            <select v-model="calendarHours_to" class="form-control form-control-sm" >
              <option selected :value="undefined">--Select Hours--</option>
              <option value=00:00>12:00 AM</option>
              <option value=01:00>01:00 AM</option>
              <option value=02:00>02:00 AM</option>
              <option value=03:00>03:00 AM</option>
              <option value=04:00>04:00 AM</option>
              <option value=05:00>05:00 AM</option>
              <option value=06:00>06:00 AM</option>
              <option value=07:00>07:00 AM</option>
              <option value=08:00>08:00 AM</option>
              <option value=09:00>09:00 AM</option>
              <option value=10:00>10:00 AM</option>
              <option value=11:00>11:00 AM</option>
              <option value=12:00>12:00 PM</option>
              <option value=13:00>01:00 PM</option>
              <option value=14:00>02:00 PM</option>
              <option value=15:00>03:00 PM</option>
              <option value=16:00>04:00 PM</option>
              <option value=17:00>05:00 PM</option>
              <option value=18:00>06:00 PM</option>
              <option value=19:00>07:00 PM</option>
              <option value=20:00>08:00 PM</option>
              <option value=21:00>09:00 PM</option>
              <option value=22:00>10:00 AM</option>
              <option value=23:00>11:00 AM</option>
            </select>
          </div>
         
        </div>
        <button class="btn btn-primary">Update Settings</button>
      </form>
    </div>
  </div>
</template>

<script>
import useValidate from "@vuelidate/core";
import { required, minLength, maxLength, email, url } from "@vuelidate/validators";
import PageHeader from "../SharedComponents/PageHeader.vue";
import axios from "axios";
export default {
  components: { PageHeader },
  data() {
    return {
      v$: useValidate(),
      siteName: null,
      siteUrl: null,
      password: null,
      email: null,
      calendarHours_from: null,
      calendarHours_to: null,
      base_url: process.env.VUE_APP_BASE_URL,

    };
  },

  validations() {
    return {
      siteName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      siteUrl: {
        required,
        url,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
      email: {
        required,
        email,
        minLength: minLength(3),
        maxLength: maxLength(50),
      },
    };
  },

  created() {
    var generalId = 1;
    var headers = {
      Authorization: `Bearer ` + localStorage.getItem("rezo_billing_user"),
    };
    
    axios
    .get(this.base_url + "/settings/" + generalId, { headers })
    .then((response) => {
      this.siteName = response.data.site_name,
      this.siteUrl = response.data.site_url,
      this.email = response.data.default_email,
      this.password = response.data.special_password,
      this.calendarHours_from = response.data.calendarhours_from,
      this.calendarHours_to = response.data.calendarhours_to
    })
    .catch((error) => {
      console.error("Your Error is :", error);
    });
  },

methods: {
    
    formSubmit() {
      this.v$.$validate();
      if (this.v$.$error) {
        console.log("errors");
        //event.preventDefault();
      } else {
        var data = {
          setting_id: 1,
          site_name: this.siteName,
          site_url: this.siteUrl,
          default_email: this.email,
          special_password: this.password,
          calendarHours_from: this.calendarHours_from,
          calendarHours_to: this.calendarHours_to
        };
        var headers = {
          Authorization:
            `Bearer ` + localStorage.getItem("rezo_billing_user"),
        };

        axios.post(this.base_url + "/settings", data, { headers }).then(
          (response) => {
            if (response.status === 200) {
              this.$notify({
                type: "success",
                title: "Success",
                text: "Saved Successfully!",
              });
              console.log("Form submitted successfully");
              this.$router.push({ path: "/settings/general" });
            }
            console.log(response);
          },
          (error) => {
            console.log(error.response.data.error);
            this.$notify({
              type: "error",
              title: "Something went wrong!",
              text: error.response.data.error,
            });
          }
        );
      }
    },
  },
};
</script>