<template>
  <div class="p-2">
    <div class="row">
      <div class="col-md-6">
        <div class="card p-4">
          <div class="row justify-content-center" v-if="loading">
            <ProgressSpinner style="width:50px;height:50px; padding-top: 220px;" strokeWidth="1" fill="var(--surface-ground)" animationDuration=".5s" />
          </div>
          <div v-else>
            <div class="row">
              <div class="col-md-12">
                <h3 class="text-primary"><strong>Invoice amount:</strong> ${{ USDCurrancyformat(invoiceAmount) }}</h3>
                <p><small v-if="isSaasPayment"> This is Saas Payment </small></p>
                <p><strong>Invoice #:</strong> {{ invoiceNumber }}</p>
                <p><strong>Invoice Date:</strong> {{ moment(invoiceDate).format("MM/DD/YYYY") }}</p>
                <p><strong class="mr-2">PDF Invoice: </strong>
                  <button v-if="documentFile != null" class="btn btn-sm pt-0 pb-0 btn-light"
                    @click="downloadFile(invoiceID)" :disabled="btnDisabled">
                    <div v-if="!downladbtnDisabled">Invoice</div><i v-if="downladbtnDisabled"
                      class="pi pi-spin pi-spinner" style="font-size: 1rem"></i>
                  </button>
                  <small v-else class="text-danger">No invoice uploaded</small>
                </p>
                <p><strong>Invoice Notes:</strong> {{ invoiceNotes }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card p-4">
          <paymentCard v-if="!loading" :invoiceAmount="invoiceAmount" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import paymentCard from '../partials/paymentCard.vue';
import axios from "axios";
import useValidate from "@vuelidate/core";
import { required, minLength, maxLength, helpers } from "@vuelidate/validators";
import ProgressSpinner from 'primevue/progressspinner';
import moment from "moment";
export default {
  components: {
    ProgressSpinner,
    paymentCard,
  },

  data() {
    return {
      v$: useValidate(),
      app_base_url: process.env.VUE_APP_BASE_URL,
      loading: true,
      moment: moment,

      //Invoice fields
      invoiceID: null,
      invoiceNumber: null,
      invoiceAmount: 0,
      isSaasPayment: null,
      invoiceDate: null,
      invoiceNotes: null,
      documentFile: null,
      paymentMethodHTML: "",
      //Card fields
      user_id: null,
      card_number: null,
      expiring_date: null,
      cvv_number: null,
      isDisabled: false,
      btnTitle: "Pay Invoice"
    };
  },

  validations() {
    return {
      card_number: {
        required: helpers.withMessage('Credit card number is required.', required),
      },

      expiring_date: {
        required: helpers.withMessage('Expiry date is required.', required),
      },

      cvv_number: {
        required: helpers.withMessage('CVV # is required.', required),
        minLength: minLength(3),
        maxLength: maxLength(5)
      },
    };
  },

  created() {
    this.user_id = this.userID = localStorage.getItem("logged_user_id");
    this.getInvoice();
  },

  methods: {
    USDCurrancyformat(value) {
      let val = (value / 1).toFixed(2).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },

    downloadFile(invoiceId) {
      this.downladbtnDisabled = true;
      var data = {
        user_id: this.user_id,
        id: invoiceId
      };

      var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_billing_front_user") };
      axios
        .post(this.app_base_url + "/invoice_file/download", data, { headers, responseType: 'blob' })
        .then((response) => {
          if (response.status === 200) {
            this.downladbtnDisabled = false;
            // console.log(response);
            const file = new Blob(
              [response.data],
              { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
          }
        }).catch((error) => {
          this.downladbtnDisabled = false;
          this.$notify({
            type: "error",
            title: "Something Went Wrong",
            text: "Error!",
          });
          console.warn("Your Error is :", error);
        });
    },

    hideDialog() {
      this.$emit('Display', false)
    },

    getInvoice: function () {
      var id = this.$route.params.id;
      var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_billing_front_user") };
      axios.get(this.app_base_url + '/customer_invoices/' + id, { headers })
      .then((response) => {

        this.invoiceID = response.data.CustomerInvoice.id;
        this.invoiceNumber = response.data.CustomerInvoice.invoice_no;
        this.invoiceAmount = response.data.CustomerInvoice.invoice_amount;
        this.isSaasPayment = response.data.CustomerInvoice.is_saas_payment ? true : false,
        this.SentStatus = response.data.CustomerInvoice.status ? true : false,
        this.invoiceDate = response.data.CustomerInvoice.date;
        this.invoiceNotes = response.data.CustomerInvoice.notes;
        this.documentFile = response.data.CustomerInvoice.document_file;
        this.loading = false;

        this.paymentMethodHTML = response.data.paymentMethodHTML;

      }).catch((error) => {
        console.warn('Your Error is :', error);
        this.$notify({
          type: "error",
          title: "Something Went Wrong",
          text: "Error!",
        });
      })
    },

    formSubmit() {
      this.v$.$validate();
      if (this.v$.$error) {
        console.log("errors", this.v$.$error);
        if (this.v$.amount.$error) {
          this.v$.amount.$errors.forEach(validationError => {
            this.$toast.add({
              severity: 'error',
              summary: 'Amount',
              detail: validationError.$message,
              life: 2000
            });
          });
        }
        if (this.v$.card_number.$error) {
          this.v$.card_number.$errors.forEach(validationError => {
            this.$toast.add({
              severity: 'error',
              summary: 'Card Number',
              detail: validationError.$message,
              life: 2000
            });
          });
        }
        if (this.v$.expiring_date.$error) {
          this.v$.expiring_date.$errors.forEach(validationError => {
            this.$toast.add({
              severity: 'error',
              summary: 'Expiration date',
              detail: validationError.$message,
              life: 2000
            });
          });
        }
        if (this.v$.cvv_number.$error) {
          this.v$.cvv_number.$errors.forEach(validationError => {
            this.$toast.add({
              severity: 'error',
              summary: 'CVV Number',
              detail: validationError.$message,
              life: 2000
            });
          });
        }
      } else {

        this.isDisabled = true;
        this.btnTitle = "Please wait...";

        var data = {
          user_id: this.user_id,
          card_number: this.card_number,
          expiring_date: this.expiring_date,
          cvv_number: this.cvv_number,
        };

        var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_billing_front_user") };
        axios.post(this.app_base_url + "/store/user_payment_information", data, { headers }).then(
          (response) => {
            if (response.status === 200) {
              this.btnTitle = "Pay Invoice";
              this.isDisabled = false;
              this.hideDialog();
            }
          },
          (error) => {
            this.isDisabled = false;
            this.btnTitle = "Pay Invoice"
            console.log(error.response.data.error);
            this.$notify({
              type: "error",
              title: "Something went wrong!",
              text: error.response.data.error,
            });
          }
        );
      }
    }
  }
}
</script>