<template>
  <page-header :title="formTitle" />
  <div class="card col-md-6 mb-3">
    <div class="card-body" style="line-height: 4px;">
      <div class="row justify-content-center" v-if="loading">
        <ProgressSpinner 
          style="width:50px;height:50px" 
          strokeWidth="1" 
          fill="var(--surface-ground)"
          animationDuration=".5s"
        />
      </div>
      <div v-else>
        <form @submit.prevent="formSubmit" class="user">
          <div class="form-group row mt-3">
            <div class="col-sm-6">
              <label class="required" for="companyName">Company Name</label>
              <input type="text" autocomplete="chrome-off" v-model="companyName"
                class="form-control form-control-sm mb-1" id="companyName"/>
              <span class="text-danger" v-if="v$.companyName.$error">
                <small class="help-block" v-for="validationError in v$.companyName.$errors" :key="validationError">
                  {{ validationError.$message }}
                </small>
              </span>
            </div>
            <div class="col-sm-6" v-if="userID">
              <router-link :to="{name:'ShowCustomer',  params: { userID }}" class="btn btn-info btn-sm" style="margin-top: 12px;"><i class="fa fa-user pr-1" aria-hidden="true"></i> Show Profile</router-link> 
            </div>
          </div>
          
          <div class="form-group row mt-3">
            <div class="col-sm-12">
              <label class="required" for="Address">Address</label>
              <input type="text" autocomplete="chrome-off" v-model="Address"
                class="form-control form-control-sm mb-1" id="Address"/>
              <span class="text-danger" v-if="v$.Address.$error">
                <small class="help-block" v-for="validationError in v$.Address.$errors" :key="validationError">
                  {{ validationError.$message }}
                </small>
              </span>
            </div>
          </div>
          
          <div class="form-group row mt-3">
            <div class="col-sm-4">
              <label class="required" for="City">City</label>
              <input type="text" autocomplete="chrome-off" v-model="City"
                class="form-control form-control-sm mb-1" id="City"/>
              <span class="text-danger" v-if="v$.City.$error">
                <small class="help-block" v-for="validationError in v$.City.$errors" :key="validationError">
                  {{ validationError.$message }}
                </small>
              </span>
            </div>
            <div class="col-sm-5">
              <label class="required" for="selectStates"> State</label>
              <select v-model="State" class="form-control form-control-sm mb-1" style="" id="selectStates" >
              <option selected :value="null">--Select--</option>
              <option v-for="state in statesList" :key="state">{{state}}</option>
              </select>
              <span class="text-danger" v-if="v$.State.$error">
                <small class="help-block" v-for="validationError in v$.State.$errors" :key="validationError">
                  {{ validationError.$message }}
                </small>
              </span>
            </div>
            <div class="col-sm-3">
              <label class="required" for="Zip">Zip</label>
              <input type="number" autocomplete="chrome-off" v-model="Zip"
                class="form-control form-control-sm mb-1" id="Zip"/>
              <span class="text-danger" v-if="v$.Zip.$error">
                <small class="help-block" v-for="validationError in v$.Zip.$errors" :key="validationError">
                  {{ validationError.$message }}
                </small>
              </span>
            </div>
          </div>
          
          <div class="form-group row mt-3">
            <div class="col-sm-6">
              <label for="Phone">Phone</label>
              <InputMask
                v-model="Phone"
                mask="(999) 999-9999"
                type="text"
                class="form-control form-control-sm"
                id="Phone"
                autocomplete="chrome-off"
              />
            </div>
            <div class="col-sm-6">
              <label class="required" for="contactName">Contact Name</label>
              <input type="text" autocomplete="chrome-off" v-model="contactName"
                class="form-control form-control-sm mb-1" id="contactName"/>
              <span class="text-danger" v-if="v$.contactName.$error">
                <small class="help-block" v-for="validationError in v$.contactName.$errors" :key="validationError">
                  {{ validationError.$message }}
                </small>
              </span>
            </div>
          </div>
          
          <div class="form-group row mt-3">
            <div class="col-sm-6">
              <label for="contactPhone">Contact Phone</label>
              <InputMask
                v-model="contactPhone"
                mask="(999) 999-9999"
                type="text"
                class="form-control form-control-sm"
                id="contactPhone"
                autocomplete="chrome-off"
              />
            </div>
            <div class="col-sm-6">
              <label class="required" for="Email">Email</label>
              <input type="text" autocomplete="chrome-off" v-model="Email"
                class="form-control form-control-sm mb-1" id="Email"/>
              <span class="text-danger" v-if="v$.Email.$error">
                <small class="help-block" v-for="validationError in v$.Email.$errors" :key="validationError">
                  {{ validationError.$message }}
                </small>
              </span>
            </div>
          </div>
          
          <div class="form-group row mt-3">
            <!-- <div class="col-sm-6">
              <label :class="userID ? '' : 'required'" for="Password">{{userID ? 'New password' : 'Password'}}</label>
              <input type="text" autocomplete="chrome-off" v-model="Password"
                class="form-control form-control-sm mb-1" id="Password"/>
              <span class="text-danger" v-if="v$.Password.$error">
                <small class="help-block" v-for="validationError in v$.Password.$errors" :key="validationError">
                  {{ validationError.$message }}
                </small>
              </span>
            </div> -->
            <div class="col-sm-6">
              <label :class="userID ? '' : 'required'" for="Password">{{userID ? 'Password' : 'Password'}}</label>
              <Password v-model="Password" :toggleMask="true" :inputStyle="{height: 31, width: 213}">
                <template #header>
                  <h6></h6>
                  <h6 v-if="userID" class="text-danger"> Change the current password</h6>
                  <h6 v-else class="text-success">Pick a password</h6>
                </template>
                <template #footer>
                  <Divider />
                  <p class="mt-2">Suggestions</p>
                  <ul class="pl-2 ml-2 mt-0" style="line-height: 1.2">
                    <li>At least one lowercase</li>
                    <li>At least one uppercase</li>
                    <li>At least one numeric</li>
                    <li>Minimum 8 characters</li>
                  </ul>
                </template>
              </Password>
              <span class="text-danger " v-if="v$.Password.$error">
                <small class="help-block mt-1" v-for="validationError in v$.Password.$errors" :key="validationError">
                  {{ validationError.$message }}
                </small>
              </span>
            </div>
            <div class="col-sm-6">
              <label for="additionalEmail">Additional Email</label>
              <input type="text" autocomplete="chrome-off" v-model="additionalEmail" class="form-control form-control-sm mb-1" id="additionalEmail"/>
              <span class="text-danger" v-if="v$.additionalEmail.$error">
                <small class="help-block" v-for="validationError in v$.additionalEmail.$errors" :key="validationError">
                  {{ validationError.$message }}
                </small>
              </span>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <button class="btn btn-primary btn-sm" :disabled="btnDisabled">{{btnTitle}} <i v-if="btnDisabled" class="pi pi-spin pi-spinner" style="font-size: 1rem"></i></button>
            </div>
            <div class="col-md-8" v-if="userID">
              <div class="form-inline">
                <div class="form-group mr-2">
                  <label class="switch">
                    <input type="checkbox" id="isEmailedLoginDetails" v-model="isEmailedLoginDetails" />
                    <span class="slider round"></span>
                  </label>
                  <label for="isEmailedLoginDetails" class="mr-1">Email Customer Login Detail</label>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
  import useValidate from "@vuelidate/core";
  import { 
    maxLength,
    minLength,
    required, 
    helpers,
    requiredUnless,
    email
  } from "@vuelidate/validators";
  import PageHeader from "../SharedComponents/PageHeader.vue";
  import axios from "axios";
  import InputMask from 'primevue/inputmask';
  import ProgressSpinner from 'primevue/progressspinner';
  import 'primeicons/primeicons.css';
  import Password from 'primevue/password';

  export default {
    components: { 
      PageHeader,
      InputMask,
      ProgressSpinner,
      Password
    },
    data() {
      return {
        app_base_url: process.env.VUE_APP_BASE_URL,
        v$: useValidate(),
        formTitle: "New Customer",
        btnDisabled: false,
        passwordValidiation: true,
        btnTitle: "Create",
        statesList: [],
        loading: true,

        //Fields
        userID: null,
        userRole: "Staff",
        companyName: null,
        Address: null,
        City: null,
        State: null,
        Zip: null,
        Phone: null,
        contactName: null,
        contactPhone: null,
        Email: null,
        Password: null,
        additionalEmail: null,
        isEmailedLoginDetails: false,
      };
    },

    validations() {
      return {
        companyName: {
          maxLength: maxLength(30),
          required: helpers.withMessage('Company name is required.', required),
        },
        Address: {
          required: helpers.withMessage('Address is required.', required),
        },
        City: {
          required: helpers.withMessage('City is required.', required),
        },
        State: {
          required: helpers.withMessage('Please select any state.', required),
        },
        Zip: {
          required: helpers.withMessage('Zip is required.', required),
          maxLength: helpers.withMessage('Max 5 required.', maxLength(5)),
        },
        contactName: {
          required: helpers.withMessage('Contact name is required.', required),
        },
        Email: {
          required: helpers.withMessage('Email is required.', required),
        },
        additionalEmail: {
          email
        },
        Password: {
          requiredIf: requiredUnless(this.userID),
          minLength: minLength(4)
        }
      };
    },


    created() {
      this.loading = false;
      this.getAmericanStates();
      if (this.$route.name == "EditCustomer") {
        this.formTitle = "Edit Customer";
        this.userID = this.$route.params.id;
        this.getCustomer();
        this.btnTitle = "Update"
        this.loading = true;
      }
    },

    methods: {

      getAmericanStates: function () {
        var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_billing_user") };
        axios.get(this.app_base_url + '/customers/create', { headers })
          .then((response) => {
            this.statesList = response.data.states;
          }).catch((error) => {
            console.warn('Your Error is :', error);
          })
      },

      getCustomer: function () {
        var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_billing_user") };
        axios.get(this.app_base_url + '/customers/' + this.userID, { headers })
        .then((response) => {
            this.loading = false;
            console.info("Edit Customer: ", response.data.customer);
            this.companyName = response.data.customer.company_name,
            this.Address = response.data.customer.address,
            this.City = response.data.customer.city,
            this.State = response.data.customer.state,
            this.Zip = response.data.customer.zip,
            this.Phone = response.data.customer.phone,
            this.contactName = response.data.customer.contact_name,
            this.contactPhone = response.data.customer.contact_phone,
            this.Email = response.data.customer.email,
            this.Password = response.data.customer.password,
            this.additionalEmail = response.data.customer.additional_email,
            this.isEmailedLoginDetails = response.data.customer.is_emailed_login_details?true:false
          }).catch((error) => {
            console.warn('Your Error is :', error);
          })
      },

      formSubmit() {
        this.v$.$validate();
        if (this.v$.$error) {
          console.log("errors", this.v$.$error);
        } else {

          this.btnDisabled = true;
          this.btnTitle = "Please wait...";

          var data = {
            role: this.userRole,
            user_id: this.userID,
            company_name: this.companyName,
            address: this.Address,
            city: this.City,
            state: this.State,
            zip: this.Zip,
            phone: this.Phone,
            contact_name: this.contactName,
            contact_phone: this.contactPhone,
            email: this.Email,
            password: this.Password,
            additional_email: this.additionalEmail,
            is_emailed_login_details: this.isEmailedLoginDetails
          };

          var headers = {
            Authorization:
              `Bearer ` + localStorage.getItem("rezo_billing_user"),
          };
          axios
            .post(this.app_base_url + "/customers", data, { headers })
            .then((response) => {
              if (response.status === 200) {
                this.btnDisabled = false;
                this.btnTitle = this.$route.name == "EditCustomer" ? "Update" : "Create";

                this.$notify({
                  type: "success",
                  title: "Success",
                  text: "Customer is created successfully!",
                });
                this.$router.push('/customers')
              }
            }).catch((error) => {
              let error_message = '';
              if (error.response.data.Error) {
                error_message = error.response.data.Error;
              }
              else {
                error_message = error;
              }
              this.btnDisabled = false
              if (this.$route.name == "EditCustomer") {
                this.btnTitle = 'Update';
              }
              else {
                this.btnTitle = 'Create';
              }
              this.$notify({
                type: "error",
                title: error_message,
                text: "Error!",
              });

              console.warn("Your Error is :", error);
            });
        }
      },
    }
  };
</script>