<template>
  <div class="row p-3">
    <div class="col-md-5">
      <div class="card shadow py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2">
              <customerProfile />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-7 col-md-12">
      <TabView class="shadow" :activeIndex="activeIndex">
        
        <TabPanel header="Current Invoices">
          <!-- Invoices -->
          <customerInvoices />
        </TabPanel>

        <TabPanel header="Payment Info">
          <!-- Payment Info -->
          <customerPaymentInfo />
        </TabPanel>
        
        <TabPanel header="Product">
          <!-- Products -->
          <customerProduct />
        </TabPanel>
       
        <TabPanel header="Billing History">
          <!-- Billing History -->
          <customerBillingHistory />
        </TabPanel>

      </TabView>
    </div>
  </div>
</template>

<script>
  import TabView from 'primevue/tabview';
  import TabPanel from 'primevue/tabpanel';
  import customerProfile from '../views/frontend/customerProfile.vue'
  import customerPaymentInfo from '../views/frontend/customerPaymentInfo.vue'
  import customerProduct from '../views/frontend/customerProduct.vue'
  import customerInvoices from '../views/frontend/customerInvoices.vue'
  import customerBillingHistory from '../views/frontend/customerBillingHistory.vue'
  export default {
    name: "Dashboard",

    components: {
      TabView,
      TabPanel,
      customerProfile,
      customerPaymentInfo,
      customerProduct,
      customerInvoices,
      customerBillingHistory
    },
    data() {
      return {

      };
    },
  };
</script>