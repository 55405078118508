<template>
    <div>
        <div class="row justify-content-center" v-if="loading">
            <ProgressSpinner style="width:50px;height:50px" strokeWidth="1" fill="var(--surface-ground)"
                animationDuration=".5s" />
        </div>
        <div v-else>
            <div class="row">
                <div class="col-md-12">
                    <table class="table">
                        <thead>
                            <tr style="font-size: 15px;">
                                <th class="p-1" scope="col">Invoice No</th>
                                <th class="p-1" scope="col">Transcation ID</th>
                                <th class="p-1" scope="col">Card Last 4</th>
                                <th class="p-1" scope="col">Paid Amount</th>
                                <th class="p-1" scope="col">Paid Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="record in BillingHistories.data" :key="record" class="spaceUnder"
                                style="font-size: 15px;">
                                <td class="p-1">{{ record.invoice.invoice_no }}</td>
                                <td class="p-1">{{ record.transaction_id }}</td>
                                <td class="p-1">{{ record.card_last_4 }}</td>
                                <td class="p-1 ">${{ USDCurrancyformat(record.amount) }}</td>
                                <td class="p-1">{{ moment(record.date_paid).format("MM/DD/YYYY h:mm:ss A") }}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="text-center pt-0">
                        <small class="text-danger" v-if="BillingHistories.data.length < 1">No record found.</small>
                    </div>
                    <div v-if="BillingHistories.data.length > 0" class="pagination d-flex justify-content-center mt-1">
                        <button class="btn btn-outline-secondary pt-0 pb-0 mr-1"
                            @click="getBillingHistory(BillingHistories.first_page_url)"
                            :disabled="pagination.current_page == 1" style="font-size: 14px; height: 30px;"> First Page
                        </button>
                        <button class="btn btn-outline-secondary pt-0 pb-0 mr-2"
                            @click="getBillingHistory(pagination.prev_page_url)"
                            :disabled="pagination.current_page == 1" style="font-size: 14px; height: 30px;"> Previous
                        </button>
                        <label>Page {{ pagination.current_page }} of {{ pagination.last_page }}</label>
                        <button class="btn btn-outline-secondary pt-0 pb-0 ml-2"
                            @click="getBillingHistory(pagination.next_page_url)"
                            :disabled="pagination.current_page == pagination.last_page"
                            style="font-size: 14px; height: 30px;">Next 
                        </button>
                        <button class="btn btn-outline-secondary pt-0 pb-0 ml-1"
                            @click="getBillingHistory(BillingHistories.last_page_url)"
                            :disabled="pagination.current_page == pagination.last_page"
                            style="font-size: 14px; height: 30px;"> Last Page 
                        </button>

                        <div class="ml-1">
                            <select v-model="searchFilters.per_page" class="form-control"
                                style="border: 1px solid #858796; height: 30px; padding-top: 2px;">
                                <option selected :value="3">3</option>
                                <option selected :value="5">5</option>
                                <option selected :value="10">10</option>
                                <option selected :value="20">20</option>
                                <option selected :value="50">50</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import axios from "axios";
import moment from "moment";
import ProgressSpinner from 'primevue/progressspinner';
export default {
    components: {
        ProgressSpinner,
    },
    data() {
        return {
            app_base_url: process.env.VUE_APP_BASE_URL,
            moment: moment,
            loading: true,
            userID: null,
            
            //Fields
            BillingHistories: [],
            pagination: {},
            searchFilters: {
                per_page:5
            },
        };
    },
    watch: {
        searchFilters: {
            deep: true,
            handler() {
                if (!this.awaitingSearch) {
                setTimeout(() => {
                    this.getBillingHistory(this.app_base_url + '/billing_histories/' + this.userID)
                    this.loading = true;
                    this.awaitingSearch = false;
                }, 700);
                }
                this.awaitingSearch = true;
            }
        },
    },
    created() {
        this.userID = localStorage.getItem("logged_user_id");
        this.getBillingHistory();
    },
    methods: {
        USDCurrancyformat(value) {
            let val = (value / 1).toFixed(2).replace(',', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },

        getBillingHistory: function (page_url) {
            this.loading = true;
            let vm = this;
            page_url = page_url || this.app_base_url + '/billing_histories/' + this.userID;
            var headers = { Authorization: `Bearer ` + localStorage.getItem("rezo_billing_front_user") };
            axios.get(page_url, { headers, params: (this.searchFilters) })
                .then((response) => {
                this.loading = false;
                console.log('Billing History: ', response.data.billing_histories);
                this.BillingHistories = response.data.billing_histories;
                vm.makePagination(response.data)
                this.loading = false;
            });
        },

        makePagination: function (data) {

            let pagination = {
                current_page: data.billing_histories.current_page,
                last_page: data.billing_histories.last_page,
                next_page_url: this.app_base_url + '/billing_histories/' + this.userID + '?page=' + (data.billing_histories.current_page + 1),
                prev_page_url: this.app_base_url + '/billing_histories/' + this.userID + '?page=' + (data.billing_histories.current_page - 1),
            }
            
            this.pagination = pagination;
        },

    },
};
</script>