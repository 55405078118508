<template>
  <div class="d-flex align-items-center justify-content-center vh-100 mb-5">
    <div class="text-center">
      <h1 class="display-1 fw-bold">404</h1>
      <p class="fs-3"> <span class="text-danger">Opps!</span> Page not found.</p>
      <p class="lead">
        The page you’re looking for doesn’t exist.
      </p>
      <router-link :to="redirect" class="btn btn-primary">Go to Home</router-link>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'NotFound',
    computed: {
      redirect() {
        if (localStorage.getItem("user_role") == 'Admin') {
          return '/';
        }else{
          return '/front';
        }
      },
    },
  }
</script>