<template>
  <div class="container-fuild  full-height" style="height: 400px;">
          <div class="row justify-content-center">
            <div>
              <div class="card o-hidden border-0 shadow-lg my-4">
                <div class="card-body p-0">
                  <!-- Nested Row within Card Body -->
                  <div class="row justify-content-center">
                    <div style="width: 430px;">
                      <div class="pl-5 pr-5 pt-3 pb-2">
                        <div class="text-center">
                          <h1 class="h4 text-gray-900 mb-4">Update Password</h1>
                        </div>
                        <form @submit.prevent="formSubmit" class="user">
                          <div class="form-group">
                            <label for="">Password</label>
                            <input type="password" v-model="password" class="form-control form-control"  placeholder="Enter a strong password" />
                            <small class="text-danger" v-if="v$.password.$error">{{
                            v$.password.$errors[0].$message
                            }}</small>
                          </div>

                          <div class="form-group">
                            <label for="">Password Confirmation</label>

                            <input type="password" v-model="password_confirmation" class="form-control form-control"  placeholder="Type password again to confirm" />
                            <small class="text-danger" v-if="v$.password_confirmation.$error">{{
                            v$.password_confirmation.$errors[0].$message
                            }}</small>
                          </div>

                          <button class="btn btn-dark" :disabled="isDisabled">
                            {{btnTitle}} <i v-if="isDisabled" class="pi pi-spin pi-spinner" style="font-size: 1rem"></i>
                          </button>

                          <div class="my-3 alert alert-danger" v-if="errorMessage" v-text="errorMessage"></div>

                          <div class="mt-3" v-if="password_updated">
                            <router-link class=" " to="/">
                              <button class="btn btn-success">Login</button>
                            </router-link>
                          </div>

                          <!-- <a href="#" class="ml-3" style="text-decoration: underline;">Forget password</a> -->
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
</template>

<script>
import useValidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import axios from "axios";
import 'primeicons/primeicons.css';
import moment from "moment";
export default {
  data() {
    return {
      v$: useValidate(),
      app_base_url: process.env.VUE_APP_BASE_URL,
      moment: moment,
      password: null,
      password_confirmation: null,
      btnTitle: "Update",
      isDisabled: false,
      password_reset_token: null,
      errors: [],
      password_updated: false,
    };
  },

  created()
  {
    this.password_reset_token = this.$route.params.password_reset_token
  },

  validations() {
    return {
      password: { required, minLength: minLength(8) },
      password_confirmation: { required, minLength: minLength(8) },
    };
  },

  methods: {
    formSubmit() {
      this.v$.$validate();
      if (this.v$.$error) {
        console.log("errors");
      } else {

        this.isDisabled = true;
        this.btnTitle = "Please wait... ";

        var data = {
          password: this.password,
          password_confirmation: this.password_confirmation,
          password_reset_token: this.password_reset_token,
        };
        var headers = {};
        axios
          .post(this.app_base_url + "/update/password", data, { headers: headers })
          .then((response) => {
            if (response.data.status) {
              this.$notify({
                  type: "success",
                  title: response.data.message,
                  text: "Success!",
              });
              // this.isDisabled = false;
              this.btnTitle = "Updated";
              this.$router.push({ name: 'Dashboard' })
            } else {
              this.$notify({
                  type: "error",
                  title: response.data.message,
                  text: "Error!",
              });

              this.isDisabled = false;
              this.btnTitle = "Update";
            }

          },
            (error) => {

              if (error.response.data.errors && error.response.data.errors.password) {
                this.errorMessage = error.response.data.errors.password[0]
              }

              if (error.response.data.errors && error.response.data.errors.password_confirmation) {
                this.errorMessage = error.response.data.errors.password_confirmation[0]
              }

              if (error.response.data.errors && error.response.data.errors.password_reset_token) {
                this.errorMessage = error.response.data.errors.password_reset_token[0]
              }
              
              this.$notify({
                type: "error",
                title: this.errorMessage,
                text: "Error!",
              });

              this.isDisabled = false;
              this.btnTitle = "Update";
            });
      }
    },
  },
};
</script>